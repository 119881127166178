/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSnackbar } from "react-simple-snackbar";
import { Icon } from "semantic-ui-react";
import history from "../../utils/history";
import {
  menuButton,
  logoIcon,
  search,
  heart,
  message,
  profile,
  searchActive,
  messageActive,
  profileActive,
  news,
  discount,
  messageNotification,
  // menuClose,
} from "../../assets/images";
import "../NavBar/NavBar.scss";
import { userDetails } from "../../Helpers/UserDetails";
import HomeSearch from "../HomeSearch/HomeSearch";
// eslint-disable-next-line import/no-cycle
// import TabNavigationModal from '../TabNavigationModal/TabNavigationModal';
function TabNavBar(props) {
  const { t } = useTranslation();
  // const [isHomeActive, setIsHomeActive] = useState(false);
  // const [isPromoActive, setIsPromoActive] = useState(false);
  // const [isFidelityActive, setIsFidelityActive] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showNotificationRedIcon, setShowNotificationRedIcon] = useState(true);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
  // const [showResetPassordModal, setShowResetPassordModal] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const handleTabButtonClick = (type) => {
    // if (history.location.pathname !== '/home') {
    //   history.push('/home');
    // }
    if (type === "home" && history.location.pathname !== "/home") {
      // props.setIsHomeActive(true);
      // props.setIsPromoActive(false);
      // props.setIsFidelityActive(false);
      // props.setShowListingPage(false);
      // props.setShowMapView(false);
      setIsOpenSearchModal(false);
      history.push("/home");
      //   setShowResetPassordModal(false);
    }
    if (type === "promo" && history.location.pathname !== "/promo") {
      // props.setIsPromoActive(true);
      // props.setIsHomeActive(false);
      // props.setIsFidelityActive(false);
      // props.setShowListingPage(false);
      // props.setShowMapView(false);
      setIsOpenSearchModal(false);
      //   setShowResetPassordModal(false);
      if (localStorage.getItem("token")) {
        history.push("/promo");
      } else {
        openSnackbar(t("notLogged"));
      }
    }
    if (type === "fidelity" && history.location.pathname !== "/fidelity") {
      //   setShowResetPassordModal(false);
      setIsOpenSearchModal(false);
      // props.setIsFidelityActive(true);
      // props.setIsHomeActive(false);
      // props.setIsPromoActive(false);
      // props.setShowListingPage(false);
      // props.setShowMapView(false);
      if (localStorage.getItem("token")) {
        history.push("/fidelity");
      } else {
        openSnackbar(t("notLogged"));
      }
    }
  };

  const [openSnackbar] = useSnackbar({
    // position: 'top-center',
    style: {
      position: "absolute",
      backgroundColor: " baby blue",
      // border: '2px solid lightgreen',
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
      bottom: "100%",
      left: 0,
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });

  const handleSearchIconClick = (value) => {
    setIsOpenSearchModal(value);
    setShowLogoutModal(false);
    setShowNotificationModal(false);
  };
  return (
    <div className="home_navbar_continer">
      <div
        className={
          props.isScrolled || props.showMapView
            ? "home_navbar home_navbar_fixed"
            : // : props.mallDetailView
              // ? 'home_navbar mallAndShopNav'
              "home_navbar"
        }
        style={
          props.mapview
            ? { paddingLeft: "0px", paddingRight: "0px" }
            : // : (!props.isScrolled || !props.showMapView) &&
              //   !props.isBannerPresent
              // ? { position: 'fixed' }
              null
        }
      >
        {!isTabletOrMobile ? (
          <div className="home_link_container">
            {props.isTabNavigation ? (
              <Icon
                className="close nav-close"
                onClick={() => {
                  props.setShowResetPassordModal(false);
                }}
              />
            ) : (
              <img
                className="home_navbar_link_button"
                src={menuButton}
                alt="menu button"
              />
            )}

            <button
              className="home_navbar_link_button"
              type="button"
              onClick={() => handleTabButtonClick("home")}
            >
              <div
                style={{
                  color: props.isHomeActive ? "#ec6338" : null,
                  width: "50px",
                }}
              >
                HOME
              </div>
              {props.isHomeActive && <div className="tab_underline" />}
            </button>
            <button
              className="home_navbar_link_button"
              type="button"
              onClick={() => handleTabButtonClick("promo")}
            >
              <div style={props.isPromoActive ? { color: "#ec6338" } : null}>
                PROMO
              </div>

              {props.isPromoActive && <div className="tab_underline" />}
            </button>
            <button
              className="home_navbar_link_button"
              type="button"
              onClick={() => handleTabButtonClick("fidelity")}
            >
              <div style={props.isFidelityActive ? { color: "#ec6338" } : null}>
                FIDELITY
              </div>

              {props.isFidelityActive && <div className="tab_underline" />}
            </button>
          </div>
        ) : (
          <div className="home_link_container">
            {props.isTabNavigation ? (
              <Icon
                className="close nav-close"
                onClick={() => {
                  props.setShowResetPassordModal(false);
                }}
              />
            ) : (
              <img
                className="home_navbar_link_button"
                src={menuButton}
                onClick={() => {
                  //   setShowResetPassordModal(true);
                }}
                alt="menu button"
              />
            )}

            {/* <img className="home_nav_icon" src={heart} alt="logo" /> */}
            <button
              className="product_search_icon"
              type="button"
              onClick={() => {
                props.setShowResetPassordModal(false);
                if (localStorage.getItem("token")) {
                  if (history.location.pathname !== "/favourites") {
                    history.push("/favourites");
                  }
                } else {
                  openSnackbar(t("notLogged"));
                }
              }}
            >
              <img className="home_nav_icon" src={heart} alt="logo" />
            </button>
          </div>
        )}
        <button
          type="button"
          className="logo_home_button"
          onClick={() => {
            if (!localStorage.getItem("token")) {
              history.push("/");
            }
          }}
        >
          <img src={logoIcon} alt="logo" className="home_logo_icon" />
        </button>

        <div className="navbar_icons_container">
          {showNotificationModal && (
            <div
              className={
                props.isScrolled
                  ? "notification_card_active notification_card"
                  : "notification_card"
              }
              style={props.mapview ? { right: "44px" } : null}
            >
              <div className="triangle_up" />
              <div className="notifiacaion_item">
                <div className="notication_image_container">
                  <img
                    src={news}
                    alt="news"
                    className="notification_image_type"
                  />
                </div>
                <div className="notification_description">
                  <p className="notification_heading">
                    Sconto del 20% su tutte le t-shirt
                  </p>
                  <p className="notification_text">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor.{" "}
                  </p>
                </div>
              </div>
              <div
                className="notifiacaion_item"
                style={{ backgroundColor: "#ffffff" }}
              >
                <div className="notication_image_container">
                  <img
                    src={discount}
                    alt="news"
                    className="notification_image_type"
                  />
                </div>
                <div className="notification_description">
                  <p className="notification_heading">
                    Sconto del 20% su tutte le t-shirt
                  </p>
                  <p className="notification_text">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor.{" "}
                  </p>
                </div>
              </div>
              <div className="notifiacaion_item">
                <div className="notication_image_container">
                  <img
                    src={messageNotification}
                    alt="news"
                    className="notification_image_type"
                  />
                </div>
                <div className="notification_description">
                  <p className="notification_heading">
                    Sconto del 20% su tutte le t-shirt
                  </p>
                  <p className="notification_text">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor.{" "}
                  </p>
                </div>
              </div>
            </div>
          )}
          <button
            type="button"
            className="product_search_icon"
            // eslint-disable-next-line react/prop-types
            onClick={() => {
              setIsOpenSearchModal(!isOpenSearchModal);
              setShowNotificationModal(false);
              setShowLogoutModal(false);
            }}
          >
            {isOpenSearchModal ? (
              <img className="home_nav_icon" src={searchActive} alt="logo" />
            ) : (
              <img className="home_nav_icon" src={search} alt="logo" />
            )}
          </button>
          {isOpenSearchModal && (
            <div
              className={
                props.isScrolled || props.showMapView
                  ? "home_search_container home_search_container_active"
                  : "home_search_container"
              }
            >
              <HomeSearch
                handleSearchIconClick={handleSearchIconClick}
                // isScrolled={isScrolled}
                setShowListingPage={props.setShowListingPage}
                setIsHomeActive={props.setIsHomeActive}
                setIsPromoActive={props.setIsPromoActive}
                setIsFidelityActive={props.setIsFidelityActive}
                setShowMapView={props.setShowMapView}
                showMapView={props.showMapView}
                searchMallsStores={props.searchMallsStores}
                setIsOpenSearchModal={setIsOpenSearchModal}
                searchedMallsAndStores={props.searchedMallsAndStores}
              />
            </div>
          )}
          {!isTabletOrMobile && (
            <button
              className="product_search_icon"
              type="button"
              onClick={() => {
                if (localStorage.getItem("token")) {
                  if (history.location.pathname !== "/favourites") {
                    history.push("/favourites");
                  }
                } else {
                  openSnackbar(t("notLogged"));
                }
              }}
            >
              <img className="home_nav_icon" src={heart} alt="logo" />
            </button>
          )}
          <button
            className="product_search_icon"
            type="button"
            onClick={() => {
              // eslint-disable-next-line react/prop-types
              if (localStorage.getItem("token")) {
                setShowNotificationModal(!showNotificationModal);
                setIsOpenSearchModal(false);
                setShowLogoutModal(false);
                setShowNotificationRedIcon(false);
              } else {
                openSnackbar(t("notLogged"));
              }
            }}
          >
            {showNotificationRedIcon && localStorage.getItem("token") && (
              <span className="notification_red_dot">3</span>
            )}
            {showNotificationModal ? (
              <img className="home_nav_icon" src={messageActive} alt="logo" />
            ) : (
              <img className="home_nav_icon" src={message} alt="logo" />
            )}
          </button>
          {showLogoutModal && (
            <div
              className={
                props.isScrolled
                  ? "profile_logout_card profile_logout_card_active"
                  : "profile_logout_card"
              }
              style={props.mapview ? { right: "0" } : null}
            >
              <div className="triangle_up" />
              <div
                className="view_profile_text"
                onClick={() => {
                  if (history.location.pathname !== "/userProfile") {
                    history.push("/userProfile");
                  }
                }}
              >
                VAI AL PROFILO
              </div>
              <hr style={{ margin: "18px 16px 10px 16px" }} />
              <button
                type="button"
                className="home_logout_button"
                onClick={() => {
                  history.push("/");
                  localStorage.removeItem("token");
                  localStorage.removeItem("zipCode");
                  localStorage.removeItem("searchHistory");
                }}
              >
                LOGOUT
              </button>
            </div>
          )}
          <button
            type="button"
            className="product_search_icon"
            // eslint-disable-next-line react/prop-types
            onClick={() => {
              if (localStorage.getItem("token")) {
                setIsOpenSearchModal(false);
                setShowLogoutModal(!showLogoutModal);
                setShowNotificationModal(false);
              } else {
                openSnackbar(t("notLogged"));
              }
            }}
          >
            {localStorage.getItem("token") ? (
              <span className="logged_in_name">{userDetails().firstLast}</span>
            ) : showLogoutModal ? (
              <img className="home_nav_icon" src={profileActive} alt="logo" />
            ) : (
              <img className="home_nav_icon" src={profile} alt="logo" />
            )}
          </button>
        </div>
      </div>
      {/* <TabNavigationModal
        showResetPassordModal={showResetPassordModal}
        setShowResetPassordModal={setShowResetPassordModal}
        handleTabButtonClick={handleTabButtonClick}
      /> */}
    </div>
  );
}
TabNavBar.propTypes = {
  // isOpenSearchModal: PropTypes.bool,
  // setShowNotificationModal: PropTypes.func,
  // setIsOpenSearchModal: PropTypes.func,
  // setShowLogoutModal: PropTypes.func,
  // setShowNotificationRedIcon: PropTypes.func,
  // showNotificationRedIcon: PropTypes.bool,
  // showNotificationModal: PropTypes.bool,
  // showLogoutModal: PropTypes.bool,
  isScrolled: PropTypes.bool,
  setIsHomeActive: PropTypes.func,
  setIsPromoActive: PropTypes.func,
  setIsFidelityActive: PropTypes.func,
  isHomeActive: PropTypes.bool,
  isPromoActive: PropTypes.bool,
  isFidelityActive: PropTypes.bool,
  setShowListingPage: PropTypes.func,
  setShowMapView: PropTypes.func,
  showMapView: PropTypes.bool,
  mapview: PropTypes.bool,
  searchMallsStores: PropTypes.bool,
  searchedMallsAndStores: PropTypes.object,
  setShowResetPassordModal: PropTypes.func,
  // mallDetailView: PropTypes.bool,
  isTabNavigation: PropTypes.bool,
  // isBannerPresent: PropTypes.bool,
};

export default TabNavBar;
