import React from "react";
import { useTranslation } from "react-i18next";
import {
  heartFilled,
  heartCard,
  locator,
  shoppingCentre,
  store6,
} from "../../assets/images";
import history from "../../utils/history";
import { shopGallaryResource } from "../../Helpers/api";
import "./StoreCard.scss";

function StoreCard(props) {
  const { t } = useTranslation();

  const {
    shop,
    isCreateLoding,
    type,
    deleteFavStore,
    craeteFavStore,
    isCreateFav,
    openSnackbar,
  } = props;

  const formImageUrl = (imageName) => {
    const imgeUrl = `${shopGallaryResource}${imageName}`;
    const imgeUrlEncode = encodeURI(imgeUrl);
    return imgeUrlEncode;
  };

  return (
    <div className="store-card">
      <div className="card-heart-container-new">
        <button
          className="heart-button-new"
          onClick={() =>
            isCreateFav
              ? deleteFavStore &&
                !isCreateLoding &&
                deleteFavStore(shop.id, type)
              : localStorage.getItem("token")
              ? craeteFavStore &&
                !isCreateLoding &&
                craeteFavStore(shop.id, type)
              : openSnackbar && openSnackbar(t("notLogged"))
          }
        >
          <img src={isCreateFav ? heartFilled : heartCard} alt="heart" />
        </button>
      </div>
      <div className="store-card-container">
        <div
          style={{
            backgroundImage: `url(${formImageUrl(shop.image)}),url(${
              type === "mall" ? shoppingCentre : store6
            })`,
          }}
          className="store-image"
          onClick={() => {
            if (type === "mall") {
              history.push(`/mallDetails/${shop.id}`);
            } else if (type === "store") {
              history.push(`/shopDetails/${shop.id}`);
            }
          }}
        ></div>
        <div
          className="name-description"
          onClick={() => {
            if (type === "mall") {
              history.push(`/mallDetails/${shop.id}`);
            } else if (type === "store") {
              history.push(`/shopDetails/${shop.id}`);
            }
          }}
        >
          <span>{shop.name}</span>
        </div>
        {/* <div className="shop_card_locator">
          <img src={locator} alt="locator" />
        </div> */}
        <div className="address-description">
          <img src={locator} alt="locator" />
          <span>
            {" "}
            {`${shop.address.address},  ${shop.address.city},  ${shop.address.state},  ${shop.address.zip}, ${shop.address.county}`}
          </span>
        </div>
      </div>
    </div>
  );
}

export default StoreCard;
