/**
Sign up user
 */

import { call, put, takeLatest } from 'redux-saga/effects';
// import { useSnackbar } from 'react-simple-snackbar';
import axios from 'axios';
// import { userDetails } from '../Helpers/UserDetails';
import * as API from '../../Helpers/api';
import { operatorSignUp, operatorSignUpFailed } from './actions';

// const [openSnackbar, closeSnackbar] = useSnackbar();

/** function that returns an axios call */
function signupOperator(payload) {
  console.log(payload);
  return axios(API.operatorSignUp, {
    method: 'POST',
    // auth: {
    //   username: 'g.scirea',
    //   password: 'testtest',
    //   //   username: 'u.aspernatur',
    //   //   password: 'Test@123456',
    // },
    data: payload,
  });
}

export function* operatorSignUpSaga(action) {
  try {
    const res = yield call(signupOperator, action.payload);
    if (res) {
      yield put(operatorSignUp(res));
    }
  } catch (err) {
    // openSnackbar('This is the content of the Snackbar.');
    // operatorSignUpFailed(err);
    yield put(operatorSignUpFailed(err));
    console.log(err);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* signupData() {
  // Watches for LOGGED_IN actions and calls loginSaga when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest('OPERATOR_SIGNNED_UP', operatorSignUpSaga);
}
