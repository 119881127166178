import { SIGN_IN, SIGN_IN_FAILED, SIGN_IN_LODING } from './constants';

export function signin(data) {
  return {
    type: SIGN_IN,
    res: data,
  };
}
export function signinlLoading(data) {
  return {
    type: SIGN_IN_LODING,
    res: data,
  };
}
export function signinFailed(data) {
  return {
    type: SIGN_IN_FAILED,
    res: data,
  };
}
