import {
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_LOADING,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILED,
  DELETE_USER_PROFILE_SUCCESS,
  DELETE_USER_PROFILE_FAILED,
} from './constants';

export function getUserProfileSuccess(payload) {
  return {
    type: GET_USER_PROFILE_SUCCESS,
    res: payload,
  };
}

export function getUserProfileFailed(payload) {
  return {
    type: GET_USER_PROFILE_FAILED,
    res: payload,
  };
}

export function updateUserProfileLoading(payload) {
  return {
    type: UPDATE_USER_PROFILE_LOADING,
    res: payload,
  };
}

export function updateUserProfileSuccess(payload) {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    res: payload,
  };
}

export function updateUserProfileFailed(payload) {
  return {
    type: UPDATE_USER_PROFILE_FAILED,
    res: payload,
  };
}

export function deleteUserProfileSuccess(payload) {
  return {
    type: DELETE_USER_PROFILE_SUCCESS,
    res: payload,
  };
}

export function deleteUserProfileFailed(payload) {
  return {
    type: DELETE_USER_PROFILE_FAILED,
    res: payload,
  };
}
