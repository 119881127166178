import {
  GET_FAVOURITE_STORES_SUCCESS,
  CREATE_FAVOURITE_STORES_SUCCESS,
  DELETE_FAVOURITE_STORES_SUCCESS,
  GET_MALLS_STORES_SUCCESS,
  SEARCH_STORES_MALLS_SUCCESS,
  SEARCH_STORES_MALLS_FAILED,
  SEARCH_STORES_MALLS_LOADING,
  GET_CITIES,
} from './constants';

export function getFavouritStores(payload) {
  return {
    type: GET_FAVOURITE_STORES_SUCCESS,
    res: payload,
  };
}
export function createFavouritStores(payload) {
  return {
    type: CREATE_FAVOURITE_STORES_SUCCESS,
    res: payload,
  };
}

export function deleteFavouritStores(payload) {
  return {
    type: DELETE_FAVOURITE_STORES_SUCCESS,
    res: payload,
  };
}

export function getMalllsAndStores(payload) {
  return {
    type: GET_MALLS_STORES_SUCCESS,
    res: payload,
  };
}
export function searchMalllsAndStoresSuccess(payload) {
  return {
    type: SEARCH_STORES_MALLS_SUCCESS,
    res: payload,
  };
}
export function searchMalllsAndStoresFailed(payload) {
  return {
    type: SEARCH_STORES_MALLS_FAILED,
    res: payload,
  };
}
export function searchMalllsAndStoresLaoding(payload) {
  return {
    type: SEARCH_STORES_MALLS_LOADING,
    res: payload,
  };
}
export function getCities(payload) {
  return {
    type: GET_CITIES,
    res: payload,
  };
}
