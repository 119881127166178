/* eslint-disable */
import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Button, Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import StoreCard from "../../common/StoreCard/StoreCard";
import { compose } from "redux";
import injectSaga from "../../utils/injectSaga";
import { DAEMON } from "../../utils/constants";
import { useSnackbar } from "react-simple-snackbar";
import axios from "axios";
import history from "../../utils/history";
import saga from "./saga";
import { homePageBanners, shopGallaryResource } from "../../Helpers/api";
// import NavBar from "../../components/NavBar/NavBar";
import {
  aboutArrowLeft,
  aboutArrowRight,
  shoppingCentre,
  locator,
  appleIcon,
  androidIcon,
  store6,
  heartCard,
  heartFilled,
  defaultBannerImage,
  noStore,
  homePagePhone,
} from "../../assets/images";
import "./HomePage.scss";
// import Footer from "../../components/footer/Footer";
// import PromoPage from "../promoPage/promoPage";
// import FidelityPage from "../FidelityPage/FidelityPage";
// import ItemsMapView from "../../components/ItemsMapView/ItemsMapView";
// import ListingPage from "../ListingPage/ListingPage";

function Homepage({
  match: {
    params: { id },
  },
  getMallsStores,
  mallsAndStores,
  createFavStores,
  getFavStores,
  favStores,
  deleteFavStores,
  // searchMallsStores,
  // searchedMallsAndStores,
  createFavStoresSuccess,
  deletedFavStoreSuccess,
}) {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
  const [isCreateLoding, setIsCreateLoding] = useState(false);
  // const [showLogoutModal, setShowLogoutModal] = useState(false);
  // const [showNotificationModal, setShowNotificationModal] = useState(false);
  // const [showNotificationRedIcon, setShowNotificationRedIcon] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  // const [isHomeActive, setIsHomeActive] = useState(true);
  // const [isPromoActive, setIsPromoActive] = useState(false);
  // const [isFidelityActive, setIsFidelityActive] = useState(false);
  // const [showListingPage, setShowListingPage] = useState(false);
  // const [isBannerPresent, setIsBannerPresent] = useState(false);
  // const [showMapView, setShowMapView] = useState(false);
  const [isBannerImagePresent, setIBannerImagePresent] = useState(true);
  const [mallsData, setMallsData] = useState([]);
  const [shopsData, setShopsData] = useState([]);
  const [favStoresIds, setFavStoresId] = useState([]);
  const [favMallsIds, setFavMallsIds] = useState([]);
  const [bannersInfo, setBannersInfo] = useState([
    {
      image: "",
    },
  ]);
  const [isMallStorePresent, setIsMallStorePresent] = useState(false);

  const [openSnackbar] = useSnackbar({
    // position: 'top-center',
    style: {
      position: "absolute",
      backgroundColor: " baby blue",
      // border: '2px solid lightgreen',
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
      bottom: "100%",
      left: 0,
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });
  // const [isFavStoreSelected, setIsFavStoreSelected] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  useEffect(() => {
    // Update the document title using the browser API
    document.addEventListener("scroll", () => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 1 && !isScrolled) {
        setIsScrolled(true);
      } else if (isScrolled && scrolled < 1) {
        setIsScrolled(false);
      }
    });

    return () => {
      document.removeEventListener("scroll", () => {});
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsScrolled(false);
  }, []);

  useEffect(() => {
    setIsCreateLoding(false);
  }, [createFavStoresSuccess, deletedFavStoreSuccess]);

  // Call Malls and stores

  useEffect(() => {
    getFavStores();
    const zipCode = localStorage.getItem("zipCode");
    const payload = {
      zipCode,
    };
    setIsLoading(true);
    getMallsStores(payload);

    // Get Banners data

    const bannersUrl = isTabletOrMobile
      ? `${homePageBanners}?category=TABLET`
      : `${homePageBanners}?category=WEB`;
    axios.get(bannersUrl).then(
      (res) => {
        if (res.data.data.length) {
          setBannersInfo(res.data.data);
          // setIsBannerPresent(true);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const formImageUrl = (imageName) => {
    const imgeUrl = `${shopGallaryResource}${imageName}`;
    const imgeUrlEncode = encodeURI(imgeUrl);
    return imgeUrlEncode;
  };

  // Get  Mall and stores

  useEffect(() => {
    if (mallsAndStores || favStores) {
      if (mallsAndStores && mallsAndStores.data && mallsAndStores.data.data) {
        if (
          (mallsAndStores.data.data.malls &&
            mallsAndStores.data.data.malls.length) ||
          (mallsAndStores.data.data.stores &&
            mallsAndStores.data.data.stores.length)
        ) {
          setIsLoading(false);
          setIsMallStorePresent(true);
          setMallsData(mallsAndStores.data.data.malls);
          setShopsData(mallsAndStores.data.data.stores);
        } else {
          setIsLoading(false);
          setIsMallStorePresent(false);
        }
      }
      const filterFavStoresId = [];
      const filterFavMallsId = [];
      if (
        favStores &&
        favStores.data &&
        favStores.data.data &&
        favStores.data.data.stores
      ) {
        // setIsLoading(false);
        favStores.data.data.stores.forEach((item) =>
          filterFavStoresId.push(item.id)
        );
      }
      if (
        favStores &&
        favStores.data &&
        favStores.data.data &&
        favStores.data.data.malls
      ) {
        // setIsLoading(false);
        favStores.data.data.malls.forEach((item) =>
          filterFavMallsId.push(item.id)
        );
      }
      setFavStoresId(filterFavStoresId);
      setFavMallsIds(filterFavMallsId);
    }
  }, [mallsAndStores, favStores]);

  useEffect(() => {}, [favStores, mallsAndStores]);
  // Create fav stores

  const craeteFavStore = (storeId, type) => {
    const payload = {
      storeId,
      type,
    };
    setIsCreateLoding(true);
    createFavStores(payload);
    setTimeout(() => getFavStores(), 1000);
  };
  const deleteFavStore = (storeId, type) => {
    setIsCreateLoding(true);
    deleteFavStores({ storeId, type });
    setTimeout(() => getFavStores(), 100);
  };

  // useEffect(() => {
  //   setIsScrolled(true);
  // }, [showMapView]);

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  // useEffect(() => {
  //   if (id === "1") {
  //     setIsHomeActive(true);
  //     setIsPromoActive(false);
  //     setIsFidelityActive(false);
  //     setShowListingPage(false);
  //     setShowMapView(false);
  //   }
  //   if (id === "2") {
  //     setIsHomeActive(false);
  //     setIsPromoActive(true);
  //     setIsFidelityActive(false);
  //     setShowListingPage(false);
  //     setShowMapView(false);
  //   }
  //   if (id === "3") {
  //     setIsHomeActive(false);
  //     setIsPromoActive(false);
  //     setIsFidelityActive(true);
  //     setShowListingPage(false);
  //     setShowMapView(false);
  //   }
  //   if (id === "4") {
  //     setIsHomeActive(false);
  //     setIsPromoActive(false);
  //     setIsFidelityActive(false);
  //     setShowListingPage(true);
  //     setShowMapView(false);
  //   }
  // }, [id]);

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };
  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  return (
    <div>
      <header>
        <div
          className={
            bannersInfo && bannersInfo.length > 1
              ? "nav_bar_container"
              : "nav_bar_container mall_details_dots"
          }
        >
          {/* <div>
            <NavBar
              // handleSearchIconClick={handleSearchIconClick}
              setShowLogoutModal={setShowLogoutModal}
              setIsOpenSearchModal={setIsOpenSearchModal}
              showLogoutModal={showLogoutModal}
              isOpenSearchModal={isOpenSearchModal}
              showNotificationModal={showNotificationModal}
              setShowNotificationModal={setShowNotificationModal}
              setShowNotificationRedIcon={setShowNotificationRedIcon}
              showNotificationRedIcon={showNotificationRedIcon}
              isScrolled={isScrolled}
              setIsHomeActive={setIsHomeActive}
              setIsPromoActive={setIsPromoActive}
              setIsFidelityActive={setIsFidelityActive}
              setShowListingPage={setShowListingPage}
              setShowMapView={setShowMapView}
              isHomeActive={isHomeActive}
              isPromoActive={isPromoActive}
              isFidelityActive={isFidelityActive}
              showMapView={showMapView}
              searchMallsStores={searchMallsStores}
              searchedMallsAndStores={searchedMallsAndStores}
              isBannerPresent={isBannerPresent}
            />
          </div> */}

          {bannersInfo && bannersInfo.length > 0 && (
            <div className="home_carousae_container">
              <div className="home_page_background">
                <Carousel
                  autoPlay
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  infiniteLoop
                  interval={5000}
                  transitionTime={2000}
                  selectedItem={currentSlide}
                  onChange={updateCurrentSlide}
                >
                  {bannersInfo.map((banner) => (
                    <div
                      className="first_photo"
                      style={{
                        backgroundImage:
                          isBannerImagePresent && banner && banner.image
                            ? `url(${formImageUrl(banner.image)})`
                            : `url(${defaultBannerImage})`,
                        // backgroundImage: `url(${defaultBannerImage})`,
                      }}
                    >
                      <div className="text_container">
                        <p className="carousal_text_heading">{banner.title}</p>

                        <p className="carousal_text_content">{banner.body}</p>
                      </div>
                      {isBannerImagePresent && banner && banner.image && (
                        <img
                          style={{
                            width: "0",
                            visibility: "hidden",
                          }}
                          src={formImageUrl(banner.image)}
                          alt="default"
                          onError={(e) => {
                            console.log(e);
                            setIBannerImagePresent(false);
                          }}
                        />
                      )}
                      {banner.link && banner.link.length && (
                        <Button
                          className="landing_login_button homePageDiscover"
                          type="submit"
                          onClick={() => window.open(`http://${banner.link}`)}
                        >
                          {banner.buttonLabel !== ""
                            ? banner.buttonLabel
                            : "SCOPRI"}
                        </Button>
                      )}
                    </div>
                  ))}
                </Carousel>
                {bannersInfo.length > 1 && (
                  <button
                    className="corousal_left_button"
                    type="button"
                    onClick={prev}
                  >
                    <img src={aboutArrowLeft} alt="left button" />
                  </button>
                )}
                {bannersInfo.length > 1 && (
                  <button
                    className="corousal_right_button"
                    type="button"
                    onClick={next}
                  >
                    <img src={aboutArrowRight} alt="right arrow" />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </header>

      <div className="home_page_content">
        {isLoading ? (
          <Loader active inline className="home_page_loader" />
        ) : isMallStorePresent ? (
          <div className="malls_and_shop_container">
            {mallsData && mallsData.length > 0 && (
              <section className="shopping_center">
                <div className="shopping_center_heading">
                  CENTRO COMMERCIALE
                </div>
                <div className="shopping_center_details_container">
                  {mallsData.map((mall) => (
                    <StoreCard
                      key={mall.id}
                      isCreateFav={
                        favMallsIds.includes(mall.id) &&
                        localStorage.getItem("token")
                      }
                      shop={mall}
                      isCreateLoding={isCreateLoding}
                      deleteFavStore={deleteFavStore}
                      craeteFavStore={craeteFavStore}
                      openSnackbar={openSnackbar}
                      type="mall"
                    />
                    // eslint-disable-next-line react/no-array-index-key

                    // <div className="shoppping_centre_card" key={mall.id}>
                    //   <div className="card_heart_container">
                    //     {favMallsIds.includes(mall.id) &&
                    //     localStorage.getItem("token") ? (
                    //       <div
                    //         className="heart_button"
                    //         type="button"
                    //         onClick={() =>
                    //           !isCreateLoding && deleteFavStore(mall.id, "mall")
                    //         }
                    //       >
                    //         <img src={heartFilled} alt="heart" />
                    //       </div>
                    //     ) : (
                    //       <div
                    //         className="heart_button"
                    //         onClick={() => {
                    //           if (localStorage.getItem("token")) {
                    //             !isCreateLoding &&
                    //               craeteFavStore(mall.id, "mall");
                    //           } else {
                    //             openSnackbar(t("notLogged"));
                    //           }
                    //         }}
                    //       >
                    //         <img src={heartCard} alt="heart" />
                    //       </div>
                    //     )}
                    //   </div>
                    //   <div className="shoppping_centre_card_image">
                    //     <img
                    //       src={formImageUrl(mall.image)}
                    //       alt="shopping centre"
                    //       onError={(e) => {
                    //         e.target.src = shoppingCentre;
                    //       }}
                    //       onClick={() =>
                    //         history.push(`/mallDetails/${mall.id}`)
                    //       }
                    //     />
                    //   </div>
                    //   <div>
                    //     <div
                    //       className="shopping_centre_name"
                    //       onClick={() =>
                    //         history.push(`/mallDetails/${mall.id}`)
                    //       }
                    //     >
                    //       {mall.name}
                    //     </div>
                    //     <div className="shopping_center_address_container">
                    //       <div>
                    //         <img src={locator} alt="locator" />
                    //       </div>
                    //       <div className="shopping_centre_address">
                    //         {`${mall.address.address},  ${mall.address.city},  ${mall.address.state},  ${mall.address.zip}, ${mall.address.county}`}
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                  ))}
                </div>
              </section>
            )}

            <section className="shopping_center">
              {shopsData && shopsData.length > 0 && (
                <div className="shopping_center_heading">NEGOZI</div>
              )}
              <div className="shopping_center_details_container">
                {/* {[...Array(8)].map((e, i) => ( */}
                {shopsData.map((shop) => (
                  <StoreCard
                    key={shop.id}
                    isCreateFav={
                      favStoresIds.includes(shop.id) &&
                      localStorage.getItem("token")
                    }
                    shop={shop}
                    isCreateLoding={isCreateLoding}
                    deleteFavStore={deleteFavStore}
                    craeteFavStore={craeteFavStore}
                    openSnackbar={openSnackbar}
                    type="store"
                  />
                  // eslint-disable-next-line react/no-array-index-key
                  // <div className="shoppping_centre_card" key={shop.id}>
                  //   <div className="card_heart_container">
                  //     {favStoresIds.includes(shop.id) &&
                  //     localStorage.getItem("token") ? (
                  //       <button
                  //         className="heart_button"
                  //         type="button"
                  //         onClick={() =>
                  //           !isCreateLoding && deleteFavStore(shop.id, "store")
                  //         }
                  //       >
                  //         <img src={heartFilled} alt="heart" />
                  //       </button>
                  //     ) : (
                  //       <button
                  //         className="heart_button"
                  //         type="button"
                  //         onClick={() => {
                  //           if (localStorage.getItem("token")) {
                  //             !isCreateLoding &&
                  //               craeteFavStore(shop.id, "store");
                  //           } else {
                  //             openSnackbar(t("notLogged"));
                  //           }
                  //         }}
                  //       >
                  //         <img src={heartCard} alt="heart" />
                  //       </button>
                  //     )}
                  //   </div>
                  //   <div className="shoppping_centre_card_image">
                  //     {shop.image ? (
                  //       <img
                  //         src={formImageUrl(shop.image)}
                  //         alt="shopping centre"
                  //         onClick={() =>
                  //           history.push(`/shopDetails/${shop.id}`)
                  //         }
                  //         onError={(e) => {
                  //           e.target.src = store6;
                  //         }}
                  //       />
                  //     ) : (
                  //       <img
                  //         src={store6}
                  //         alt="shopping centre"
                  //         onClick={() =>
                  //           history.push(`/shopDetails/${shop.id}`)
                  //         }
                  //         onError={(e) => {
                  //           e.target.src = store6;
                  //         }}
                  //       />
                  //     )}
                  //   </div>
                  //   <p
                  //     className="shopping_centre_name"
                  //     onClick={() => history.push(`/shopDetails/${shop.id}`)}
                  //   >
                  //     {shop.name}
                  //   </p>
                  //   <div className="shopping_center_address_container">
                  //     <img src={locator} alt="locator" />
                  //     {shop && shop.address && shop.address.address && (
                  //       <p className="shopping_centre_address">
                  //         {`${shop.address.address},  ${shop.address.city},  ${shop.address.state},  ${shop.address.zip}, ${shop.address.county}`}
                  //       </p>
                  //     )}
                  //   </div>
                  // </div>
                ))}
              </div>
            </section>
          </div>
        ) : (
          !isLoading &&
          !isMallStorePresent && (
            <div className="no_store_image_container">
              <img src={noStore} alt="No store" />
              <p className="no_store_found">
                Siamo spiacenti, nessun negozio attivo nella tua zona
              </p>
            </div>
          )
        )}

        {/* <div className="home_phone_image_container">
          <div className="phone_container">
            <p className="phone_container_heading">
              Il Primo Centro Commerciale Digitale d’Italia
            </p>
            <p className="phone_container_content">
              Scarica l'APP per poter beneficiare delle promozioni dedicate e
              restare in contatto con gli esercenti di tuo interesse{" "}
            </p>
            <Button className="playstore_button home_page_play_store">
              <img
                src={appleIcon}
                alt="apple-icon"
                style={{ margin: "0 5px 3px 0" }}
              />{" "}
              APP STORE
            </Button>
            <Button className="playstore_button home_page_apple">
              <img
                src={androidIcon}
                alt="apple-icon"
                style={{ margin: "0 5px 3px 0" }}
              />{" "}
              PLAY STORE
            </Button>
          </div>
        </div> */}

        <div className="new_home_phone_image_container">
          <div className="new_phone_container">
            <p className="new_phone_container_heading">
              Il Primo Centro Commerciale Digitale d’Italia
            </p>
            <p className="new_phone_container_content">
              Scarica l'APP per poter beneficiare delle promozioni dedicate e
              restare in contatto con gli esercenti di tuo interesse{" "}
            </p>
            <Button className="playstore_button home_page_play_store_button">
              <img
                src={appleIcon}
                alt="apple-icon"
                style={{ margin: "0 5px 3px 0" }}
              />{" "}
              APP STORE
            </Button>
            <Button className="playstore_button home_page_apple_store">
              <img
                src={androidIcon}
                alt="apple-icon"
                style={{ margin: "0 5px 3px 0" }}
              />{" "}
              PLAY STORE
            </Button>
          </div>
          <div className="home_page_phone_conatainer">
            <img
              src={homePagePhone}
              alt="home page phone"
              className="new_phone_image"
            />
          </div>
        </div>
      </div>

      {/* {isPromoActive && <PromoPage />} */}

      {/* {isFidelityActive && <FidelityPage />} */}

      {/* {showMapView && (
        <ItemsMapView
          setShowListingPage={setShowListingPage}
          setShowMapView={setShowMapView}
        />
      )} */}
      {/* {showListingPage && (
        <ListingPage
          setIsHomeActive={setIsHomeActive}
          setIsPromoActive={setIsPromoActive}
          setIsFidelityActive={setIsFidelityActive}
          setShowMapView={setShowMapView}
          setShowListingPage={setShowListingPage}
          setIsOpenSearchModal={setIsOpenSearchModal}
          // searchedMallsAndStores={searchedMallsAndStores}
          searchMallsStores={searchMallsStores}
        />
      )} */}
      {/* <div className="home_page_footer">
        <Footer />
      </div> */}
    </div>
  );
}

Homepage.propTypes = {
  match: PropTypes.object,
  getMallsStores: PropTypes.func,
  mallsAndStores: PropTypes.object,
  createFavStores: PropTypes.object,
  getFavStores: PropTypes.object,
  favStores: PropTypes.object,
  deleteFavStores: PropTypes.object,
  searchMallsStores: PropTypes.func,
  searchedMallsAndStores: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
  return {
    getMallsStores: (payload) =>
      dispatch({ type: "GET_MALLS_STORES", payload }),
    createFavStores: (payload) =>
      dispatch({ type: "CREATE_FAVOURITE_STORE", payload }),
    getFavStores: (payload) =>
      dispatch({ type: "GET_FAVOURITE_STORES", payload }),
    deleteFavStores: (payload) =>
      dispatch({ type: "DELETE_FAVOURITE_STORES", payload }),
    searchMallsStores: (payload) =>
      dispatch({ type: "SEARCH_MALLS_STORES", payload }),
  };
}

export function mapStateToProps(state) {
  return {
    mallsAndStores: state.homePageFav.mallsStores,
    favStores: state.homePageFav.favStores,
    searchedMallsAndStores: state.homePageFav,
    createFavStoresSuccess: state.homePageFav.createdFavStore,
    deletedFavStoreSuccess: state.homePageFav.deletedFavStore,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: "mallsStores", saga, mode: DAEMON });

export default compose(withConnect, memo, withSaga)(Homepage);
