import { OPERATOR_SIGN_UP_SUCCESS, OPERATOR_SIGN_UP_FAILED } from './constants';

export function operatorSignUp(payload) {
  return {
    type: OPERATOR_SIGN_UP_SUCCESS,
    res: payload,
  };
}

export function operatorSignUpFailed(payload) {
  return {
    type: OPERATOR_SIGN_UP_FAILED,
    res: payload,
  };
}
