/**
Sign up user
 */

import { call, put, takeLatest } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
import axios from 'axios';
import { signup } from './actions';
import { userSignUp } from '../../Helpers/api';

/** function that returns an axios call */
function signupApi(payload) {
  return axios.post(
    // 'https://retapps-dev.demos.classicinformatics.com/api/cli/profiles/signup',
    userSignUp,
    payload,
  );
}

/**
 * Github repos request/response handler
 */
export function* signupSaga(action) {
  try {
    // Call our request helper (see 'utils/request')
    const SignUpResponse = yield call(signupApi, action.payload);
    yield put(signup(SignUpResponse));
    // yield put(push('/verifyMail'));
  } catch (err) {
    // console.log(err);
    // yield put(repoLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* signupData() {
  // Watches for SIGN_UP actions and calls signup when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest('SIGN_UP_USER', signupSaga);
}
