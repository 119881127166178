import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
// eslint-disable-next-line import/no-cycle
import TabNavBar from './TabNavBar';
import './TabNavigationModal.scss';

function TabNavigationModal(props) {
  return (
    <div>
      <Modal
        closeIcon={{
          style: { top: '1.0535rem', right: '1rem' },
          color: 'black',
          name: 'close',
        }}
        className="navigation_modal"
        open={props.showResetPassordModal}
        onClose={() => props.setShowResetPassordModal(false)}
      >
        <Modal.Content style={{ textAlign: 'center' }}>
          <div className="nav_modal_navbar_container">
            <TabNavBar
              isScrolled
              isTabNavigation
              setShowResetPassordModal={props.setShowResetPassordModal}
            />
          </div>
          <div className="tab_nav_name">
            <button
              type="button"
              className="no_button"
              onClick={() => props.handleTabButtonClick('home')}
            >
              Home
            </button>
            <button
              className="no_button"
              type="button"
              onClick={() => props.handleTabButtonClick('promo')}
            >
              Promo
            </button>
            <button
              className="no_button"
              type="button"
              onClick={() => props.handleTabButtonClick('fidelity')}
            >
              Fidelity
            </button>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
}
TabNavigationModal.propTypes = {
  showResetPassordModal: PropTypes.bool,
  setShowResetPassordModal: PropTypes.func,
  handleTabButtonClick: PropTypes.func,
};

export default TabNavigationModal;
