/* eslint-disable */
import React, { Component } from "react";
// import NavBar from "../NavBar/NavBar";
// import Footer from "../footer/Footer";

class CookiePolicy extends Component {
  render() {
    return (
      <div>
        {/* <NavBar mapview showMapView/> */}
        <div className="privacy_content">
          <p className="privacy_heading">Cookie Policy</p>
          <p className="privacy_terms">
            Gentile Utente,
            <br />
            qui nel seguito sono fornite le informazioni relative al trattamento
            dei dati personali effettuato mediante l’utilizzo di cookie ed altri
            identificatori analoghi dal sito my-stores.it (nel seguito, il
            “Sito”), le finalità del trattamento nonché le opzioni a
            disposizione dell’Utente per manifestare e modificare le proprie
            preferenze ed il proprio consenso. La presente Cookie Policy può
            essere soggetta a revisioni, anche in conseguenza di modifiche e di
            integrazioni normative, per cui si invita l’Utente a prenderne
            opportuna conoscenza nell’apposita sezione del Sito. Titolare del
            Trattamento è RetAPPs S.r.l., con sede legale in Brescia (BS), via
            E. Stassano n. 29, Cod. Fisc. e P.IVA 03388720983, iscritta al
            Registro delle Imprese di Brescia al n. BS-530039 (nel seguito,
            <p className="privacy_title">1. Cosa è un cookie?</p>
            <p className="privacy_terms">
              Un cookie è un file, costituito da porzioni di codice, che viene
              scaricato e collocato sul computer, sul tablet, sullo smartphone o
              su altro dispositivo dell’Utente (nel seguito, il “Dispositivo”)
              quando questi accede al Sito e, grazie al rilevamento di dati,
              supporta il Titolare del Trattamento nell'erogazione dei servizi
              resi sul e per il tramite del Sito. Altre tecnologie di raccolta o
              di accesso alle informazioni del Dispositivo, analoghe a quella
              dei cookie, devono intendersi qui parimenti ed unitariamente
              disciplinate. In via generale ed in funzione della tipologia, i
              cookie consentono di memorizzare e recuperare informazioni sulla
              visita dell’Utente al Sito, sulle sue abitudini di navigazione,
              sul browser o sul computer da cui accede, oltre a riconoscere
              l’Utente come tale e ciò al fine sia di agevolarne le visite
              successive sia di migliorare le funzionalità del Sito
              personalizzandone i contenuti.
            </p>
            <div>
              <p className="privacy_title">
                2. Che tipo di cookie adottiamo sul Sito e con quali finalità?
              </p>
              <p className="privacy_terms">
                I cookie si possono classificare in base a diversi criteri.
              </p>

              <div>
                <ul className="privacy_title">
                  In funzione di chi li amministra o li configura, possono
                  definirsi:
                  <li>
                    cookie proprietari: sono inviati al Dispositivo da un
                    dominio o un sistema amministrato da RetAPPS;
                  </li>
                  <li>
                    cookie di terze parti: sono inviati al Dispositivo da un
                    dominio o un sistema di un’azienda esterna a RetAPPs.
                  </li>
                </ul>
              </div>
              <div>
                <ul className="privacy_title">
                  In funzione della durata, possono definirsi:
                  <li>
                    {" "}
                    cookie di sessione: si cancellano quando l’Utente esce dal
                    Sito;
                  </li>
                  <li>
                    {" "}
                    cookie persistenti: rimangono memorizzati sul Dispositivo
                    per un periodo di tempo superiore.
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <ul className="privacy_title">
                In funzione della finalità, possono definirsi:
                <li>
                  {" "}
                  cookie tecnici: sono necessari per il corretto funzionamento
                  del Sito e dei servizi ivi offerti e non possono essere
                  disattivati. Vengono impostati in risposta alle azioni
                  dell’Utente che configurano una richiesta di servizi resi sul
                  o per il tramite del Sito come, a titolo esemplificativo,
                  l’accesso, la registrazione al Sito, l’utilizzo o la
                  compilazione di moduli, l'impostazione delle preferenze
                  relative alla privacy; permettono di acquisire informazioni
                  riguardo al browser ed al sistema operativo così come ad altri
                  identificativi del Dispositivo, all’indirizzo IP, alla
                  localizzazione del Dispositivo, all’identificazione della
                  sessione, dell’accesso ad aree riservate.
                </li>
                <li>
                  L’Utente può impostare il proprio browser per bloccare
                  l’attivazione di questi Cookie, tenendo tuttavia presente che,
                  per conseguenza, alcune parti del Sito potrebbero non
                  funzionare correttamente. Cookie analitici: permettono di
                  conoscere, a titolo esemplificativo, il numero di pagine
                  visitate dall’Utente, il luogo da cui accede, la frequenza e
                  il tasso di ripetizione delle visite, la durata delle visite,
                  l’apertura di una comunicazione commerciale. Il Sito non
                  utilizza cookie analitici.
                </li>
              </ul>
            </div>
            <div>
              <p className="privacy_title">3. Elenco dei cookie</p>
              <p className="privacy_terms">- elenco cookie utilizzati</p>
            </div>
          </p>

          <p className="privacy_title">
            4. Manifestazione e revoca del consenso
          </p>
          <p className="privacy_terms">
            In occasione del primo accesso al Sito, l’Utente è invitato a
            prendere conoscenza della presente Informativa, ad esprimere le
            proprie preferenze e il proprio consenso all’utilizzo dei cookie.
            L’Utente può in ogni momento modificare le proprie preferenze ed il
            proprio consenso, tenendo tuttavia presente che, per conseguenza,
            alcuni servizi resi sul o per il tramite del Sito potrebbero non
            funzionare correttamente.
          </p>
          <p className="privacy_terms">
            L’Utente può esercitare i propri diritti in materia di protezione
            dei dati personali in qualunque momento e a titolo gratuito,
            inviando una comunicazione in forma scritta all’indirizzo PEC:
            retapps@pec.it o all’indirizzo e-mail: support@my-stores.it o,
            ancora, a mezzo posta cartacea a: RetAPPs S.r.l., via E. Stassano n.
            29, 25125 Brescia (BS). Nel contattare RetAPPs per le finalità
            sopraindicate, l’Utente deve aver cura di indicare il proprio nome e
            cognome, l’indirizzo di residenza o domicilio, l’indirizzo e-mail ed
            il recapito telefonico, così che la richiesta possa essere gestita.
          </p>
          <div>
            <p className="privacy-date">Ultima modifica in data 02/10/2020</p>
          </div>
        </div>
        {/* <NavBar mapview showMapView/>
        <Footer/> */}
      </div>
    );
  }
}

export default CookiePolicy;
