import React from "react";
import PropTypes from "prop-types";
import { Header, Modal, Button } from "semantic-ui-react";
import history from "../../../utils/history";
import { shop, friendship } from "../../../assets/images";
import "./RegistrationModal.scss";
const RegistrationModal = (props) => (
  <Modal
    style={{
      width: "684px",
      height: "395px",
      borderRadius: "8px",
      boxShadow: "0 0 34px 0 rgba(0, 0, 0, 0.5)",
      backgroundColor: "#ffffff",
      paddingTop: "10px",
    }}
    closeIcon={{
      style: { top: "1.0535rem", right: "1rem" },
      color: "black",
      name: "close",
    }}
    open={props.showRegistrationModal}
    onClose={() => props.setShowRegistrationModal(false)}
  >
    <Modal.Header style={{ textAlign: "center", border: "none" }}>
      Registrazione
    </Modal.Header>
    <Modal.Content
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <div
        className="content-1"
        style={{ maxWidth: "320px", borderRight: "1px solid #d8d8d8" }}
      >
        <img src={friendship} alt="" />
        <Modal.Description>
          <Header
            style={{
              fontFamily: "Open Sans",
              fontSize: "18px",
              fontWeight: "bold",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "0.14px",
              color: "#333333",
            }}
          >
            Registrati come utente
          </Header>
          <p
            style={{
              fontFamily: "Open Sans",
              fontSize: "14px",
              fontWeight: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "0.14px",
              textAlign: "center",
              color: "#333333",
              padding: "10px",
            }}
          >
            Da qui potrai cercare e visualizzare i negozi di tuo interesse
            gestendo quelli preferiti
          </p>
          <Button
            className="registerModalButton"
            style={{
              marginTop: "10px",
            }}
            // type="submit"
            onClick={() => {
              props.setShowSignUpModal(true);
              props.setShowRegistrationModal(false);
            }}
          >
            CONTINUA
          </Button>
        </Modal.Description>
      </div>
      <div className="content-2" style={{ maxWidth: "320px" }}>
        <img src={shop} alt="" />
        <Modal.Description>
          <Header
            style={{
              fontFamily: "Open Sans",
              fontSize: "18px",
              fontWeight: "bold",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "0.14px",
              color: "#333333",
            }}
          >
            Registra la tua attività
          </Header>
          <p
            style={{
              fontFamily: "Open Sans",
              fontSize: "14px",
              fontWeight: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "0.14px",
              textAlign: "center",
              color: "#333333",
              padding: "0 10px",
            }}
          >
            Creare e gestire direttamente la comunicazione e le promozioni del
            tuo punto vendita
          </p>
          <Button
            className="registerModalButton"
            style={{ marginTop: "10px" }}
            // type="submit"
            onClick={() => {
              if (history.location.pathname !== "/register-store") {
                history.push("/register-store");
              }
              props.setShowRegistrationModal(false);
            }}
          >
            CONTINUA
          </Button>
        </Modal.Description>
      </div>
    </Modal.Content>
  </Modal>
);

RegistrationModal.propTypes = {
  showRegistrationModal: PropTypes.bool,
  setShowRegistrationModal: PropTypes.func,
  setShowSignUpModal: PropTypes.func,
};

export default RegistrationModal;
