/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Button, Loader } from 'semantic-ui-react';
import { useAlert } from 'react-alert';
import axios from 'axios';
import { useSnackbar } from 'react-simple-snackbar';
import { resetPasswprd } from '../../../Helpers/api';
import './ResetPasswordModal.scss';

const ResetPasswordModal = props => {
  const [newLetterEmail, setNewLetterEmail] = useState('');
  const [newsLetterError, setNewsLetterError] = useState({});
  const [isNewsLoading, setIsNewsLoading] = useState(false);
  const alert = useAlert();
  const [openSnackbarRed] = useSnackbar({
    style: {
      position: 'absolute',
      backgroundColor: 'red',
      border: '2px solid lightgreen',
      color: 'lightblue',
      fontFamily: 'Menlo, monospace',
      fontSize: '20px',
      textAlign: 'center',
      bottom: '100%',
      left: 0,
    },
    closeStyle: {
      color: 'lightcoral',
      fontSize: '16px',
    },
  });
  const validateFormData = () => {
    const error = {};
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (newLetterEmail) {
      if (!regEmail.test(newLetterEmail)) {
        error.email = 'Invalid email';
      }
    } else {
      error.email = "Email can't be empty";
    }
    setNewsLetterError(error);
    return error;
  };
  const onSumit = () => {
    // props.setShowResetPassordModal(false);
    const errors = validateFormData();
    if (Object.keys(errors).length === 0) {
      setIsNewsLoading(true);
      axios
        .post(resetPasswprd, {
          tid: '01',
          email: newLetterEmail,
        })
        .then(
          res => {
            setIsNewsLoading(false);
            if (res && res.data && res.data.responseCode) {
              const error = {};
              if (res.data.responseCode === 2) {
                // openSnackbar('Profile not found for the given email !');
                error.email = 'Profile not found for the given email !';
                setNewsLetterError(error);
              }
            } else {
              // openSnackbar('Email sent for password reset !');
              alert.success('Email sent for password reset !');
              setNewLetterEmail('');
              // props.setShowResetPassordModal(false);
            }
          },
          err => {
            openSnackbarRed('Something went wrong !');
            setIsNewsLoading(false);

            console.log(err);
          },
        );
    }
  };
  return (
    <Modal
      style={{
        width: '506px',
        height: '323px',
        borderRadius: '8px',
        boxShadow: '0 0 34px 0 rgba(0, 0, 0, 0.5)',
        backgroundColor: '#ffffff',
        padding: '20px',
      }}
      closeIcon={{
        style: { top: '1.0535rem', right: '1rem' },
        color: 'black',
        name: 'close',
      }}
      open={props.showResetPassordModal}
      onClose={() => props.setShowResetPassordModal(false)}
    >
      <Modal.Header className="reset_passord_header">
        Recupera password
      </Modal.Header>
      <Modal.Content style={{ textAlign: 'center' }}>
        <Modal.Description style={{ textAlign: 'center' }}>
          <p className="reset_password_text">
            Enter your email address, we will send you a link where you can
            change your password.
          </p>
          <div style={{ position: 'relative' }}>
            <Input
              type="email"
              className="reset_password_email"
              placeholder="@ Your Email"
              value={newLetterEmail}
              // onChange={e => setEmail(e.target.value)}
              onChange={e => {
                setNewLetterEmail(e.target.value);
              }}
            />
            {newsLetterError && newsLetterError.email && (
              <span className="ladingFormError resetFormNewsError">
                {newsLetterError.email}
              </span>
            )}
          </div>

          <Button
            className="registerModalButton"
            style={{ marginTop: '30px' }}
            type="button"
            onClick={() => onSumit()}
          >
            {isNewsLoading ? (
              <Loader active inline className="login_loader ladingFormLoader" />
            ) : (
              'INVIA'
            )}
          </Button>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
ResetPasswordModal.propTypes = {
  showResetPassordModal: PropTypes.bool,
  setShowResetPassordModal: PropTypes.func,
};

export default ResetPasswordModal;
