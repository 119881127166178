/* eslint-disable */
import React, { Component } from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../footer/Footer";

class Tcu extends Component {
  render() {
    return (
      <>
        {/* <NavBar mapview showMapView/> */}
        <div className="privacy_content">
          <h1 className="privacy_title">
            Termini e Condizioni Generali per la creazione di un Profilo
            Personale e l’utilizzo della piattaforma <b>My-Stores</b>
          </h1>
          <p className="privacy_terms">
            Le presenti Condizioni Generali disciplinano la registrazione
            dell’Utente alla piattaforma My-Stores (nel seguito, “My-Stores” o
            “la Piattaforma”), per la creazione di un Profilo Personale e
            l’utilizzo di My-Stores attraverso le applicazioni per smartphone
            (nel seguito, “le App”) ed il sito my-stores.it (nel seguito, “il
            Sito”).
          </p>
          <p className="privacy_terms">
            My-Stores è una piattaforma in Cloud di Attraction & Engagement, per
            Esercenti, Circuiti, Centri Commerciali Fisici e Naturali e Comunità
            Multi-Esercente che consente agli utenti di cercare .
          </p>
          <p className="privacy_terms">
            La tecnologia digitale, con sito ed eCommerce integrati, può
            rivelarsi un grande supporto allo sviluppo del retail tradizionale
            di prossimità dando vita ad un sistema «retail-centrico» che
            valorizza il punto vendita fisico, con logiche di fidelizzazione,
            rivolgendosi ai consumatori che gravitano nel bacino d’utenza, ma
            aprendosi anche a tutta la platea On-Line.
          </p>
          <p className="privacy_title">1. Titolarità di My-Stores</p>
          <p className="privacy_terms">
            Titolare della Piattaforma, delle App e del Sito è RetAPPs S.r.l.,
            con sede legale in Brescia (BS), via E. Stassano n. 29, Cod. Fisc. e
            P.IVA 03388720983, iscritta al Registro delle Imprese di Brescia al
            n. BS-530039 (nel seguito, “RetAPPs”).
          </p>
          <p className="privacy_title">2. Definizioni:</p>
          <p className="privacy_terms">
            Utente: è la persona fisica che si registra alla Piattaforma
            My-Stores; Esercente o Retailer: è il titolare dell’attività che si
            registra alla piattaforma ed attiva la sua vetrina digitale
            associata ad uno o più punti vendita fisici presenti sul territorio;
            Negozio o Shop: è il punto vendita fisico dove l’esercente esercita
            la sua attività; Negozio Preferito: è il punto vendita fisico
            selezionato volontariamente dall’utente per il quale ha dichiarato
            interesse a ricevere comunicazioni ed offerte; Centro Commerciale o
            Mall: è un aggregato di punti vendita fisici presenti sul
            territorio. Può essere fisico, se esiste una struttura fisica
            definita, oppure naturale o virtuale nel caso di aggregazione senza
            una struttura fisica definita sul territorio; Fidelity: è la
            possibilità che viene data all’utente di partecipare ad una campagna
            promozionale definita da ciascun esercente, potendo accumulare punti
            e ricevere sconti ed offerte dedicate sia nei negozi fisici sia
            nello shop e-Commerce online.
          </p>
          <p className="privacy_title">
            3. Registrazione a My-Stores e fruizione dei servizi
          </p>
          <p className="privacy_terms">
            3.1 Il rapporto fra My-Stores e l’Utente è regolato dai presenti
            Termini e Condizioni (nel seguito, “TCU”), ivi ritenendovi compresi
            ogni nota, avviso legale, contenuto contrattuale o anche solo
            informativo pubblicati nelle varie sezioni delle App e del Sito, con
            esclusione di qualsivoglia diversa pattuizione laddove non
            concordata espressamente in forma scritta.
          </p>
          <p className="privacy_terms">
            3.2 La registrazione dell’Utente e l’utilizzo dei servizi offerti da
            My-Stores implica l’accettazione dei TCU, della Privacy Policy e
            della Cookie Policy. Per il caso in cui l’Utente utilizzasse i
            servizi di My-Stores per conto di Terzi, accettando TCU, Privacy
            Policy e Cookie Policy dichiara e garantisce di avere il potere di
            rappresentare, obbligare e vincolare il Terzo, liberando RetAPPs da
            ogni e qualsivoglia responsabilità nei riguardi del Terzo.
          </p>
          <p className="privacy_terms">
            3.3 RetAPPs si riserva il diritto di aggiornare e modificare, in
            tutto o in parte ed in qualsiasi momento, TCU, Privacy Policy e
            Cookie Policy, così come eventuali integrazioni contrattuali ivi
            richiamati. L’Utente è invitato, pertanto, a prenderne
            periodicamente visione, poiché i contenuti, così come modificati,
            avranno vigore dal momento della pubblicazione su My-Stores e si
            intenderanno da quel momento conosciuti dall’Utente.
          </p>
          <p className="privacy_terms">
            3.4 I contenuti di My-Stores sono destinati agli Utenti che
            rivestono la qualità di Consumatori, intendendosi per tali le
            persone fisiche che, in relazione alla fruizione dei servizi offerti
            su o per il tramite di My-Stores, agiscono per scopi estranei
            all'attività imprenditoriale, commerciale, professionale o
            artigianale eventualmente svolta.
          </p>
          <p className="privacy_title">
            4. Registrazione e Creazione di un Profilo personale{" "}
          </p>
          <p className="privacy_terms">
            4.1 My-Stores è accessibile dall’Utente previa indicazione del luogo
            di interesse individuato quale Città, Comune o CAP. L’indicazione di
            un luogo è essenziale al fine di poter fruire delle funzionalità
            offerte da My-Stores.
          </p>
          <p className="privacy_terms">
            4.2 Le offerte, le promozioni e, in via generale, tutti i servizi
            resi su o per il tramite di My-Stores sono accessibili e fruibili
            dall’Utente solo previa registrazione e creazione di un Profilo
            personale, protetto da password e verificato tramite OPT link in
            fase di registrazione al servizio. In sede di registrazione,
            all’Utente viene chiesto di indicare taluni dati anagrafici, di
            posizione e di contatto, quali: nome e cognome, data di nascita,
            indirizzo e-mail e localizzazione. I dati ritenuti necessari onde
            poter fruire dei servizi offerti da My-Stores sono contrassegnati da
            asterisco. er le finalità indicate nell’apposita Informativa,
            potranno altresì venire richiesti dati ulteriori, quali, a titolo
            esemplificativo: sesso, composizione del nucleo famigliare,
            interessi, attività lavorativa, indirizzo di spedizione, che
            l’Utente potrà facoltativamente comunicare.
          </p>
          <p className="privacy_terms">
            4.4 RetAPPs, sia nella fase di creazione del Profilo personale sia
            in seguito, potrà richiedere all’Utente la presentazione o l’invio
            di copia di un documento di identità per la verifica dei dati
            forniti. L’eventuale non veridicità dei dati forniti in sede di
            registrazione e creazione del Profilo personale ne comporterà la
            cancellazione.
          </p>
          <p className="privacy_terms">
            4.5 L’Utente è tenuto alla conservazione delle proprie credenziali
            di accesso al Profilo personale ed è responsabile per ogni eventuale
            uso di My-Stores da parte di chiunque vi acceda con le sue
            credenziali. L’Utente si impegna ad informare tempestivamente
            RetAPPs in caso di sospetta violazione del proprio Profilo
            personale, ovvero di utilizzo da parte di terzi non autorizzati.
            L’Utente può, in qualunque momento, procedere alla cancellazione del
            proprio Profilo personale, seguendo le indicazioni riportate
            nell’apposita sezione.
          </p>
          <p className="privacy_terms">
            4.6 RetAPPs, per le finalità indicate nella Privacy Policy, potrà
            fornire all’Utente supporto tecnico, così come inviare segnalazioni
            o comunicazioni relative, in generale, ai servizi resi su o per il
            tramite di My-Stores ai recapiti indicati dall’Utente in sede di
            registrazione.
          </p>
          <p className="privacy_terms">
            4.7 RetAPPs si riserva la facoltà di sospendere o cancellare il
            Profilo personale dell’Utente in caso di violazione degli obblighi
            previsti dai presenti TCU.
          </p>
          <p className="privacy_terms">
            4.8 La registrazione a My-Stores ed il Profilo personale hanno
            validità di 24 mesi dalla registrazione; decorso il termine, per
            poter continuare a fruire delle offerte, delle promozioni e, in via
            generale, dei servizi resi sul o per il tramite di My-Stores,
            l’Utente dovrà procedere a nuova registrazione, seguendo le relative
            indicazioni.
          </p>
          <p className="privacy_title">
            5. Negozi Preferiti e Visualizzazione delle Offerte
          </p>
          <p className="privacy_terms">
            5.1 Una volta registrato, l’Utente può accedere alle funzionalità di
            My-Stores e cercare, selezionare e salvare nel proprio Profilo
            personale i suoi Negozi Preferiti.
          </p>
          <p className="privacy_terms">
            5.2 La selezione dei Negozi Preferiti consente all’Utente di
            visualizzarne i contenuti, di accedere agli e-Commerce e procedere
            all’acquisto di beni e servizi, di visualizzarne offerte e
            promozioni nell’apposita sezione, di accedere ai programmi fedeltà
            di ciascun Negozio Preferito ed anche di ricevere comunicazione di
            offerte, promozioni, informazioni su prodotti e servizi ai recapiti
            indicati in sede di registrazione. L’Utente, per le finalità
            indicate nella Privacy Policy, può ricevere le anzidette
            comunicazioni esclusivamente da My-Stores e dai propri Negozi o
            Centri Commerciali Preferiti.
          </p>
          <p className="privacy_title">6. Negozi Fisici Preferiti</p>
          <p className="privacy_terms">
            L’Utente può beneficiare dei servizi disponibili sulla Piattaforma
            anche facendo acquisti presso i Negozi Fisici che aderiscono al
            circuito My-Stores, all’interno dei quali trova esposto un QR-Code
            che, inquadrato con la fotocamera del proprio smartphone, consente
            l’identificazione da parte dell’esercente ed il check-in
            dell’Utente. L’Utente può in questo modo visualizzare tutte le
            offerte a lui riservate presso quel Negozio Fisico, accedere al
            programma fedeltà, utilizzare gli sconti accumulati e beneficiare
            delle promozioni (cf. infra, art. 8).
          </p>
          <p className="privacy_title">7. Centro Commerciale</p>
          <p className="privacy_terms">
            In accordo con gli Esercenti che ne fanno parte, un Centro
            Commerciale, fisico o naturale, può estendere le campagne fidelity
            di erogazione punti con apposite Campagne dedicate. I punti erogati
            saranno sempre gestiti dall’Esercente che ha effettuato la vendita e
            mai direttamente dal Centro Commerciale.
          </p>
          <p className="privacy_title">8. Fidelity</p>
          <p className="privacy_terms">
            8.1 L’Utente può accedere ai programmi fedeltà di ciascuno dei
            propri Negozi Preferiti ed ottenere, per ciascuno di essi, una
            Fidelity Card digitale.
          </p>
          <p className="privacy_terms">
            8.2 Selezionando ciascuna Fidelity Card, l’Utente può, visualizzare
            il totale dei punti disponibili ed il relativo controvalore di
            sconto che potrà essere utilizzato in occasione di acquisti presso
            quel Negozio, a termini di Regolamento, ed entro il periodo di
            validità della Campagna Promozionale.
          </p>
          <p className="privacy_terms">
            8.3 Selezionando ciascuna Fidelity Card, l’Utente può, altresì,
            consultare, selezionare ed accedere ad eventuali Promozioni Fedeltà
            riservategli dal Negozio.
          </p>
          <p className="privacy_title">9. Autonomia delle Parti </p>
          <p className="privacy_terms">
            9.1 L’Utente può fruire dei contenuti offerti direttamente da
            RetAPPs su o per il tramite di My-Stores a titolo gratuito. Ciascun
            Negozio o Centro Commerciale indica all’Utente le proprie condizioni
            di vendita, di partecipazione ai programmi fedeltà e di fruizione
            delle offerte promozionali.
          </p>
          <p className="privacy_terms">
            9.2 RetAPPs, i Negozi e i Centri Commerciali sono soggetti
            reciprocamente autonomi e indipendenti. L’utilizzo di My-Stores da
            parte dell’Utente non determina, nei riguardi di RetAPPs,
            l’insorgere di qualsivoglia diverso rapporto che non sia la
            fruizione dei servizi resi su o per il tramite di My-Stores.
          </p>
          <p className="privacy_title">10. Proprietà intellettuale</p>
          <p className="privacy_terms">
            10.1 I domini web “my-stores.it”, “.net”, “.shop”, “.online” e la
            denominazione commerciale delle App “My-Stores” e “My-Stores POS”,
            appartengono a RetAPPs; in mancanza di previa autorizzazione in
            forma scritta, è vietato ai Terzi l’utilizzo del dominio e della
            denominazione commerciale “My-Stores” per attività e finalità
            diverse rispetto all’utilizzo ed alla fruizione dei contenuti e dei
            servizi resi su o per il tramite la Piattaforma My-Stores e ciò al
            fine di non causare confusione nel Consumatore.
          </p>
          <p className="privacy_terms">
            0.2 RetAPPs detiene tutti i diritti sui contenuti, il design e il
            codice sorgente delle App e dei Siti, ivi inclusi, a titolo
            esemplificativo, fotografie, immagini, testi, loghi, disegni,
            marchi, denominazioni commerciali e dati, di creazione e/o comunque
            riconducibili a RetAPPs..
          </p>
          <p className="privacy_terms">
            10.3 Il contenuto delle App e del Sito è considerato software
            informatico e, per conseguenza, trova applicazione la normativa
            italiana e comunitaria europea vigente in materia di tutela del
            diritto d’autore. In mancanza di previa autorizzazione in forma
            scritta, è vietata la riproduzione totale o parziale delle App e del
            Sito, anche tramite link ipertestuali, così come di ogni parte dei
            relativi contenuti, ivi comprese le presenti TCU.
          </p>
          <p className="privacy_title">11. Pubblicità e link di terze parti</p>
          <p className="privacy_terms">
            11.1 RetAPPs si riserva la facoltà di caricare sulle App e sul Sito
            contenuti pubblicitari o sponsorizzati, così come collegamenti
            ipertestuali a siti di terze parti. L’inclusione di un link non
            implica l’approvazione del sito collegato da parte di RetAPPs, né
            l’adesione ai relativi contenuti.
          </p>
          <p className="privacy_terms">
            11.2 RetAPPs non è responsabile del contenuto dei siti web cui
            l’Utente può accedere tramite gli anzidetti collegamenti
            ipertestuali, né dell’aggiornamento della Privacy Policy e della
            Cookie Policy di detti siti.
          </p>
          <p className="privacy_title">
            12. Concorsi, programmi fedeltà, promozioni
          </p>
          <p className="privacy_terms">
            RetAPPs si riserva la facoltà di realizzare concorsi, programmi
            fedeltà e promozioni a cui l’Utente può partecipare. Nel caso, il
            Regolamento di detti concorsi, programmi fedeltà e promozioni verrà
            pubblicato su My-Stores e l’Utente ne verrà informato mediante
            comunicazioni sulle App, sul Sito ed ai recapiti indicati in sede di
            registrazione.
          </p>
          <p className="privacy_title">13. Responsabilità</p>
          <p className="privacy_terms">
            RetAPPs si impegna a verificare il corretto utilizzo di My-Stores da
            parte dell’Utente, del Negozio e del Centro Commerciale; tuttavia, a
            titolo esemplificativo e non esaustivo, non si assume alcuna
            responsabilità derivante:
          </p>
          <ul>
            <li>
              dall’utilizzo che l’Utente può fare delle informazioni, delle
              immagini e, in via generale, dei contenuti presenti nei Negozi e
              nei Centri Commerciali;
            </li>
            <li>
              alla violazione di norme a tutela della proprietà intellettuale o
              industriale eventualmente commesse dai Negozi o dai Centri
              Commerciali in relazione a prodotti o servizi resi all’Utente su o
              per il tramite di My-Stores;
            </li>
            <li>
              dagli eventuali pregiudizi derivanti all’Utente in conseguenza
              dell’acquisto e/o dell’utilizzo di prodotti e servizi presso i
              Negozi, unicamente responsabili di ogni conseguenza connessa alla
              compravendita dei prodotti e dei servizi;
            </li>
            <li>
              dall’accesso e dall’utilizzo di My-Stores da parte di minori di 16
              anni, essendo compito del genitore o di chi ne fa le veci
              esercitare l’adeguato controllo sull’attività del minore, ovvero
              installare strumenti di controllo dell’accesso e dell’uso
              dell’internet;
            </li>
            <li>
              dalla mancata operatività o da problemi tecnici relativi
              all’indirizzo e-mail fornito dall’Utente in sede di registrazione.
            </li>
          </ul>
          <p className="privacy_title">14. Interruzione dei servizi</p>
          <p className="privacy_terms">
            14.1 RetAPPs potrà sospendere temporaneamente l’accesso a My-Stores
            in caso di problemi tecnici o di esigenze manutentive, così come
            potrà modificarne impostazioni e contenuti. RetAPPs potrà cessare in
            qualunque momento, in tutto o in parte, la prestazione dei servizi
            resi su o per il tramite di My-Stores, salvo il rispetto dei previ
            impegni contrattualmente assunti.
          </p>
          <p className="privacy_terms">
            14.2 RetAPPs non si assume alcuna responsabilità per eventuali
            difficoltà, disfunzioni o impedimenti di accesso al Profilo
            personale ed alle funzionalità di My-Stores da parte dell’Utente
            laddove cagionati dagli strumenti tecnici, dalla rete di connessione
            o qualsivoglia causa non direttamente dipendente dal fatto di
            RetAPPs.
          </p>
          <p className="privacy_title">15. Disposizioni finali </p>
          <p className="privacy_terms">
            15.1 Qualora una o più delle disposizioni di cui ai presenti TCU
            venga dichiarata, in tutto o in parte, invalida o inefficace, verrà
            ritenuta scindibile dalle altre e non inciderà sulla validità degli
            altri TCU, così come ogni nota, avviso legale, contenuto
            contrattuale o anche solo informativo pubblicati nelle varie sezioni
            delle App e del Sito, ivi comprese le informazioni relative alla
            Privacy Policy ed alla Cookie Policy.
          </p>
          <p className="privacy_terms">
            15.2 L’eventuale tolleranza, da parte di RetAPPs, di comportamenti
            posti in essere in violazione dei presenti TCU non costituisce
            rinuncia ai diritti derivanti dalle disposizioni violate né a
            qualsivoglia diritto o facoltà scaturente da Legge o contratto.
          </p>
          <p className="privacy_title">
            16. Legge applicabile e risoluzione delle controversie{" "}
          </p>
          <p className="privacy_terms">
            TCU, così come ogni contenuto delle App e del Sito, sono regolati
            dalla Legge italiana. In caso di controversia, l’Utente è invitato a
            contattare RetAPPs all’indirizzo PEC retapps@pec.it o all’indirizzo
            e-mail support@my-stores.it al fine di risolvere bonariamente la
            questione. Nell’impossibilità di una soluzione amichevole, sin d’ora
            RetAPPs manifesta l’adesione al servizio di conciliazione
            “Risolvionline”, fornito dalla Camera Arbitrale di Milano e meglio
            descritto sul sito www.risolvionline.it, al quale si rinvia.
          </p>
          <p className="privacy_terms">
            L’Utente, ai sensi e per gli effetti di cui agli artt. 1341 e 1342
            cod. civ. nonché agli artt. 33, 34, 35 e 36 D.Lgs. 205/2006 (Codice
            del consumo), dichiara di aver letto e ben compreso e, dunque, di
            approvare espressamente il contenuto delle presenti condizioni e, in
            particolare, dei seguenti articoli: 3. Registrazione a My-Stores e
            fruizione dei servizi; 4. Registrazione e Creazione di un Profilo
            personale; 9. Autonomia delle Parti; 13. Responsabilità; 14.
            Interruzione dei servizi; 16. Legge applicabile e risoluzione delle
            controversie.
          </p>
          <div>
            <p className="privacy-date">Ultima modifica in data 09/10/2020</p>
          </div>
        </div>
        {/* <NavBar mapview showMapView/>
        <Footer/> */}
      </>
    );
  }
}

export default Tcu;
