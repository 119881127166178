import React, { useEffect } from 'react';
import { Map, Marker, TileLayer, ZoomControl } from 'react-leaflet';
import { Icon } from 'leaflet';
import './MallMapView.scss';
import 'leaflet/dist/leaflet.css';
import { mapLocator } from '../../assets/images/index';
import history from '../../utils/history';

function MallMapView() {
  const [mapCentre, setMapCentre] = React.useState([45.822617, 8.749668]);
  const [markerLocation, setMarkerLocation] = React.useState([]);
  const skater = new Icon({
    iconUrl: mapLocator,
    iconSize: [30, 40],
  });
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const lat = params.get('lat');
    const lng = params.get('lng');
    setMapCentre([lat, lng]);
    setMarkerLocation([lat, lng]);
  }, []);
  return (
    <div className="mall_map">
      <Map
        center={mapCentre}
        zoom={14}
        zoomControl={false}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <ZoomControl position="topright" />
        {markerLocation && markerLocation.length && (
          <Marker
            // key={store.address.id}
            position={markerLocation}
            icon={skater}
            //   onClick={e => {
            //     setActiveIcon(e.latlng);
            //     setActivePark(store);
            //   }
            // }
          />
        )}
      </Map>
    </div>
  );
}

export default MallMapView;
