/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Header, Modal, Icon } from 'semantic-ui-react';
import { verifiedUser } from '../../assets/images';
import './EmailConformModal.scss';
const EmailConformModal = props => (
  <Modal
    style={{
      width: '300px',
      height: '250px',
      borderRadius: '8px',
      boxShadow: '0 0 34px 0 rgba(0, 0, 0, 0.5)',
      backgroundColor: '#ffffff',
    }}
    closeIcon={{
      style: { top: '5px', right: '5px', fontSize: '15px' },
      color: 'black',
      name: 'close',
    }}
    open={props.showMailConformModal}
    onClose={() => props.setShowMailConformModal(false)}
  >
    {/* <Modal.Header style={{ textAlign: 'center', border: 'none' }}>
      Registrazione
    </Modal.Header> */}
    <Modal.Content style={{ textAlign: 'center', paddingTop: '80px' }}>
      {/* <img src={emailVerification} alt="" /> */}
      <Modal.Description>
        <Header
          style={{
            fontFamily: 'Open Sans',
            fontSize: '18px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.14px',
            color: '#333333',
          }}
        >
          {/* {props.isLoading
            ? 'Confirming your email..'
            : props.isMailConfirmed
            ? 'Email confirmed'
            : 'Email confirmation failed'} */}
          {props.isLoading ? (
            'Confirming your email..'
          ) : props.isMailConfirmed ? (
            <div className="verified_user_text">
              <img src={verifiedUser} alt="" />
              <div>Utente verificato</div>
            </div>
          ) : (
            'Email confirmation failed'
          )}
        </Header>
        {!props.isLoading ? (
          props.isMailConfirmed ? (
            <Icon
              className="check icon"
              style={{ fontSize: '30px', color: 'green', display: 'none' }}
            />
          ) : (
            <Icon
              className="close icon"
              style={{ fontSize: '30px', color: 'red' }}
            />
          )
        ) : null}
      </Modal.Description>
    </Modal.Content>
  </Modal>
);
EmailConformModal.propTypes = {
  showMailConformModal: PropTypes.func,
  setShowMailConformModal: PropTypes.func,
  isLoading: PropTypes.bool,
  isMailConfirmed: PropTypes.bool,
};

export default EmailConformModal;
