/*
 *
 * LanguageProvider reducer
 *
 */
import produce from 'immer';

import { SIGN_IN, SIGN_IN_FAILED, SIGN_IN_LODING } from './constants';

export const initialState = {
  loginInfo: '',
  loginError: '',
  loginLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const signInReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SIGN_IN:
        draft.loginInfo = action.res;
        break;
      case SIGN_IN_FAILED:
        draft.loginError = action.res;
        break;
      case SIGN_IN_LODING:
        draft.loginLoading = action.res;
        break;
    }
  });

export default signInReducer;
