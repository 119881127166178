/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from "react";

export default function NotFound() {
  return (
    <div
      style={{
        margin: "200px 47%",
        display: "inline-block",
        width: "max-content",
      }}
    >
      <h1>Page not found !</h1>
    </div>
  );
}
