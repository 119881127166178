/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
/* eslint-disable indent */
/* eslint-disable */
/**
 *
 * LandingPageContainer
 *
 */

import React, { memo, useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";
import _ from "lodash";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
// import { FormattedMessage } from 'react-intl';
// import { createStructuredSelector } from 'reselect';
import { useSnackbar } from "react-simple-snackbar";
import { useMediaQuery } from "react-responsive";
import { compose } from "redux";
// import { Link } from 'react-router-dom';
import {
  Button,
  Input,
  Accordion,
  Icon,
  Dropdown,
  Loader,
} from "semantic-ui-react";
import injectSaga from "../../utils/injectSaga";
import { DAEMON } from "../../utils/constants";
import axios from "axios";
import Footer from "../../components/footer/Footer";
import saga from "../Homepage/saga";

// import { useInjectSaga } from 'utils/injectSaga';
// import { useInjectReducer } from 'utils/injectReducer';
// import makeSelectLandingPageContainer from './selectors';
// import Accordion from '../../components/Accordian/Accordian';
// import Carousel from './LandingPageCarousel/LandingPageCarousal';
// import reducer from './reducer';
// import saga from './saga';
// import messages from './messages';
import "./LandingPage.scss";
import {
  logoIcon,
  logoIcon2,
  appleIcon,
  androidIcon,
  backgroudSecondSection,
  secondSectionFirstImage,
  secondSectionSecondImage,
  secondSectionThirdImage,
  phonesGroupImage,
  secondSectionDashedbackground,
  thirdSectionBackImage,
  thirdSectionDashedBack,
  thirdSectionFirstImage,
  thirdSectionSecondImage,
  thirdSectionThirdImage,
  thirdSectionFourthImage,
  thirdSectionFifthImage,
  cardBackground,
  cardDashedBackground,
  cardImage,
  // aboutImage,
  aboutBackgImage,
  aboutArrowLeft,
  aboutArrowRight,
  businessCardLogo,
  businessbackgOne,
  businessbackgTwo,
  //   mobileHeader,
  mobileShoppingCentre,
  mobileOfferGroup,
  mobileCard,
  mobileViewGroup,
  diconoDiNoi,
} from "../../assets/images";
import { createNewsLetter, getFAQs, baseUrl } from "../../Helpers/api";
import LoginForm from "../../components/Login/Login";
import SignupForm from "../../components/SignupForm";
import LandingForm from "../../components/LandingPage/LandingForm";
import EmailVerificationModal from "../../components/LandingPage/EmailVerificationModal";
import RegistrationModal from "./RegistrationModal/RegistrationModal";
import history from "../../utils/history";
import ResetPasswordModal from "./ResetPasswordModal/ResetPasswordModal";
import EmailConformModal from "../../components/EmailConfirmModal/EmailConformModal";

export function LandingPage(props) {
  const { t } = useTranslation();
  // useInjectReducer({ key: 'landingPageContainer', reducer });
  // useInjectSaga({ key: 'landingPageContainer', saga });
  const [showLoginModal, setShowLoginModal] = useState(false);
  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });
  const [faqs, setFaqs] = useState([
    {
      id: "345a4461-eec6-4f52-a5ab-ee5743f84a99",
      created: "2020-09-05T06:05:58.000+0000",
      updated: "2020-09-05T06:05:58.000+0000",
      question: "what is your name",
      answer: "Kunal ",
      status: "active",
    },
    {
      id: "345a4461-eec6-4f52-a5ab-ee5743f84a99",
      created: "2020-09-05T06:05:58.000+0000",
      updated: "2020-09-05T06:05:58.000+0000",
      question: "what is your name",
      answer: "Kunal ",
      status: "active",
    },
    {
      id: "345a4461-eec6-4f52-a5ab-ee5743f84a99",
      created: "2020-09-05T06:05:58.000+0000",
      updated: "2020-09-05T06:05:58.000+0000",
      question: "what is your name",
      answer: "Kunal ",
      status: "active",
    },
  ]);
  const [newLetterEmail, setNewLetterEmail] = useState("");
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showSignUpSuccesModal, setShowSignUpSuccesModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showResetPassordModal, setShowResetPassordModal] = useState(false);
  const [showMailConformModal, setShowMailConformModal] = useState(false);
  // const [isNewsChecked, setIsNewsChecked] = useState(false);
  const [isMailConfirmed, setIsMailConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCity, setSlectedCity] = useState("");
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [newsLetterError, setNewsLetterError] = useState({});
  const [isNewsLoading, setIsNewsLoading] = useState(false);
  const [openSnackbar] = useSnackbar({
    // position: 'top-center',
    style: {
      position: "absolute",
      backgroundColor: "green",
      border: "2px solid lightgreen",
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
      bottom: "100%",
      left: 0,
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });
  const [openSnackbarRed] = useSnackbar({
    style: {
      position: "absolute",
      backgroundColor: "red",
      border: "2px solid lightgreen",
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
      bottom: "100%",
      left: 0,
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    props.getCities();
  }, []);

  useEffect(() => {
    if (props.allCities) {
      const cityArray = [];
      props.allCities.data.data.sort((a, b) => a.id - b.id);
      props.allCities.data.data.forEach((item) => {
        cityArray.push({
          key: item.id,
          value: item.id,
          text: `${item.city} [${item.id}]`,
        });
      });
      // const newArray = cityArray.slice(0, 5);
      setCitiesOptions(cityArray);
    }
  }, [props.allCities]);

  useEffect(() => {
    if (!_.isEmpty(props.match.params)) {
      setShowMailConformModal(true);
    } else {
      setShowMailConformModal(false);
    }
    const ConfirmApiUrl = `${baseUrl}/cli/profiles/${props.match.params.profileId}/otp/${props.match.params.otpId}`;
    axios.post(ConfirmApiUrl).then(
      (result) => {
        setIsLoading(false);
        setIsMailConfirmed(true);
        console.log(result);
      },
      (err) => {
        setIsMailConfirmed(false);
        setIsLoading(false);
        console.log(err);
      }
    );

    // Get Cities

    // axios.get(getCities).then(
    //   res => {
    //     console.log(res);
    //     const cityArray = [];
    //     res.data.data.sort((a, b) => a.id - b.id);
    //     res.data.data.forEach(item => {
    //       cityArray.push({
    //         key: item.id,
    //         value: item.id,
    //         text: `${item.city} [${item.id}]`,
    //       });
    //     });
    //     // const newArray = cityArray.slice(0, 5);
    //     setCitiesOptions(cityArray);
    //   },
    //   err => {
    //     console.log(err);
    //   },
    // );

    //  Get FAQs
    axios.get(getFAQs).then(
      (res) => {
        if (res && res.data && res.data.data) setFaqs(res.data.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Update the document title using the browser API
    document.addEventListener("scroll", () => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 1 && !isScrolled) {
        setIsScrolled(true);
      } else if (isScrolled && scrolled < 1) {
        setIsScrolled(false);
      }
    });
    return () => {
      document.removeEventListener("scroll", () => {});
    };
  });

  const handleAccordianTitleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    // const newIndex =
    //   activeIndex.indexOf(index) === -1
    //     ? [...activeIndex, index]
    //     : activeIndex.filter(item => item !== index);

    setActiveIndex(newIndex);
  };
  const iconName = activeIndex === 0 ? "angle up" : "angle down";
  // const iconName1 = activeIndex === 1 ? 'angle up' : 'angle down';
  // const iconName2 = activeIndex === 2 ? 'angle up' : 'angle down';
  // const iconName3 = activeIndex === 3 ? 'angle up' : 'angle down';
  // const iconName4 = activeIndex === 4 ? 'angle up' : 'angle down';

  // const iconName1 = activeIndex.includes(1) ? 'angle down' : 'angle up';
  // const iconName2 = activeIndex.includes(2) ? 'angle down' : 'angle up';
  // const iconName3 = activeIndex.includes(3) ? 'angle down' : 'angle up';
  // const iconName4 = activeIndex.includes(4) ? 'angle down' : 'angle up';
  // const iconName5 = activeIndex.includes(5) ? 'angle down' : 'angle up';

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };
  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };
  const closeSignUpModal = () => {
    setShowSignUpModal(false);
  };
  const onSuccessfulSignUp = () => {
    setShowSignUpModal(false);
    setShowSignUpSuccesModal(true);
  };
  const closeSuccessfulSignUp = () => {
    setShowSignUpModal(false);
    setShowSignUpSuccesModal(false);
  };

  // Data for carousel

  // const carouselSlidesData = [
  //   {
  //     content: (
  //       <div className="about_card">
  //         <div className="about_image_container">
  //           <img
  //             src={aboutBackgImage}
  //             alt="background"
  //             className="about_dashed_backgroung"
  //           />
  //           <img src={aboutImage} alt="background" className="about_photo" />
  //         </div>
  //         <div className="about_text">
  //           <div className="about_text_content">
  //             “Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
  //             commodo ligula eget dolor. Aenean massa. Cum sociis natoque
  //             penatibus et magnis dis parturient montes, nascetur ridiculus
  //             mus.”
  //           </div>
  //           <div className="about_person_intro">
  //             Nome del commento - Attività s.r.l
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //     // author: 'Alfred Pennyworth',
  //     // source: 'twitter',
  //   },
  //   {
  //     content: (
  //       <div className="about_card">
  //         <div className="about_image_container">
  //           <img
  //             src={aboutBackgImage}
  //             alt="background"
  //             className="about_dashed_backgroung"
  //           />
  //           <img src={aboutImage} alt="background" className="about_photo" />
  //         </div>
  //         <div className="about_text">
  //           <div className="about_text_content">
  //             “Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
  //             commodo ligula eget dolor. Aenean massa.”
  //           </div>
  //           <div className="about_person_intro">
  //             Oome del commento - Attività s.r.l
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //     // author: 'Alfred Pennyworth',
  //     // source: 'twitter',
  //   },
  //   {
  //     content: (
  //       <div className="about_card">
  //         <div className="about_image_container">
  //           <img
  //             src={aboutBackgImage}
  //             alt="background"
  //             className="about_dashed_backgroung"
  //           />
  //           <img src={aboutImage} alt="background" className="about_photo" />
  //         </div>
  //         <div className="about_text">
  //           <div className="about_text_content">
  //             “Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
  //             commodo ligula eget dolor. Aenean massa. Cum sociis natoq”
  //           </div>
  //           <div className="about_person_intro">
  //             Pome del commento - Attività s.r.l
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //     // author: 'Alfred Pennyworth',
  //     // source: 'twitter',
  //   },
  // ];
  const validateFormData = () => {
    const error = {};
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (newLetterEmail) {
      if (!regEmail.test(newLetterEmail)) {
        error.email = t("userSignupValidation.emailInvalid");
      }
    } else {
      error.email = t("userSignupValidation.emptyEmail");
    }
    setNewsLetterError(error);
    return error;
  };
  const submitNewsEmail = () => {
    const errors = validateFormData();
    if (Object.keys(errors).length === 0) {
      setIsNewsLoading(true);
      axios.post(createNewsLetter, { email: newLetterEmail }).then(
        (res) => {
          console.log(res);
          setIsNewsLoading(false);
          console.log(res.data, res.data.responseCode);
          if (res.data.responseCode === 0) {
            openSnackbar(t("thankYou"));
            setNewLetterEmail("");
          }
          if (res.data.responseCode === 1) {
            openSnackbarRed(t("userSignupValidation.emailExist"));
          }
        },
        (err) => {
          openSnackbarRed(t("somethingWentWrong"));
          setIsNewsLoading(false);
          console.log(err);
        }
      );
    }

    // if (newLetterEmail) {
    //   axios.post(createNewsLetter, newLetterEmail).then(
    //     res => {
    //       console.log(res);
    //     },
    //     err => {
    //       console.log(err);
    //     },
    //   );
    // }
  };
  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <header className="landing_header">
        <div
          className="landing_navbar"
          style={
            isScrolled
              ? {
                  backgroundColor: "#ffffff",
                  height: "70px",
                  boxShadow: "0 2px 13px 0 rgba(33, 73, 122, 0.29)",
                }
              : null
          }
        >
          {isMobileView ? (
            <div
              className="logo_name_mobile"
              style={isScrolled ? { backgroundImage: "none" } : null}
            >
              {isScrolled ? (
                <img
                  src={logoIcon2}
                  alt="Logo Icon"
                  className="logo_image_image"
                />
              ) : (
                <img
                  src={logoIcon}
                  alt="Logo Icon"
                  className="logo_image_image"
                />
              )}
            </div>
          ) : (
            <div
              className="logo_name"
              style={isScrolled ? { backgroundImage: "none" } : null}
            >
              {isScrolled ? (
                <img src={logoIcon2} alt="Logo Icon" className="logo_image" />
              ) : (
                <img src={logoIcon} alt="Logo Icon" className="logo_image" />
              )}
            </div>
          )}
          <div className="link_container">
            <button
              type="button"
              className="nav-link"
              onClick={() => {
                const anchor = document.querySelector("#store_section_id");
                anchor.scrollIntoView({ behavior: "smooth", block: "center" });
              }}
              style={isScrolled ? { color: "#333333" } : null}
            >
              NEGOZI
            </button>
            <button
              type="button"
              className="nav-link"
              onClick={() => {
                const anchor = document.querySelector("#offer_section_id");
                anchor.scrollIntoView({ behavior: "smooth", block: "center" });
              }}
              style={isScrolled ? { color: "#333333" } : null}
            >
              OFFERTE
            </button>
            <button
              type="button"
              className="nav-link"
              onClick={() => {
                const anchor = document.querySelector("#card_section_id");
                anchor.scrollIntoView({ behavior: "smooth", block: "center" });
              }}
              style={isScrolled ? { color: "#333333" } : null}
            >
              FIDELITY
            </button>
            <button
              type="button"
              className="nav-link"
              onClick={() => {
                const anchor = document.querySelector("#buisness_section_id");
                anchor.scrollIntoView({ behavior: "smooth", block: "center" });
              }}
              style={isScrolled ? { color: "#333333" } : null}
            >
              IL TUO BUSINESS
            </button>
            {/* <Link
              to="/react"
              className="nav-link"
              style={isScrolled ? { color: '#333333' } : null}
            >
              OFFERTE
            </Link>
            <Link
              to="/react"
              className="nav-link"
              style={isScrolled ? { color: '#333333' } : null}
            >
              FIDELITY
            </Link>
            <Link
              to="/react"
              className="nav-link"
              style={isScrolled ? { color: '#333333' } : null}
            >
              IL TUO BUSINESS
            </Link> */}
          </div>
          <div
            className="button_container"
            style={isScrolled ? { margin: "15px 20px 0 0" } : null}
          >
            <Button
              className="landing_register_button"
              type="submit"
              // onClick={() => setShowSignUpModal(true)}
              onClick={() => setShowRegistrationModal(true)}
            >
              REGISTRATI
            </Button>
            <Button
              className="landing_login_button"
              type="submit"
              onClick={() => setShowLoginModal(true)}
            >
              ACCEDI
            </Button>
          </div>
        </div>

        <div>
          <div className="landing_intro_container">
            <div className="landing_intro_text_heading">
              Il Primo Centro Commerciale Digitale d’Italia
            </div>
            <div className="landing_intro_text_content">
              My-Stores è la piattaforma in Cloud, di Attraction & Engagement,
              per Esercenti, Circuiti, Centri Commerciali Fisici e Naturali e
              Comunità Multi-Esercente. La tecnologia digitale, con sito ed
              eCommerce integrati, può rivelarsi un grande supporto allo
              sviluppo del retail tradizionale di prossimità dando vita ad un
              sistema «retail-centrico» che valorizza il punto vendita fisico,
              con logiche di fidelizzazione, rivolgendosi ai consumatori che
              gravitano nel bacino d’utenza, ma aprendosi anche a tutta la
              platea On-Line.
            </div>
            {isMobileView && (
              <>
                <div className="landing_intro_text_download">Scarica l'APP</div>
                <a
                  href="https://apps.apple.com/it/app/my-stores/id1490231492"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button className="playstore_button">
                    <img
                      src={appleIcon}
                      alt="apple-icon"
                      style={{ margin: "0 5px 3px 0" }}
                    />{" "}
                    APP STORE
                  </Button>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.retapps.mystores"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button className="playstore_button">
                    <img
                      src={androidIcon}
                      alt="apple-icon"
                      style={{ margin: "0 5px 3px 0" }}
                    />{" "}
                    PLAY STORE
                  </Button>
                </a>
              </>
            )}
          </div>
          {isMobileView ? (
            <img
              src={mobileViewGroup}
              alt="phone group"
              className="phones_group_image_mobile"
            />
          ) : (
            <img
              src={phonesGroupImage}
              alt="phone group"
              className="phones_group_image"
            />
          )}
        </div>
      </header>
      <div className="store_section_main_container">
        <div className="store_section" id="store_section_id">
          <div className="for_mobile_view_group">
            <img
              src={mobileShoppingCentre}
              alt="background"
              //   className="store_section_back_img"
            />
          </div>
          <div className="store_section_img_container">
            <img
              src={backgroudSecondSection}
              alt="background"
              className="store_section_back_img"
            />
            <img
              src={secondSectionFirstImage}
              alt="store"
              className="store_section_img_1"
            />
            <img
              src={secondSectionThirdImage}
              alt="store"
              className="store_section_img_2"
            />
            <img
              src={secondSectionSecondImage}
              alt="store"
              className="store_section_img_3"
            />
            <img
              src={secondSectionDashedbackground}
              alt="store"
              className="store_section_dashed_background"
            />
          </div>
          <div className="store_section_text_container">
            <div
              className="store_section_text_heading"
              style={{ color: "black" }}
            >
              Scopri le attività e i centri commerciali più vicini a te!
            </div>
            <div
              className="store_section_text_content"
              style={{ color: "black", width: "640px" }}
            >
              Hai bisogno di qualcosa in particolare e non sai dove poterla
              acquistare?
              <br /> Con My-Stores scopri i negozi vicino a te e le loro offerte
            </div>
            <div className="landing_city_container">
              <Dropdown
                placeholder="Inserisci il nome della città"
                fluid
                search
                selection
                clearable
                className="landing_city_dropdown"
                // icon={null}
                value={selectedCity}
                options={citiesOptions}
                onChange={(e, { value }) => {
                  setSlectedCity(value);
                }}
              />
              <Button
                // style={{
                //   height: "50px",
                //   marginRight: "0px",
                //   position: "absolute",
                //   right: "-9px",
                // }}
                className="landing_login_button goto_city"
                type="button"
                disabled={selectedCity === ""}
                onClick={() => {
                  history.push("/home");
                  localStorage.setItem("zipCode", selectedCity);
                }}
              >
                CERCA
              </Button>
            </div>

            {/* <Button
              className="landing_login_button"
              // type="submit"
              // onClick={() => setShowSignUpModal(true)}
            >
              REGISTRATI
            </Button> */}
          </div>
        </div>
        <div className="mobile_store_section_text_container">
          <div
            className="mobile_store_section_text_heading"
            style={{ color: "black" }}
          >
            Scopri le attività e i centri commerciali più vicini a te!
          </div>
          <div
            className="mobile_store_section_text_content"
            style={{ color: "black" }}
          >
            Hai bisogno di qualcosa in particolare e non sai dove poterla
            acquistare? Con My-Stores scopri i negozi vicino a te e le loro
            offerte
          </div>
        </div>
        <div className="offer_section" id="offer_section_id">
          <div className="offer_section_text">
            <div
              className="store_section_text_heading"
              style={{ color: "black" }}
            >
              Trova le offerte migliori
            </div>
            <div
              className="store_section_text_content"
              style={{ color: "black" }}
            >
              Con My-Stores resterai sempre aggiornato sulle offerte dei tuoi
              negozi preferiti. Non lasciarti scappare questa occasione.
            </div>
            <Button
              className="landing_login_button"
              // type="submit"
              onClick={() => setShowLoginModal(true)}
            >
              REGISTRATI
            </Button>
          </div>
          <div className="offer_section_img_container">
            <img
              src={thirdSectionBackImage}
              alt="background"
              className="offer_section_backg"
            />
            <img
              src={thirdSectionFirstImage}
              alt="nature"
              className="offer_section_nature"
            />
            <img
              src={thirdSectionSecondImage}
              alt="mega sale"
              className="offer_section_mega_sale"
            />
            <img
              src={thirdSectionThirdImage}
              alt="sale"
              className="offer_section_sale"
            />
            <img
              src={thirdSectionFourthImage}
              alt="jacket"
              className="offer_section_jacket"
            />
            <img
              src={thirdSectionFifthImage}
              alt="shoes"
              className="offer_section_shoes"
            />
            <img
              src={thirdSectionDashedBack}
              alt="Dashed background"
              className="offer_section_dashed_backg"
            />
          </div>
        </div>
        <div className="mobile_offer_section">
          <div className="mobile_offer_section_image">
            <img src={mobileOfferGroup} alt="" />
          </div>
          <div>
            <div className="mobile_store_section_text_heading">
              Trova le offerte migliori
            </div>
            <div className="mobile_store_section_text_content">
              Con My-Stores resterai sempre aggiornato sulle offerte dei tuoi
              negozi preferiti. Non lasciarti scappare questa occasione.
            </div>
          </div>
        </div>
        <div className="card_section">
          <div className="card_section_images">
            <img
              src={cardBackground}
              alt="card background"
              className="card_background"
            />
            <img
              src={cardDashedBackground}
              alt="card dashed background"
              className="cad_dashed_backg"
            />
            <img
              src={cardImage}
              alt="loyality card"
              className="loyality_card"
            />
            <img
              src={cardDashedBackground}
              alt="card background"
              className="card_dashed_background_second"
            />
          </div>
          <div className="card_section_text" id="card_section_id">
            <div
              className="store_section_text_heading"
              style={{ color: "black" }}
            >
              Un'unica carta fedeltà per tutti i negozi del circuito
            </div>
            <div
              className="store_section_text_content"
              style={{ color: "black" }}
            >
              Con la carta fedeltà My-Stores accumuli punti in tutti i negozi
              aderenti. Più punti accumuli più sconti per te
            </div>
            <Button
              className="landing_login_button"
              // type="submit"
              onClick={() => setShowLoginModal(true)}
            >
              REGISTRATI
            </Button>
          </div>
        </div>
        <div className="mobile_card_section">
          <div className="mobile_card_section_image">
            <img src={mobileCard} alt="" />
          </div>
          <div>
            <div className="mobile_store_section_text_heading">
              Un'unica carta fedeltà per tutti i negozi del circuito
            </div>
            <div className="mobile_store_section_text_content">
              Con la carta fedeltà My-Stores accumuli punti in tutti i negozi
              aderenti. Più punti accumuli più sconti per te
            </div>
          </div>
        </div>
        <div className="about_section">
          <div className="about_us_heading">Dicono di noi</div>
          {/* <div className="carousel-container">
            <Carousel slides={carouselSlidesData} />
          </div> */}
          {/* <div className="arrow_container">
            <img
              src={aboutArrowLeft}
              alt="Arrow left"
              className="about_arrow_left"
            />

            <div className="about_card">
              <div className="about_image_container">
                <img
                  src={aboutBackgImage}
                  alt="background"
                  className="about_dashed_backgroung"
                />
                <img
                  src={aboutImage}
                  alt="background"
                  className="about_photo"
                />
              </div>
              <div className="about_text">
                <div className="about_text_content">
                  “Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                  natoque penatibus et magnis dis parturient montes, nascetur
                  ridiculus mus.”
                </div>
                <div className="about_person_intro">
                  Nome del commento - Attività s.r.l
                </div>
              </div>
            </div>
            <img
              src={aboutArrowRight}
              alt="Arrow left"
              className="about_arrow_right"
            />
          </div> */}

          <div className="arrow_container">
            <button
              type="button"
              onClick={prev}
              className="about_arrow_left button_style"
            >
              <img src={aboutArrowLeft} alt="Arrow left" />
            </button>

            <div className="carousel_container">
              <Carousel
                autoPlay
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                infiniteLoop
                interval={5000}
                transitionTime={2000}
                selectedItem={currentSlide}
                onChange={updateCurrentSlide}
              >
                <div className="about_card">
                  <div className="about_image_container">
                    <img
                      src={diconoDiNoi}
                      alt="background"
                      className="about_dashed_backgroung"
                    />
                    {/* <img
                      src={aboutImage}
                      alt="background"
                      className="about_photo"
                    /> */}
                  </div>
                  <div className="about_text">
                    <div className="about_text_content">
                      La tecnologia digitale, con sito dedicato ed eCommerce
                      integrati, può rivelarsi un grande supporto allo sviluppo
                      del retail tradizionale di prossimità dando vita ad un
                      sistema «retail-centrico» che valorizza il Punto Vendita
                      fisico, rivolgendosi ai consumatori che gravitano
                      nel bacino d’utenza
                    </div>
                  </div>
                </div>
                {/* <div className="about_card">
                  <div className="about_image_container">
                    <img
                      src={aboutBackgImage}
                      alt="background"
                      className="about_dashed_backgroung"
                    />
                    <img
                      src={aboutImage}
                      alt="background"
                      className="about_photo"
                    />
                  </div>
                  <div className="about_text">
                    <div className="about_text_content">
                      “Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                      nascetur ridiculus mus.”
                    </div>
                    <div className="about_person_intro">
                      Nome del commento - Attività s.r.l
                    </div>
                  </div>
                </div>
                <div className="about_card">
                  <div className="about_image_container">
                    <img
                      src={aboutBackgImage}
                      alt="background"
                      className="about_dashed_backgroung"
                    />
                    <img
                      src={aboutImage}
                      alt="background"
                      className="about_photo"
                    />
                  </div>
                  <div className="about_text">
                    <div className="about_text_content">
                      “Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                      nascetur ridiculus mus.”
                    </div>
                    <div className="about_person_intro">
                      Nome del commento - Attività s.r.l
                    </div>
                  </div>
                </div> */}
              </Carousel>
            </div>

            <button
              type="button"
              onClick={next}
              className="about_arrow_right button_style"
            >
              <img src={aboutArrowRight} alt="Arrow left" />
            </button>
          </div>
        </div>
        <div className="business_section" id="buisness_section_id">
          <div className="business_section_header">
            Hai un’attività commerciale?
          </div>
          <div className="business_section_text">
            Entra a far parte e scopri i vantaggi di far parte del centro
            commerciale più grande d’italia!
          </div>

          <div className="buisness_cards_container">
            <div style={{ position: "relative" }}>
              <div className="buisness_card buisness_card1 position_card">
                <img
                  src={businessCardLogo}
                  alt="card logo"
                  className="business_card_logo"
                />
                <div className="buisness_card_heading">
                  My-Stores ti mette a disposizione
                </div>
                <div className="buisness_card_text">
                  <ul className="padding_5">
                    <li>
                      Una piattaforma completa che consente di sviluppare una
                      propria strategia digitale omnicanale
                    </li>
                    <li>
                      Un sito Web aggiornato e responsivo (per PC e Smartphone)
                    </li>
                    <li>
                      Delle APP native (iOS e Android) dedicate ai Clienti e
                      agli Esercenti
                    </li>
                    <li>Una console Web di Gestione / Amministrazione</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div style={{ position: 'relative' }}>
              <div
                className="buisness_card buisness_card2"
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  zIndex: '15',
                }}
              >
                <img
                  src={businessCardLogo}
                  alt="card logo"
                  className="business_card_logo"
                />
                <div className="buisness_card_heading">
                  My-Stores ti mette a disposizione
                </div>
                <div className="buisness_card_text">
                  <ul>
                    
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
          <img
            src={businessbackgTwo}
            alt="background"
            className="business_backgound_two"
          />
          <img
            src={aboutBackgImage}
            alt="background"
            className="buisness_dashed_background_two"
          />
          <img
            src={aboutBackgImage}
            alt="background"
            className="buisness_dashed_background_first"
          />
          <img
            src={aboutBackgImage}
            alt="background"
            className="about_dashed_background_third"
          />
          <img
            src={businessbackgOne}
            alt="background"
            className="business_backgound_one"
          />
        </div>
        <div className="business_section_mobile">
          <div>
            <div className="mobile_store_section_text_heading">
              Hai un’attività commerciale?
            </div>
            <div className="mobile_store_section_text_content">
              Entra a far parte e scopri i vantaggi di far parte del centro
              commerciale più grande d’italia!
            </div>
          </div>
          <div className="buisness_cards_container">
            <div style={{ position: "relative" }}>
              <div className="buisness_card buisness_card1 position_card">
                <img
                  src={businessCardLogo}
                  alt="card logo"
                  className="business_card_logo"
                />
                <div className="buisness_card_heading">
                  My-Stores ti mette a disposizione
                </div>
                <div className="buisness_card_text">
                  <ul className="padding_5">
                    <li>
                      Una piattaforma completa che consente di sviluppare una
                      propria strategia digitale omnicanale
                    </li>
                    <li>
                      Un sito Web aggiornato e responsivo (per PC e Smartphone)
                    </li>
                    <li>
                      Delle APP native (iOS e Android) dedicate ai Clienti e
                      agli Esercenti
                    </li>
                    <li>Una console Web di Gestione / Amministrazione</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div style={{ position: 'relative' }}>
              <div
                className="buisness_card buisness_card2"
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  zIndex: '15',
                }}
              >
                <img
                  src={businessCardLogo}
                  alt="card logo"
                  className="business_card_logo"
                />
                <div className="buisness_card_heading">
                  TITOLO TITOLO TITOLO
                </div>
                <div className="buisness_card_text">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                  natoque penatibus et magnis dis parturient montes, nascetur
                  ridiculus mus.
                </div>
              </div>
              </div>*/}
          </div>
        </div>
        <div className="form_accordian_container_main">
          <div className="form_accordian_container_header">
            Vuoi maggiori informazioni?
          </div>
          {/* <img src={landingFormBackground} alt="" /> */}
          <div className="form_accordian_container">
            <LandingForm />
            <div className="landing_accordian_container">
              {faqs && faqs.length > 0 && (
                <div className="accordian_heading">Domande frequenti</div>
              )}
              <Accordion
                className="landing_accordian"
                // exclusive={false}
                activeIndex={activeIndex}
              >
                {faqs &&
                  faqs.length > 0 &&
                  faqs.map((item, index) => (
                    <div className="landing_accordian_box_style">
                      <Accordion.Title
                        // active={activeIndex.includes(0)}
                        active={activeIndex === index}
                        index={index}
                        onClick={handleAccordianTitleClick}
                        style={
                          activeIndex === index
                            ? {
                                boxShadow: "none",
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                                color: "#ffffff",
                                backgroundImage:
                                  "linear-gradient(85deg, #3b58e0 0%, #6bb5fc 100%)",
                              }
                            : null
                        }
                      >
                        <Icon
                          name={iconName}
                          className="landing_register_accordian_icon"
                        />
                        <div
                          className="landing_accordian_heading"
                          style={
                            activeIndex === index ? { color: "#ffffff" } : null
                          }
                        >
                          {item.question}
                        </div>
                      </Accordion.Title>
                      <Accordion.Content
                        active={activeIndex === index}
                        style={{ padding: "25px" }}
                      >
                        <p className="frequently_answers">{item.answer}</p>
                      </Accordion.Content>
                    </div>
                  ))}

                {/* <div className="landing_accordian_box_style">
                  <Accordion.Title
                    // active={activeIndex.includes(0)}
                    active={activeIndex === 0}
                    index={0}
                    onClick={handleAccordianTitleClick}
                    style={
                      activeIndex === 0
                        ? {
                            boxShadow: 'none',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            color: '#ffffff',
                            backgroundImage:
                              'linear-gradient(85deg, #3b58e0 0%, #6bb5fc 100%)',
                          }
                        : null
                    }
                  >
                    <Icon
                      name={iconName}
                      className="landing_register_accordian_icon"
                    />
                    <span
                      className="landing_accordian_heading"
                      style={activeIndex === 0 ? { color: '#ffffff' } : null}
                    >
                      COME FUNZIONA{' '}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 0}
                    style={{ padding: '25px' }}
                  >
                    <p className="frequently_answers">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                      nascetur ridiculus mus. Donec quam felis, ultricies nec,
                      pellentesque eu, pretium quis, sem.
                    </p>
                  </Accordion.Content>
                </div> */}

                {/* <div className="landing_accordian_box_style">
                  <Accordion.Title
                    // active={activeIndex.includes(0)}
                    active={activeIndex === 1}
                    index={1}
                    onClick={handleAccordianTitleClick}
                    style={
                      activeIndex === 1
                        ? {
                            boxShadow: 'none',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            color: '#ffffff',
                            backgroundImage:
                              'linear-gradient(85deg, #3b58e0 0%, #6bb5fc 100%)',
                          }
                        : null
                    }
                  >
                    <Icon
                      name={iconName1}
                      className="landing_register_accordian_icon"
                    />
                    <span
                      className="landing_accordian_heading"
                      style={activeIndex === 1 ? { color: '#ffffff' } : null}
                    >
                      COME FUNZIONA{' '}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 1}
                    style={{ padding: '25px' }}
                  >
                    <p className="frequently_answers">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                      nascetur ridiculus mus. Donec quam felis, ultricies nec,
                      pellentesque eu, pretium quis, sem.
                    </p>
                  </Accordion.Content>
                </div>
                <div className="landing_accordian_box_style">
                  <Accordion.Title
                    // active={activeIndex.includes(0)}
                    active={activeIndex === 2}
                    index={2}
                    onClick={handleAccordianTitleClick}
                    style={
                      activeIndex === 2
                        ? {
                            boxShadow: 'none',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            color: '#ffffff',
                            backgroundImage:
                              'linear-gradient(85deg, #3b58e0 0%, #6bb5fc 100%)',
                          }
                        : null
                    }
                  >
                    <Icon
                      name={iconName2}
                      className="landing_register_accordian_icon"
                    />
                    <span
                      className="landing_accordian_heading"
                      style={activeIndex === 2 ? { color: '#ffffff' } : null}
                    >
                      COME FUNZIONA{' '}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 2}
                    style={{ padding: '25px' }}
                  >
                    <p className="frequently_answers">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                      nascetur ridiculus mus. Donec quam felis, ultricies nec,
                      pellentesque eu, pretium quis, sem.
                    </p>
                  </Accordion.Content>
                </div>
                <div className="landing_accordian_box_style">
                  <Accordion.Title
                    // active={activeIndex.includes(0)}
                    active={activeIndex === 3}
                    index={3}
                    onClick={handleAccordianTitleClick}
                    style={
                      activeIndex === 3
                        ? {
                            boxShadow: 'none',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            color: '#ffffff',
                            backgroundImage:
                              'linear-gradient(85deg, #3b58e0 0%, #6bb5fc 100%)',
                          }
                        : null
                    }
                  >
                    <Icon
                      name={iconName3}
                      className="landing_register_accordian_icon"
                    />
                    <span
                      className="landing_accordian_heading"
                      style={activeIndex === 3 ? { color: '#ffffff' } : null}
                    >
                      COME FUNZIONA{' '}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 3}
                    style={{ padding: '25px' }}
                  >
                    <p className="frequently_answers">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                      nascetur ridiculus mus. Donec quam felis, ultricies nec,
                      pellentesque eu, pretium quis, sem.
                    </p>
                  </Accordion.Content>
                </div>
                <div className="landing_accordian_box_style">
                  <Accordion.Title
                    // active={activeIndex.includes(0)}
                    active={activeIndex === 4}
                    index={4}
                    onClick={handleAccordianTitleClick}
                    style={
                      activeIndex === 4
                        ? {
                            boxShadow: 'none',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            color: '#ffffff',
                            backgroundImage:
                              'linear-gradient(85deg, #3b58e0 0%, #6bb5fc 100%)',
                          }
                        : null
                    }
                  >
                    <Icon
                      name={iconName4}
                      className="landing_register_accordian_icon"
                    />
                    <span
                      className="landing_accordian_heading"
                      style={activeIndex === 4 ? { color: '#ffffff' } : null}
                    >
                      Totale{' '}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 4}
                    style={{ padding: '25px' }}
                  >
                    <p className="frequently_answers">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                      nascetur ridiculus mus. Donec quam felis, ultricies nec,
                      pellentesque eu, pretium quis, sem.
                    </p>
                  </Accordion.Content>
                </div> */}
              </Accordion>
              {/* <Accordion
          title="LOREM IPSUM"
          content="
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
      </br>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
      </br>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
      "
        /> */}
            </div>
          </div>
        </div>

        <div className="news_letter_container">
          <div className="news_letter">
            <div className="news_letter_heading">
              Resta aggiornato! Iscriviti alla newsletter
            </div>
            <div className="news_leter_input">
              <Input
                placeholder="Inserisci qui la email"
                value={newLetterEmail}
                style={{ width: "80%" }}
                onChange={(e) => {
                  setNewLetterEmail(e.target.value);
                }}
              />
              {newsLetterError && newsLetterError.email && (
                <span className="ladingFormError ladingFormNewsError">
                  {newsLetterError.email}
                </span>
              )}
            </div>
            <div
              className="checkbox_points news_checkbox_content_landing"
              style={{ margin: "20px auto", maxWidth: "575px" }}
            >
              <input
                type="checkbox"
                id="type1"
                name="type1"
                value="type1"
                className="checkbox_tick"
                // checked={isNewsChecked}
                // defaultChecked
                // onChange={e => setIsNewsChecked(e.target.checked)}
              />
              <label htmlFor="type1" className="news_checkbox_content">
                {" "}
                Dichiaro di aver preso visione dell'informativa redatta ai sensi
                dell'art. 13 del Regolamento UE 2016/679 sulla protezione dei
                dati personali ("Regolamento") e acconsento al trattamento del
                mio indirizzo email per le finalità ivi indicate
              </label>
            </div>
            <Button
              className="landing_login_button"
              style={{ padding: "6px 0 0 0 0" }}
              // type="submit"
              onClick={() => submitNewsEmail()}
            >
              {isNewsLoading ? (
                <Loader
                  active
                  inline
                  className="login_loader ladingFormLoader"
                />
              ) : (
                "ISCRIVITI"
              )}
            </Button>
          </div>
        </div>
      </div>

      {/* <Footer /> */}

      {/* <div className="carousel-container">
        <Carousel slides={carouselSlidesData} />
      </div> */}

      {/* <footer className="landing_footer">
        <div className="footer_link_container">
          <div className="footer_links_column_one">
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
          </div>
          <div className="footer_links_column_two">
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
          </div>
          <div className="footer_links_column_three">
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
          </div>
          <div className="footer_links_column_four">
            <div className="footer_link" style={{ fontWeight: 'normal' }}>
              Scarica l’ app:
            </div>
            <Button className="playstore_button footer_button">
              <img
                src={appleIcon}
                alt="apple-icon"
                style={{ margin: '0 5px 3px 0' }}
              />{' '}
              APP STORE
            </Button>
            <Button className="playstore_button footer_button">
              <img
                src={androidIcon}
                alt="apple-icon"
                style={{ margin: '0 5px 3px 0' }}
              />{' '}
              PLAY STORE
            </Button>
          </div>
        </div>
        <div className="footer_copyright_contiainer">
          <div className="all_rights">My-Stores® - All right reserved 2020</div>
          <div className="all_rights powered_by">powered by RetAPPs</div>
        </div>
      </footer> */}
      <LoginForm
        showLoginModal={showLoginModal}
        closeModal={closeLoginModal}
        setShowResetPassordModal={setShowResetPassordModal}
        setShowLoginModal={setShowLoginModal}
      />
      <SignupForm
        showSignUpModal={showSignUpModal}
        closeModal={closeSignUpModal}
        onSuccessfulSignUp={onSuccessfulSignUp}
      />
      <EmailVerificationModal
        showSignUpSuccesModal={showSignUpSuccesModal}
        closeSuccessfulSignUp={closeSuccessfulSignUp}
      />
      <RegistrationModal
        showRegistrationModal={showRegistrationModal}
        setShowRegistrationModal={setShowRegistrationModal}
        closeSuccessfulSignUp={closeSuccessfulSignUp}
        setShowSignUpModal={setShowSignUpModal}
      />
      <ResetPasswordModal
        showResetPassordModal={showResetPassordModal}
        setShowResetPassordModal={setShowResetPassordModal}
      />
      <EmailConformModal
        showMailConformModal={showMailConformModal}
        setShowMailConformModal={setShowMailConformModal}
        closeSuccessfulSignUp={closeSuccessfulSignUp}
        isMailConfirmed={isMailConfirmed}
        isLoading={isLoading}
      />
    </div>
  );
}

LandingPage.propTypes = {
  // dispatch: PropTypes.func.isRequired,
};

export function mapStateToProps(state) {
  return {
    allCities: state.homePageFav.cities,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCities: (payload) => dispatch({ type: "GET_CITIES", payload }),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: "cities", saga, mode: DAEMON });

export default compose(withConnect, memo, withSaga)(LandingPage);
