/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// /* eslint-disable prettier/prettier */
/* eslint-disable prefer-destructuring */
/* eslint-disable operator-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-useless-escape */
/* eslint-disable indent */
import React, { useState, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
// import moment from 'moment';
import axios from "axios";
import {
  Accordion,
  Icon,
  Input,
  Checkbox,
  Dropdown,
  Button,
  Form,
  Loader,
} from "semantic-ui-react";
import NumberFormat from "react-number-format";

// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// import { DateInput } from 'semantic-ui-calendar-react';
// import { it } from 'moment/locale/it';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";

import { useSnackbar } from "react-simple-snackbar";
import { connect } from "react-redux";
import { compose } from "redux";
import injectSaga from "../../utils/injectSaga";
import { DAEMON } from "../../utils/constants";
// import history from '../../utils/history';
import * as API from "../../Helpers/api";
import saga from "./saga";
// import { isEmail } from 'validator';
import {
  cardTypes,
  americanExpress,
  maestro,
  masterCard,
  visa,
  calendar,
} from "../../assets/images";
import EmailVerificationModal from "../LandingPage/EmailVerificationModal";
import history from "../../utils/history";
import "./StoreRegisterForm.scss";

registerLocale("it", it);

const StoreRegisterForm = (props) => {
  const { t } = useTranslation();
  //   const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState([0]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCity, setSlectedCity] = useState("");
  const [citiesOptions, setCitiesOptions] = useState([
    // {
    //   key: '00010',
    //   text: 'Casape [00010]',
    //   value: '00010',
    // },
    // {
    //   key: '00012',
    //   text: 'Guidonia Montecelio [00012]',
    //   value: '00012',
    // },
    // {
    //   key: '00013',
    //   text: 'Fonte Nuova [00013]',
    //   value: '00013',
    // },
  ]);
  const [initialCityData, setInitialCityData] = useState([
    // {
    //   id: '00010',
    //   city: 'Casape',
    //   istat: '58021',
    //   province: 'RM',
    //   lng: 12.886139,
    //   lat: 41.906406,
    // },
    // {
    //   id: '00012',
    //   city: 'Guidonia Montecelio',
    //   istat: '58047',
    //   province: 'RM',
    //   lng: 12.726386,
    //   lat: 42.000078,
    // },
    // {
    //   id: '00013',
    //   city: 'Fonte Nuova',
    //   istat: '58122',
    //   province: 'RM',
    //   lng: 12.618873,
    //   lat: 41.996273,
    // },
  ]);
  // const [newDate, setNewDate] = useState();

  const [businessCateg, setBusinessCateg] = useState([]);
  const [showSignUpSuccesModal, setShowSignUpSuccesModal] = useState(false);
  const [cardType, setCardType] = useState("Invalid");
  const [selectedBuisnessType, setSelectedBuisnessType] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState([]);
  let mandIndex = 0;
  let optIndex = 2;

  const [formValues, setFormValues] = useState({
    name: "",
    lastName: "",
    dateOfBirth: "",
    email: "",
    password: "",
    confirmPassword: "",
    musicName: "",
    businessType: "",
    businessCategory: "",
    companyName: "",
    vatNumber: "",
    fiscalCode: "",
    phone: "",
    address: "",
    streetNumber: "",
    postalCode: "",
    city: "",
    province: "",
    billingEmail: "",
    pec: "",
    recCode: "",
    cardHolderName: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    promoCode: "",
    type1: false,
    type2: false,
    type3: false,
    type4: false,
  });
  const [validationErros, setValidationErros] = useState({});
  //   const [isMonthlyPlanSelected, setIsMonthlyPlanSelected] = useState(false);
  const [isYearlyPlanSelected, setIsYearlyPlanSelected] = useState(false);
  // const [openSnackbar] = useSnackbar();
  const [openSnackbar] = useSnackbar({
    // position: 'top-center',
    style: {
      position: "absolute",
      backgroundColor: "green",
      border: "2px solid lightgreen",
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
      bottom: "100%",
      left: 0,
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });
  const [openSnackbarRed] = useSnackbar({
    // position: 'top-center',
    style: {
      position: "absolute",
      backgroundColor: "red",
      border: "2px solid lightgreen",
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
      bottom: "100%",
      left: 0,
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });

  useEffect(() => {
    axios({
      method: "get",
      url: API.shopCategories,
      // auth: {
      //   username: 'g.scirea',
      //   password: 'testtest',
      // },
    })
      .then((res) => {
        const categories = [];
        res.data.forEach((item) => {
          const obj = {};
          obj.key = item.id;
          // obj.text = item.description;
          if (item.description) {
            obj.text = `${item.description
              .charAt(0)
              .toUpperCase()}${item.description.slice(1)}`;
          }
          obj.value = item.id;
          categories.push(obj);
        });
        setBusinessCateg(categories);
      })
      .catch((err) => {
        console.log(err);
      });

    // Get privacy policy

    axios.get(API.getPrivacyPolicy).then(
      (res) => {
        setPrivacyPolicy(res.data.data);
      },
      (err) => {
        console.log(err);
      }
    );

    // Get cities

    axios.get(API.getCities).then(
      (res) => {
        const cityArray = [];
        if (res && res.data && res.data.data) {
          res.data.data.sort((a, b) => a.id - b.id);
          res.data.data.forEach((item) => {
            cityArray.push({
              key: item.id,
              value: item.id,
              text: `${item.city} [${item.id}]`,
            });
          });
        }
        setCitiesOptions(cityArray);
        console.log(cityArray);
        setInitialCityData(res.data.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    // props.getFavStores();
    if (props.operatorSignUpError) {
      openSnackbar("Something went wrong");
    }
    //  else {
    //   setShowSignUpSuccesModal(true);
    // }
  }, [props.operatorSignUpError]);

  const handleFormValueChanges = (event, { name, value }) => {
    setFormValues({ ...formValues, [name]: value });
  };
  const handleCardNumberChanges = (values) => {
    setFormValues({ ...formValues, cardNumber: values.value });
    // Check Card Type
    let typeCheck = values.value.substring(0, 2);
    let cType = "Invalid";
    if (typeCheck.length === 2) {
      typeCheck = parseInt(typeCheck, 10);
      if (typeCheck >= 40 && typeCheck <= 49) {
        cType = "Visa";
      } else if (typeCheck >= 51 && typeCheck <= 55) {
        cType = "Master Card";
      } else if ((typeCheck >= 56 && typeCheck <= 69) || typeCheck === 50) {
        cType = "Maestro";
      } else if (typeCheck === 34 || typeCheck === 37) {
        cType = "American Express";
      } else {
        cType = "Invalid";
      }
    }
    setCardType(cType);
  };
  const handleCardExpiryDate = (values) => {
    setFormValues({ ...formValues, expiryDate: values.value });
  };
  const cardExpiry = (val) => {
    const month = limit(val.substring(0, 2), "12");
    const date = limit(val.substring(2, 4), "31");

    // eslint-disable-next-line prefer-template
    return month + (date.length ? "/" + date : "");
  };
  function limit(value, max) {
    let val = value;
    if (val.length === 1 && val[0] > max[0]) {
      // eslint-disable-next-line prefer-template
      val = "0" + val;
    }

    if (val.length === 2) {
      if (Number(val) === 0) {
        val = "01";

        // eslint-disable-next-line spaced-comment
        //this can happen when user paste number
      } else if (val > max) {
        val = max;
      }
    }

    return val;
  }
  function convertDateFormat(str) {
    const date2 = new Date(str);
    const mnth = `0${date2.getMonth() + 1}`.slice(-2);
    const day = `0${date2.getDate()}`.slice(-2);
    return [day, mnth, date2.getFullYear()].join("-");
  }

  const hanldeCategorySelect = (event, { name, value }) => {
    console.log(name, value);
    setSelectedBuisnessType(value);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const errors = validateData(formValues);
    setValidationErros(errors);
    const activeTabs = [0];
    if (
      errors.musicName ||
      errors.businessType ||
      errors.selectedBuisnessType
    ) {
      activeTabs.push(1);
    }
    if (
      errors.fiscalCode ||
      errors.phone ||
      errors.address ||
      errors.streetNumber ||
      errors.postalCode ||
      errors.city
    ) {
      activeTabs.push(2);
    }
    if (errors.type) {
      activeTabs.push(6);
    }
    setActiveIndex(activeTabs);

    // props.operatorSignUp(formValues);
    console.log(errors);
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      // setShowSignUpSuccesModal(true);
      const payload = {};
      // credentials objects
      const credentials = {};
      credentials.username = formValues.email;
      credentials.encodedPassword = formValues.password;
      // Address object
      const address = {};
      // address.billingName = formValues.companyName;
      address.vatCode = formValues.vatNumber;
      address.fiscalCode = formValues.fiscalCode;
      address.fax = formValues.phone;
      address.phone = formValues.phone;
      address.address = formValues.address;
      address.streetNumber = formValues.streetNumber;
      address.zip = selectedCity;

      const fiilteredCityObj = initialCityData.find(
        (item) => item.id === selectedCity
      );

      address.city = fiilteredCityObj.city;
      // address.province = fiilteredCityObj.province;
      address.province = formValues.province;
      address.email = formValues.billingEmail;
      address.pec = formValues.pec;
      address.destinationCode = formValues.recCode;
      payload.storeName = formValues.musicName;

      address.county = "Italy";
      address.state = "Milan";
      address.nation = "Italy";

      address.latitude = fiilteredCityObj.lat;
      address.longitude = fiilteredCityObj.lng;
      address.name = formValues.companyName;

      // address.latitude = 75.11594;
      // address.longitude = 26.168262;

      // Final payload

      // Basic details

      payload.firstName = formValues.name;
      payload.lastName = formValues.lastName;
      if (formValues.dateOfBirth && formValues.dateOfBirth !== "") {
        const newBirthDate = convertDateFormat(formValues.dateOfBirth);
        payload.birthDate = newBirthDate.split("-").reverse().join("-");
      } else {
        payload.birthDate = null;
      }

      // payload.birthDate = formValues.dateOfBirth
      //   .split('/')
      //   .reverse()
      //   .join('/');
      payload.email = formValues.email;
      payload.credentials = credentials;

      // Business Info
      // Need to pass the fields such as musicName, businessType(shop or mall)
      payload.description = formValues.musicName;
      payload.type = formValues.businessType.toUpperCase();
      payload.categoryId = selectedBuisnessType;

      // Billing Address details

      // Fiels need to pass: companyName, vatNumber,fiscalCode ,postalCode,province,billingEmail,pec,recCode
      payload.homePhone = formValues.phone;
      payload.mobilePhone = formValues.phone;
      payload.address = address;

      // payload.accept1 = formValues.type1;
      // payload.accept2 = formValues.type2;
      payload.accept1 = true;
      payload.accept2 = true;
      payload.accept3 = formValues.type3;
      payload.accept4 = formValues.type4;

      payload.code = new Date();
      payload.ssnCode = new Date();
      // payload.storeId = '1232';
      payload.roles = "3123";
      payload.image = "";
      payload.attrib1 = "at1";
      payload.attrib2 = "at2";
      payload.attrib3 = "at3";
      payload.attrib4 = "at4";
      payload.enabled = true;

      payload.language = "it";

      // props.operatorSignUp(payload);

      axios.post(API.operatorSignUp, payload).then(
        (res) => {
          setIsLoading(false);
          if (res.data.responseCode === 0) {
            openSnackbar("Sign up successfull!!");
            setShowSignUpSuccesModal(true);
            setFormValues({
              name: "",
              lastName: "",
              dateOfBirth: "",
              email: "",
              password: "",
              confirmPassword: "",
              musicName: "",
              businessType: "",
              businessCategory: "",
              companyName: "",
              vatNumber: "",
              fiscalCode: "",
              phone: "",
              address: "",
              streetNumber: "",
              postalCode: "",
              city: "",
              province: "",
              billingEmail: "",
              pec: "",
              recCode: "",
              cardHolderName: "",
              cardNumber: "",
              expiryDate: "",
              cvv: "",
              promoCode: "",
              showPassword: false,
              showConfirmPassword: false,
              selectedBuisnessType: "",
              type1: false,
              type2: false,
              type3: false,
              type4: false,
            });
            setSlectedCity("");
            setSelectedBuisnessType("");
            setValidationErros({});
          } else if (res.data.responseCode === 1) {
            openSnackbarRed("Email already exist !");
          } else {
            openSnackbarRed("Something went wrong !");
          }
        },
        (err) => {
          setIsLoading(false);
          openSnackbarRed("Something went wrong !");

          console.log(err);
        }
      );
    }
  };

  const validateData = (data) => {
    // const data = formValues;
    const errors = {};
    // if (!data.name) errors.name = "Name can't be empty";
    if (!data.name) errors.name = t("userSignupValidation.name");

    // if (!data.lastName) errors.lastName = "Last name Can't be empty";
    if (!data.lastName) errors.lastName = t("userSignupValidation.lastName");

    // Age calculater
    if (data.dateOfBirth) {
      // const d = new Date(data.dateOfBirth);
      // const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      // const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
      // const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

      // const dob = new Date(ye, mo, da);
      // const diffMs = Date.now() - dob.getTime();
      // const ageDt = new Date(diffMs);

      // const finalAge = Math.abs(ageDt.getUTCFullYear() - 1970);
      // console.log(finalAge);
      const today = new Date();
      const birthDate = new Date(data.dateOfBirth);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age -= 1;
      }
      const finalAge = age;

      // if (finalAge < 18) errors.dateOfBirth = 'Age must be greater than 18';
      if (finalAge < 18)
        errors.dateOfBirth = t("userSignupValidation.dobValidate");
    } else {
      // errors.dateOfBirth = 'Age is compulsary';
      // errors.dateOfBirth = t('userSignupValidation.dob');
    }
    // if (!isEmail(data.email)) errors.email = 'Invalid email';
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (data.email) {
      if (!regEmail.test(data.email)) {
        errors.email = t("userSignupValidation.emailInvalid");
      }
    } else {
      // errors.email = "Email can't be empty";
      errors.email = t("userSignupValidation.emptyEmail");
    }
    // if (!data.password) errors.password = "Password can't be empty";
    if (!data.password)
      errors.password = t("userSignupValidation.passwordEmpty");

    // Password validation
    if (data.password.length) {
      const paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/.test(
        data.password
      );
      if (!paswd) {
        errors.password = t("userSignupValidation.passwordValid");
      }
    }

    // Phone validation
    /*
    ^                           # beginning of line
(\((00|\+)39\)|(00|\+)39)?  # country code or trunk code, with or without parentheses
(                           # followed by one of the following
 32[89]|                    # 328 or 329
 33[013-9]|                 # 33x where x != 2
 34[04-9]|                  # 34x where x not in 1,2,3
 35[01]|                    # 350 or 351
 36[068]|                   # 360 or 366 or 368
 37[019]                    # 370 or 371 or 379
 38[089])                   # 380 or 388 or 389
\d{6,7}                     # ... followed by 6 or 7 digits
$                           # and end of line
*/
    // if (data.phone.length) {
    //   const phone = /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/.test(
    //     data.phone,
    //   );
    //   if (!phone) {
    //     errors.phone = 'Phone number not valid';
    //   }
    // }

    if (data.confirmPassword !== data.password)
      // errors.confirmPassword = 'Not same as password';
      errors.confirmPassword = t("userSignupValidation.confrmPasswordNotSame");

    // if (!data.companyName) errors.companyName = "Company name can't be empty";
    if (!data.companyName) errors.companyName = t("operatorSignUp.companyName");

    // if (!data.vatNumber) errors.vatNumber = "Vat number can't be empty";
    if (!data.vatNumber) errors.vatNumber = t("operatorSignUp.vatNumber");

    // if (!data.fiscalCode) errors.fiscalCode = "Fiscal code can't be empty";
    if (!data.fiscalCode) errors.fiscalCode = t("operatorSignUp.fiscalCode");

    // if (!data.phone) errors.phone = "Phone can't be empty";
    if (!data.phone) errors.phone = t("operatorSignUp.phone");

    // if (!data.address) errors.address = "address can't be empty";
    if (!data.address) errors.address = t("operatorSignUp.address");

    // if (!data.streetNumber) errors.streetNumber = 'Field is compulsory';
    if (!data.streetNumber)
      errors.streetNumber = t("operatorSignUp.streetNumber");

    // if (!data.postalCode) errors.postalCode = 'Field is compulsory';
    if (!data.postalCode) errors.postalCode = t("operatorSignUp.postalCode");

    // if (!data.city) errors.city = "City can't be empty";
    // if (!data.type1 || !data.type2)
    // errors.type = 'First two fields are mandatory';
    // errors.type = t('operatorSignUp.type');

    // if (!data.province) errors.province = 'Field is compulsory';
    if (!data.province) errors.province = t("operatorSignUp.province");

    // if (!data.billingEmail) errors.billingEmail = "Email can't be empty";
    if (data.billingEmail) {
      if (!regEmail.test(data.billingEmail)) {
        // errors.billingEmail = 'Invalid email';
        errors.billingEmail = t("userSignupValidation.emailInvalid");
      }
    } else {
      errors.billingEmail = t("userSignupValidation.emptyEmail");
    }

    // if (!data.musicName) errors.musicName = 'Field is compulsory';
    if (!data.musicName) errors.musicName = t("operatorSignUp.musicName");

    // if (!data.businessType) errors.businessType = 'Please select type';
    if (!data.businessType)
      errors.businessType = t("operatorSignUp.businessType");

    if (!selectedBuisnessType)
      // errors.selectedBuisnessType = 'Please select category';
      errors.selectedBuisnessType = t("operatorSignUp.selectedBuisnessType");

    // if (!selectedCity) errors.selectedCity = "City can't be empty";
    if (!selectedCity) errors.selectedCity = t("operatorSignUp.selectedCity");

    // if (!data.pec) errors.pec = "Field can't be empty";
    if (!data.pec) errors.pec = t("operatorSignUp.pec");

    // if (!data.recCode) errors.recCode = "Field can't be empty";
    if (!data.recCode) errors.recCode = t("operatorSignUp.recCode");

    // if (!data.cardHolderName) errors.cardHolderName = 'Name is required';
    // if (!data.cardNumber) errors.cardNumber = 'Card number is required';
    // if (!data.expiryDate) errors.expiryDate = 'Date is required';
    // if (!data.cvv) errors.cvv = 'CVV is required';

    // setValidationErros(errors);
    return errors;
  };
  const closeSuccessfulSignUp = () => {
    // setShowSignUpModal(false);
    setShowSignUpSuccesModal(false);
    history.push("/");
  };

  const onSelectPrivacyPoints = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.checked });
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    // const newIndex = activeIndex === index ? -1 : index;
    const newIndex =
      activeIndex.indexOf(index) === -1
        ? [...activeIndex, index]
        : activeIndex.filter((item) => item !== index);

    setActiveIndex(newIndex);
  };
  const iconName = activeIndex.includes(0) ? "angle down" : "angle up";
  const iconName1 = activeIndex.includes(1) ? "angle down" : "angle up";
  const iconName2 = activeIndex.includes(2) ? "angle down" : "angle up";
  const iconName3 = activeIndex.includes(3) ? "angle down" : "angle up";
  const iconName4 = activeIndex.includes(4) ? "angle down" : "angle up";
  const iconName5 = activeIndex.includes(5) ? "angle down" : "angle up";
  const iconName6 = activeIndex.includes(6) ? "angle down" : "angle up";

  // const friendOptions = [
  //   {
  //     key: 'Jenny Hess',
  //     text: 'Jenny Hess',
  //     value: 'Jenny Hess',
  //   },
  //   {
  //     key: 'Elliot Fu',
  //     text: 'Elliot Fu',
  //     value: 'Elliot Fu',
  //   },
  // ];
  return (
    <div>
      <Form className="store_registration_form_container" autoComplete="off">
        {" "}
        <div className="store_registration_heading">
          Registra la tua attività
        </div>
        <Accordion
          className="store_register_accordian"
          exclusive={false}
          activeIndex={activeIndex}
        >
          <div className="account_section">
            <Accordion.Title
              active={activeIndex.includes(0)}
              index={0}
              onClick={handleClick}
              style={activeIndex.includes(0) ? { boxShadow: "none" } : null}
            >
              <Icon name={iconName} className="store_register_accordian_icon" />
              <span className="store_register_accordian_heading">Account</span>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex.includes(0)}
              style={{ padding: "30px" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="input_container">
                  <Input
                    name="name"
                    value={formValues.name}
                    type="text"
                    placeholder="Nome*"
                    onChange={handleFormValueChanges}
                    style={{ width: "327px", height: "50px" }}
                    autoComplete="off"
                  />
                  {!formValues.name.length && validationErros.name && (
                    <span className="input_error_message">
                      {validationErros.name}
                    </span>
                  )}
                </div>
                <div className="input_container">
                  <Input
                    name="lastName"
                    type="text"
                    value={formValues.lastName}
                    placeholder="Cognome*"
                    onChange={handleFormValueChanges}
                    style={{ width: "327px", height: "50px" }}
                    autoComplete="off"
                  />
                  {!formValues.lastName && validationErros.lastName && (
                    <span className="input_error_message">
                      {validationErros.lastName}
                    </span>
                  )}
                </div>
              </div>
              <div className="input_container" style={{ paddingTop: "20px" }}>
                {/* <DateInput
                  style={{ width: '327px', height: '50px', marginTop: '20px' }}
                  name="dateOfBirth"
                  placeholder="Data di nascita*"
                  value={formValues.dateOfBirth}
                  iconPosition="right"
                  onChange={handleFormValueChanges}
                  closeOnMouseLeave
                  animation="none"
                  closable
                  icon="calendar alternate outline"
                  autoComplete="off"
                  maxDate={new Date()}
                /> */}

                <DatePicker
                  placeholderText="Data di nascita"
                  selected={formValues.dateOfBirth}
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    setFormValues({ ...formValues, dateOfBirth: date });
                  }}
                  locale="it"
                />

                <img src={calendar} alt="calendar" className="calendar_image" />
                {/* <Calendar
                  onChange={date => setNewDate(date)}
                  value={newDate}
                  locale="it"
                /> */}
                {validationErros.dateOfBirth && (
                  <span className="input_error_message" style={{ top: "80px" }}>
                    {validationErros.dateOfBirth}
                  </span>
                )}
              </div>
              <div className="input_container">
                <Input
                  name="email"
                  type="email"
                  value={formValues.email}
                  placeholder="Indirizzo email*"
                  onChange={handleFormValueChanges}
                  style={{
                    width: "684px",
                    height: "50px",
                    marginTop: "20px",
                    marginLeft: "0px",
                  }}
                  autoComplete="off"
                />
                {validationErros.email && (
                  <span className="input_error_message" style={{ top: "72px" }}>
                    {validationErros.email}
                  </span>
                )}
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="input_container">
                  <Input
                    name="password"
                    value={formValues.password}
                    type={
                      formValues.showPassword ||
                      formValues.password.length === 0
                        ? "text"
                        : "password"
                    }
                    placeholder="Password*"
                    onChange={handleFormValueChanges}
                    style={{
                      width: "327px",
                      height: "50px",
                      marginTop: "20px",
                    }}
                    autoComplete="off"
                  />
                  {!formValues.showPassword && (
                    // <button
                    //   type="button"
                    //   onClick={() =>
                    //     setFormValues({ ...formValues, showPassword: true })
                    //   }
                    // >
                    <i
                      className="eye slash icon"
                      style={{
                        position: "absolute",
                        top: "35px",
                        right: "0",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setFormValues({ ...formValues, showPassword: true })
                      }
                    />
                    // </button>
                  )}

                  {formValues.showPassword && (
                    // <button
                    //   type="button"
                    //   onClick={() =>
                    //     setFormValues({ ...formValues, showPassword: false })
                    //   }
                    // >
                    <i
                      className="eye icon"
                      style={{
                        position: "absolute",
                        top: "35px",
                        right: "0px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setFormValues({ ...formValues, showPassword: false })
                      }
                    />
                    // </button>
                  )}
                  {validationErros.password && (
                    <span
                      className="input_error_message"
                      style={{ top: "72px" }}
                    >
                      {validationErros.password}
                    </span>
                  )}
                </div>
                <div className="input_container">
                  <Input
                    name="confirmPassword"
                    value={formValues.confirmPassword}
                    // type={formValues.confirmPassword ? 'password' : 'text'}
                    type={
                      formValues.showConfirmPassword ||
                      formValues.confirmPassword.length === 0
                        ? "text"
                        : "password"
                    }
                    placeholder="Conferma password*"
                    onChange={handleFormValueChanges}
                    style={{
                      width: "327px",
                      height: "50px",
                      marginTop: "20px",
                    }}
                    autoComplete="off"
                  />
                  {!formValues.showConfirmPassword && (
                    // <button
                    //   type="button"
                    //   onClick={() =>
                    //     setFormValues({
                    //       ...formValues,
                    //       showConfirmPassword: true,
                    //     })
                    //   }
                    // >
                    <i
                      className="eye slash icon"
                      style={{
                        position: "absolute",
                        top: "35px",
                        right: "0",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setFormValues({
                          ...formValues,
                          showConfirmPassword: true,
                        })
                      }
                    />
                    // </button>
                  )}

                  {formValues.showConfirmPassword && (
                    // <button
                    //   type="button"
                    //   onClick={() =>
                    //     setFormValues({
                    //       ...formValues,
                    //       showConfirmPassword: false,
                    //     })
                    //   }
                    // >
                    <i
                      className="eye icon"
                      style={{
                        position: "absolute",
                        top: "35px",
                        right: "0px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setFormValues({
                          ...formValues,
                          showConfirmPassword: false,
                        })
                      }
                    />
                    // </button>
                  )}
                  {validationErros.confirmPassword && (
                    <span
                      className="input_error_message"
                      style={{ top: "72px" }}
                    >
                      {validationErros.confirmPassword}
                    </span>
                  )}
                </div>
              </div>
            </Accordion.Content>
          </div>

          {/* Business Section */}

          <div className="business_details_section accordian_box_style">
            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={handleClick}
              style={activeIndex.includes(1) ? { boxShadow: "none" } : null}
            >
              <Icon
                name={iconName1}
                className="store_register_accordian_icon"
              />
              <span className="store_register_accordian_heading">
                Infomazioni sulla tua attività
              </span>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex.includes(1)}
              style={{ padding: "30px" }}
            >
              <div className="input_container">
                <Input
                  name="musicName"
                  value={formValues.musicName}
                  type="text"
                  placeholder="Inserisci il nome del tuo negozio"
                  onChange={handleFormValueChanges}
                  style={{ width: "100%", height: "50px" }}
                  autoComplete="off"
                />
                {validationErros.musicName && (
                  <span className="input_error_message">
                    {validationErros.musicName}
                  </span>
                )}
              </div>

              <div className="business_type" style={{ marginLeft: "15px" }}>
                <div className="business_type_heading">
                  La tua attività è un:
                </div>
                <Checkbox
                  radio
                  label="Centro Commerciale"
                  name="businessType"
                  value="mall"
                  checked={formValues.businessType === "mall"}
                  onChange={handleFormValueChanges}
                />
                <Checkbox
                  style={{ marginLeft: "50px" }}
                  radio
                  label="Negozio"
                  name="businessType"
                  value="store"
                  checked={formValues.businessType === "store"}
                  onChange={handleFormValueChanges}
                />
                {validationErros.businessType && (
                  <span className="input_error_message input_error_buisnessType">
                    {validationErros.businessType}
                  </span>
                )}
              </div>
              <div className="buiness_category_container">
                <Dropdown
                  name="buisnesscategory"
                  style={{
                    marginTop: "30px",
                    minWidth: "100%",
                    marginBottom: "20px",
                  }}
                  placeholder="Seleziona la categoria principale della tua attività"
                  fluid
                  search
                  selection
                  clearable
                  value={selectedBuisnessType}
                  options={businessCateg}
                  onChange={hanldeCategorySelect}
                />
                <span className="business_message">
                  Potrai poi aggiungerne altre dalla console di amministrazione
                </span>
                {validationErros.selectedBuisnessType && (
                  <span className="input_error_message input_error_buisnessCateogry">
                    {validationErros.selectedBuisnessType}
                  </span>
                )}
              </div>
            </Accordion.Content>
          </div>

          {/*  Billing Address */}

          <div className="biilind_address_section accordian_box_style">
            <Accordion.Title
              active={activeIndex === 2}
              index={2}
              onClick={handleClick}
              style={activeIndex.includes(2) ? { boxShadow: "none" } : null}
            >
              <Icon
                name={iconName2}
                className="store_register_accordian_icon"
              />
              <span className="store_register_accordian_heading">
                Indirizzo di fatturazione
              </span>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex.includes(2)}
              style={{ padding: "30px" }}
            >
              <div className="input_container">
                <Input
                  name="companyName"
                  value={formValues.companyName}
                  type="text"
                  placeholder="Nome o Ragione Sociale*"
                  onChange={handleFormValueChanges}
                  style={{ width: "100%", height: "50px" }}
                  autoComplete="off"
                />
                {!formValues.companyName && validationErros.companyName && (
                  <span className="input_error_message">
                    {validationErros.companyName}
                  </span>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "25px",
                }}
              >
                <div className="input_container">
                  <Input
                    name="vatNumber"
                    value={formValues.vatNumber}
                    type="text"
                    placeholder="P. Iva*"
                    onChange={handleFormValueChanges}
                    style={{ width: "327px", height: "50px" }}
                    autoComplete="off"
                  />
                  {!formValues.vatNumber && validationErros.vatNumber && (
                    <span className="input_error_message">
                      {validationErros.vatNumber}
                    </span>
                  )}
                </div>

                <div className="input_container">
                  <Input
                    name="fiscalCode"
                    type="text"
                    value={formValues.fiscalCode}
                    placeholder="Codice Fiscale*"
                    onChange={handleFormValueChanges}
                    style={{ width: "327px", height: "50px" }}
                    autoComplete="off"
                  />
                  {!formValues.fiscalCode && validationErros.fiscalCode && (
                    <span className="input_error_message">
                      {validationErros.fiscalCode}
                    </span>
                  )}
                </div>
              </div>
              <div className="input_container">
                <Input
                  name="phone"
                  value={formValues.phone}
                  type="number"
                  placeholder="Telefono*"
                  onChange={handleFormValueChanges}
                  maxLength="12"
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength) {
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    }
                  }}
                  style={{
                    width: "327px",
                    height: "50px",
                    marginTop: "25px",
                    marginLeft: "0",
                  }}
                  autoComplete="off"
                />
                {validationErros.phone && (
                  <span style={{ top: "78px" }} className="input_error_message">
                    {validationErros.phone}
                  </span>
                )}
              </div>

              <div style={{ display: "flex" }}>
                <div className="input_container">
                  <Input
                    name="address"
                    value={formValues.address}
                    type="text"
                    placeholder="Indirizzo di fatturazione*"
                    onChange={handleFormValueChanges}
                    style={{
                      width: "518px",
                      height: "50px",
                      marginTop: "25px",
                      marginRight: "20px",
                    }}
                    autoComplete="off"
                  />
                  {!formValues.address && validationErros.address && (
                    <span
                      style={{ top: "78px" }}
                      className="input_error_message"
                    >
                      {validationErros.address}
                    </span>
                  )}
                </div>
                <div className="input_container">
                  <Input
                    name="streetNumber"
                    value={formValues.streetNumber}
                    type="text"
                    placeholder="Civico*"
                    onChange={handleFormValueChanges}
                    style={{
                      width: "139px",
                      height: "50px",
                      marginTop: "25px",
                    }}
                    autoComplete="off"
                  />
                  {!formValues.streetNumber && validationErros.streetNumber && (
                    <span
                      style={{ top: "78px", width: "max-content" }}
                      className="input_error_message"
                    >
                      {validationErros.streetNumber}
                    </span>
                  )}
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div className="input_container">
                  <Input
                    name="postalCode"
                    value={formValues.postalCode}
                    type="number"
                    placeholder="Cap*"
                    disabled
                    onChange={handleFormValueChanges}
                    style={{
                      width: "139px",
                      height: "50px",
                      marginTop: "25px",
                      marginRight: "20px",
                      marginLeft: "0px",
                    }}
                    autoComplete="off"
                  />
                  {!formValues.postalCode && validationErros.postalCode && (
                    <span
                      style={{ top: "78px" }}
                      className="input_error_message"
                    >
                      {validationErros.postalCode}
                    </span>
                  )}
                </div>
                <div className="input_container input_container_city_dropdown">
                  {/* <Input
                    name="city"
                    value={formValues.city}
                    type="text"
                    placeholder="Città*"
                    onChange={handleFormValueChanges}
                    style={{
                      width: '353px',
                      height: '50px',
                      marginTop: '25px',
                      marginRight: '26px',
                    }}
                    autoComplete="off"
                  /> */}
                  <Dropdown
                    placeholder="Città o comune*"
                    fluid
                    search
                    selection
                    clearable
                    value={selectedCity}
                    options={citiesOptions}
                    autoComplete="off"
                    onChange={(e, { value }) => {
                      setSlectedCity(value);
                      const fiilteredCityObj = initialCityData.find(
                        (item) => item.id === value
                      );
                      if (value && fiilteredCityObj.province) {
                        setFormValues({
                          ...formValues,
                          postalCode: value,
                          province: fiilteredCityObj.province,
                        });
                      } else {
                        setFormValues({
                          ...formValues,
                          postalCode: "",
                          province: "",
                        });
                      }
                    }}
                  />
                  {!selectedCity && validationErros.selectedCity && (
                    <span
                      style={{ top: "78px" }}
                      className="input_error_message"
                    >
                      {validationErros.selectedCity}
                    </span>
                  )}
                </div>

                <div className="input_container">
                  <Input
                    name="province"
                    value={formValues.province}
                    type="text"
                    placeholder="Provincia*"
                    disabled
                    onChange={handleFormValueChanges}
                    style={{
                      width: "139px",
                      height: "50px",
                      marginTop: "25px",
                      marginLeft: "15px",
                    }}
                    autoComplete="off"
                  />
                  {!formValues.province && validationErros.province && (
                    <span
                      style={{ top: "78px" }}
                      className="input_error_message"
                    >
                      {validationErros.province}
                    </span>
                  )}
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="input_container">
                  <Input
                    name="billingEmail"
                    value={formValues.billingEmail}
                    type="text"
                    placeholder="Email*"
                    onChange={handleFormValueChanges}
                    style={{
                      width: "327px",
                      height: "50px",
                      marginTop: "20px",
                    }}
                    autoComplete="off"
                  />
                  {validationErros.billingEmail && (
                    <span
                      style={{ top: "78px" }}
                      className="input_error_message"
                    >
                      {validationErros.billingEmail}
                    </span>
                  )}
                </div>

                <div className="input_container">
                  <Input
                    name="pec"
                    value={formValues.pec}
                    type="text"
                    placeholder="Pec*"
                    onChange={handleFormValueChanges}
                    style={{
                      width: "327px",
                      height: "50px",
                      marginTop: "20px",
                    }}
                    autoComplete="off"
                  />
                  {!formValues.pec && validationErros.pec && (
                    <span
                      style={{ top: "78px" }}
                      className="input_error_message"
                    >
                      {validationErros.pec}
                    </span>
                  )}
                </div>
              </div>
              <div className="input_container">
                <Input
                  name="recCode"
                  value={formValues.recCode}
                  type="text"
                  placeholder="Codice destinatario (SDI)*"
                  maxLength="7"
                  onChange={handleFormValueChanges}
                  style={{
                    width: "326px",
                    height: "50px",
                    marginTop: "25px",
                    marginRight: "20px",
                  }}
                  autoComplete="off"
                />
                {!formValues.recCode && validationErros.recCode && (
                  <span style={{ top: "78px" }} className="input_error_message">
                    {validationErros.recCode}
                  </span>
                )}
              </div>
              <span className="business_message">
                La piattaforma rimarrà gratuita per tutto il 2020
              </span>
            </Accordion.Content>
          </div>

          {/* Fee Section */}

          <div className="fee_details_section accordian_box_style">
            <Accordion.Title
              active={activeIndex === 3}
              index={3}
              onClick={handleClick}
              style={activeIndex.includes(3) ? { boxShadow: "none" } : null}
            >
              <Icon
                name={iconName3}
                className="store_register_accordian_icon"
              />
              <span className="store_register_accordian_heading">
                Costo canone{" "}
              </span>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex.includes(3)}
              style={{ padding: "0 30px 30px 30px" }}
            >
              <p className="fee_description">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem.
              </p>
              <div className="plan_container">
                <button
                  type="button"
                  className="monthly_payment_info"
                  onClick={() => {
                    // setIsMonthlyPlanSelected(true);
                    setIsYearlyPlanSelected(false);
                  }}
                >
                  <span className="monthly_price"> ₤ 150</span>
                  <span className="monthly_price_text">al mese</span>
                </button>
                <button
                  type="button"
                  className="yearly_payment_info monthly_payment_info"
                  onClick={() => {
                    // setIsMonthlyPlanSelected(false);
                    setIsYearlyPlanSelected(true);
                  }}
                >
                  <div className="promo">PROMO</div>
                  {/* <div className="monthly_payment_info"> */}
                  <div
                    className={
                      isYearlyPlanSelected
                        ? "monthly_payment_info yearly_color"
                        : "monthly_payment_info"
                    }
                  >
                    <span
                      className="old_price"
                      style={
                        !isYearlyPlanSelected
                          ? { color: "#333333" }
                          : { color: "#ffffff" }
                      }
                    >
                      {" "}
                      ₤ 1.800
                    </span>
                    <span
                      className="new_price"
                      style={
                        !isYearlyPlanSelected
                          ? { color: "#c70202" }
                          : { color: "#ffffff" }
                      }
                    >
                      {" "}
                      ₤ 1.500
                    </span>
                    <span
                      className="monthly_price_text"
                      style={
                        !isYearlyPlanSelected
                          ? { color: "#333333" }
                          : { color: "#ffffff" }
                      }
                    >
                      all’anno*
                    </span>
                  </div>
                </button>
              </div>
              <p className="payment_info">
                *Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                natoque penatibus et magnis dis parturient montes, nascetur
                ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu,
                pretium quis, sem.
              </p>
            </Accordion.Content>
          </div>

          {/* Payment terms section */}

          <div className="payment_terms_section accordian_box_style">
            <Accordion.Title
              active={activeIndex === 4}
              index={4}
              onClick={handleClick}
              style={activeIndex.includes(4) ? { boxShadow: "none" } : null}
            >
              <Icon
                name={iconName4}
                className="store_register_accordian_icon"
              />
              <span className="store_register_accordian_heading">
                Modalità di pagamento{" "}
              </span>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex.includes(4)}
              style={{ padding: "0 30px 30px 30px" }}
            >
              <p className="payment_terms_description">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem.
              </p>
              <div className="input_container">
                <Input
                  name="cardHolderName"
                  value={formValues.cardHolderName}
                  type="text"
                  placeholder="Nome completo titolare carta*"
                  onChange={handleFormValueChanges}
                  style={{ width: "100%", height: "50px" }}
                  autoComplete="off"
                />
                {!formValues.cardHolderName &&
                  validationErros.cardHolderName && (
                    <span className="input_error_message">
                      {validationErros.cardHolderName}
                    </span>
                  )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "25px 0",
                }}
              >
                <div className="input_container" style={{ flex: "0 0 33%" }}>
                  {cardType === "Visa" && (
                    <img
                      src={visa}
                      alt="Visa card"
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "20px",
                        width: "20px",
                      }}
                    />
                  )}
                  {cardType === "Master Card" && (
                    <img
                      src={masterCard}
                      alt="Master card"
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "20px",
                        width: "20px",
                      }}
                    />
                  )}
                  {cardType === "Maestro" && (
                    <img
                      src={maestro}
                      alt="Maestro card"
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "20px",
                        width: "20px",
                      }}
                    />
                  )}
                  {cardType === "American Express" && (
                    <img
                      src={americanExpress}
                      alt="American express card"
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "20px",
                        width: "20px",
                      }}
                    />
                  )}

                  <NumberFormat
                    mask=""
                    placeholder="Numero carta*"
                    onValueChange={handleCardNumberChanges}
                    value={formValues.cardNumber}
                    // format="#### #### #### ####"
                    format={
                      cardType === "Maestro"
                        ? "#### #### #### #### ###"
                        : "#### #### #### ####"
                    }
                    style={
                      cardType !== "Invalid" ? { paddingLeft: "40px" } : null
                    }
                  />

                  {(validationErros.cardNumber &&
                    !formValues.cardNumber.length && (
                      <span className="input_error_message">
                        {validationErros.cardNumber}
                      </span>
                    )) ||
                    (formValues.cardNumber.length > 0 &&
                      cardType === "Invalid" && (
                        <span className="input_error_message">
                          Invalid card
                          {/* {validationErros.cardNumber} */}
                        </span>
                      ))}
                </div>
                <div className="input_container">
                  <NumberFormat
                    mask=""
                    placeholder="MM/AA*"
                    onValueChange={handleCardExpiryDate}
                    value={formValues.expiryDate}
                    format={cardExpiry}
                  />
                  {!formValues.expiryDate && validationErros.expiryDate && (
                    <span className="input_error_message">
                      Name is not valid
                    </span>
                  )}
                </div>

                <div className="input_container">
                  <Input
                    name="cvv"
                    value={formValues.cvv}
                    type="password"
                    placeholder="CVV*"
                    onChange={handleFormValueChanges}
                    style={{ width: "139px", height: "50px" }}
                    autoComplete="off"
                    maxLength="3"
                  />
                  {!formValues.cvv && validationErros.cvv && (
                    <span className="input_error_message">
                      {validationErros.cvv}
                    </span>
                  )}
                </div>
              </div>
              <img src={cardTypes} alt="card types" />
            </Accordion.Content>
          </div>

          <div className="payment_terms_section accordian_box_style">
            <Accordion.Title
              active={activeIndex === 5}
              index={5}
              onClick={handleClick}
              style={activeIndex.includes(5) ? { boxShadow: "none" } : null}
            >
              <Icon
                name={iconName5}
                className="store_register_accordian_icon"
              />
              <span className="store_register_accordian_heading">Totale </span>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex.includes(5)}
              style={{ padding: "30px" }}
            >
              <div className="payment_paid">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="amount_to_paid">COSTO SERVIZIO</span>{" "}
                  <span className="amount_to_paid">€ 10,00</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px 0",
                  }}
                >
                  <span className="amount_to_paid">PROMOCODE</span>{" "}
                  <span className="amount_to_paid">- € 20,00</span>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="net_amount">TOTALE</span>{" "}
                  <span className="net_amount">€ 210,00</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  position: "relative",
                }}
                className="input_container"
              >
                <Input
                  name="promoCode"
                  value={formValues.promoCode}
                  type="text"
                  placeholder="Inserisi qui il tuo promo code"
                  onChange={handleFormValueChanges}
                  style={{ width: "100%", height: "50px", marginLeft: "0px" }}
                  autoComplete="off"
                />
                <Button
                  style={{
                    height: "50px",
                    marginRight: "0px",
                    position: "absolute",
                    right: "-9px",
                  }}
                  className="landing_login_button"
                  type="button"
                  //   onClick={() => setShowLoginModal(true)}
                >
                  APPLICA
                </Button>
                {validationErros.promoCode && (
                  <span className="input_error_message">Name is not valid</span>
                )}
              </div>
            </Accordion.Content>
          </div>

          {/* Privacy points */}
          <div className="privacy_points_section accordian_box_style">
            <Accordion.Title
              active={activeIndex === 6}
              index={6}
              onClick={handleClick}
              style={activeIndex.includes(6) ? { boxShadow: "none" } : null}
            >
              <Icon
                name={iconName6}
                className="store_register_accordian_icon"
              />
              <span className="store_register_accordian_heading">
                Privacy e condizioni di utilizzo{" "}
              </span>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex.includes(6)}
              style={{ padding: " 0 0 30px 30px" }}
            >
              <div>
                {/* <p className="privacy_points">Privacy e condizioni di utilizzo</p> */}
                <p className="legal_info">
                  Leggi l&apos; informativa al trattamento dei dati personali
                  prima di procedere con la registrazione.
                  {/* <span
                    className="privacy_policy"
                    onClick={() => window.open(`/privacy-policy`)}
                    // history.push('/privacy-policy')
                  >
                    INFORMATIVA SULLA PRIVACY
                  </span> */}
                </p>
                {privacyPolicy &&
                  privacyPolicy.length > 0 &&
                  privacyPolicy.map((policy) => {
                    if (policy.mandatory) {
                      mandIndex = mandIndex + 1;
                      return (
                        <div className="checkbox_points operator_register_check">
                          <input
                            type="checkbox"
                            id={`type${mandIndex}`}
                            name={`type${mandIndex}`}
                            value={`type${mandIndex}`}
                            style={{ marginTop: "0px" }}
                            // className="operator_register_check"
                            // defaultChecked={policy.mandatory}
                            onChange={onSelectPrivacyPoints}
                          />
                          <label
                            onClick={() =>
                              window.open(`/policy/${policy.privacyId}`)
                            }
                          >
                            {policy.text}
                          </label>
                          {validationErros.type && mandIndex === 1 && (
                            <span
                              style={{
                                position: "absolute",
                                top: "-16px",
                                left: "30px",
                              }}
                              className="error_message_sign_up city_field"
                            >
                              {validationErros.type}
                            </span>
                          )}
                        </div>
                      );
                    }
                    return null;
                  })}

                {privacyPolicy.length > 0 &&
                  privacyPolicy.map((policy) => {
                    if (!policy.mandatory) {
                      optIndex = optIndex + 1;
                      return (
                        <div className="checkbox_points operator_register_check">
                          <input
                            type="checkbox"
                            id={`type${optIndex}`}
                            name={`type${optIndex}`}
                            value={`type${optIndex}`}
                            // className="checkbox_tick"
                            // defaultChecked={policy.mandatory}
                            onChange={onSelectPrivacyPoints}
                          />
                          <label
                            onClick={() =>
                              window.open(`/policy/${policy.privacyId}`)
                            }
                          >
                            {policy.text}
                          </label>
                        </div>
                      );
                    }
                    return null;
                  })}

                {/* <div className="checkbox_points" style={{ margin: '10px 0' }}>
                  <input
                    type="checkbox"
                    id="accept1"
                    name="accept1"
                    value="accept1"
                    className="checkbox_tick"
                    defaultChecked
                    onChange={onSelectPrivacyPoints}
                  />
                  <label htmlFor="accept1" className="privacy_points">
                    {' '}
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus
                  </label>
                </div>
                <div className="checkbox_points">
                  <input
                    type="checkbox"
                    id="accept2"
                    name="accept2"
                    value="accept2"
                    className="checkbox_tick"
                    defaultChecked
                    onChange={onSelectPrivacyPoints}
                  />
                  <label htmlFor="accept2" className="privacy_points">
                    {' '}
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus
                  </label>
                </div>
                <div className="checkbox_points">
                  <input
                    type="checkbox"
                    id="accept3"
                    name="accept3"
                    value="accept3"
                    className="checkbox_tick"
                    onChange={onSelectPrivacyPoints}
                  />
                  <label htmlFor="accept3" className="privacy_points">
                    {' '}
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus
                  </label>
                </div>
                <div className="checkbox_points">
                  <input
                    type="checkbox"
                    id="accept4"
                    name="accept4"
                    value="accept4"
                    className="checkbox_tick"
                    onChange={onSelectPrivacyPoints}
                  />
                  <label htmlFor="accept4" className="privacy_points">
                    {' '}
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus
                  </label>
                </div> */}
              </div>
            </Accordion.Content>
          </div>
          <Button
            style={{
              width: "374px",
              height: "44px",
              margin: "46px 175px",
            }}
            className="landing_login_button"
            type="button"
            onClick={handleSubmit}
          >
            {isLoading ? (
              <Loader active inline className="login_loader opSignUpLoader" />
            ) : (
              "PROCEDI CON LA REGISTRAZIONE"
            )}
          </Button>
        </Accordion>
      </Form>
      <EmailVerificationModal
        showSignUpSuccesModal={showSignUpSuccesModal}
        closeSuccessfulSignUp={closeSuccessfulSignUp}
      />
    </div>
  );
};
StoreRegisterForm.propTypes = {
  // operatorSignUp: PropTypes.func,
  operatorSignUpError: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
  return {
    operatorSignUp: (payload) =>
      dispatch({ type: "OPERATOR_SIGNNED_UP", payload }),
  };
}
export function mapStateToProps(state) {
  return {
    operatorSignUpError: state.operatorSignUp.operatorSignUpError,
    // favStores: state.homePageFav.favStores,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: "operatorSignUp", saga, mode: DAEMON });

export default compose(withConnect, memo, withSaga)(StoreRegisterForm);
