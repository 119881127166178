/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Message, Loader } from 'semantic-ui-react';
import axios from 'axios';
import { baseUrl } from '../../Helpers/api';
import history from '../../utils/history';
import './VerifyMail.scss';

function ConfirmMessage(props) {
  const [isMailConfirmed, setIsMailConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // const ConfirmApiUrl = `https://retapps-dev.demos.classicinformatics.com/api/cli/profiles/${
    //   props.match.params.profileId
    // }/otp/${props.match.params.otpId}`;
    const ConfirmApiUrl = `${baseUrl}/cli/profiles/${
      props.match.params.profileId
    }/otp/${props.match.params.otpId}`;
    axios.post(ConfirmApiUrl).then(
      result => {
        setIsLoading(false);
        setIsMailConfirmed(true);
        setTimeout(() => {
          history.push('/');
        }, 1000);
        console.log(result);
        console.log(result.responseCode);
      },
      err => {
        setIsMailConfirmed(false);
        setIsLoading(false);
        setTimeout(() => {
          history.push('/');
        }, 1000);
        console.log(err);
        console.log(err.responseCode);
      },
    );
  }, []);
  return (
    <div className="confirmation_popup">
      <Message
        info={isLoading}
        color={!isLoading ? (isMailConfirmed ? 'green' : 'red') : null}
      >
        <Message.Header>
          {isLoading
            ? 'Verifying your email'
            : isMailConfirmed
            ? // eslint-disable-next-line indent
              'Your email is verified'
            : 'Something went wrong'}

          {isLoading && (
            <Loader active inline className="confirmation_loader" />
          )}
        </Message.Header>
      </Message>
    </div>
  );
}
ConfirmMessage.propTypes = {
  match: PropTypes.object,
};
export default ConfirmMessage;
