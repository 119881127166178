/**
Sign up user
 */

import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { userDetails } from '../../Helpers/UserDetails';
import * as API from '../../Helpers/api';
import {
  getUserProfileSuccess,
  getUserProfileFailed,
  updateUserProfileSuccess,
  updateUserProfileFailed,
  updateUserProfileLoading,
  //   deleteUserProfileSuccess,
  //   deleteUserProfileFailed,
} from './actions';

/** function that returns an axios call */
function getUserProfileAPI() {
  console.log('Get user profile');
  return axios(API.userLogin, {
    method: 'GET',
    auth: {
      username: userDetails().email,
      password: userDetails().password,
    },
  });
}
function updateUserProfileAPI(payload) {
  console.log('update user profile');
  return axios(API.updateUserProfile, {
    method: 'PUT',
    auth: {
      username: userDetails().email,
      password: userDetails().password,
    },
    data: payload,
  });
}

export function* getUserProfileSaga(action) {
  try {
    const userProfile = yield call(getUserProfileAPI, action.payload);
    console.log(userProfile);
    if (userProfile) {
      yield put(getUserProfileSuccess(userProfile));
    }
  } catch (err) {
    console.log(err);
    yield put(getUserProfileFailed(err));
  }
}

export function* updateUserProfileSaga(action) {
  try {
    yield put(updateUserProfileLoading(true));
    const userProfile = yield call(updateUserProfileAPI, action.payload);
    console.log(userProfile);
    if (userProfile) {
      yield put(updateUserProfileSuccess(userProfile));
      yield put(updateUserProfileLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(updateUserProfileFailed(err));
    yield put(updateUserProfileLoading(false));
  }
}

export default function* signupData() {
  yield takeLatest('GET_USER_PROFILE', getUserProfileSaga);
  yield takeLatest('UPDATE_USER_PROFILE', updateUserProfileSaga);
  //   yield takeLatest('DELETE_FAVOURITE_STORES', deleteFavStoresSaga);
  //   yield takeLatest('GET_MALLS_STORES', getMallsStoresSaga);
}
