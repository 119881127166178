/* eslint-disable global-require */
// eslint-disable-next-line global-require
export const userIcon = require("./grey.svg");
export const logoIcon = require("./logo-new.png");
export const logoIcon2 = require("./text-blu.png");
export const appleIcon = require("./apple-icon.png");
export const androidIcon = require("./android-icon.png");
export const backgroudSecondSection = require("./sec-2-back-img-1.png");
// export const backgroudSecondSection = require("./sec-2-back-img-new.png");
export const secondSectionFirstImage = require("./section-2-img-1.png");
export const secondSectionSecondImage = require("./section-2-img-2.png");
export const secondSectionThirdImage = require("./section-2-img-3.png");
export const secondSectionDashedbackground = require("./sec-2-dashed-shape.png");
// export const phonesGroupImage = require('./phone-group-img.png');
export const phonesGroupImage = require("./phone-group-2.png");
// export const phonesGroupImage = require("./phone-group-2-new.png");

// export const thirdSectionBackImage = require("./sec-3-backg.png");
export const thirdSectionBackImage = require("./sec-3-backg-new.png");
export const thirdSectionDashedBack = require("./sec-3-dashed-backg.png");
export const thirdSectionFirstImage = require("./sec-3-img-1.png");
export const thirdSectionSecondImage = require("./sec-3-img-2.png");
export const thirdSectionThirdImage = require("./sec-3-img-3.png");
export const thirdSectionFourthImage = require("./sec-3-img-4.png");
export const thirdSectionFifthImage = require("./sec-3-img-5.png");
export const cardBackground = require("./card-backg.png");
export const cardDashedBackground = require("./card-dashed.png");
export const cardImage = require("./card-front.png");
export const aboutImage = require("./about_photo.png");
export const aboutBackgImage = require("./about-img-back.png");
export const diconoDiNoi = require("./dicono_di_noi.jpg");
export const aboutArrowLeft = require("./about-arrow-left.png");
export const aboutArrowRight = require("./about-arrow-right.png");
export const businessCardLogo = require("./business-card-logo.png");
export const businessbackgOne = require("./buiness-backg-1.png");
export const businessbackgTwo = require("./buisness-backg-2.png");
export const landingFormBackground = require("./landing-form-background.png");
export const emailVerification = require("./email-verification.png");
export const friendship = require("./friendship-1.png");
export const shop = require("./shop.png");
export const cardTypes = require("./card_type.png");
export const americanExpress = require("./americanExpress.png");
export const maestro = require("./maestro.png");
export const masterCard = require("./MasterCard.png");
export const visa = require("./visa.png");
export const menuButton = require("./menuButton.png");
export const search = require("./search.png");
export const heart = require("./heart.png");
export const message = require("./message.png");
export const profile = require("./profile.png");
export const searchActive = require("./search-active.png");
// export const heart = require('./heart.png');
export const messageActive = require("./message-active.png");
export const profileActive = require("./profile-active.png");
export const shoppingCentre = require("./shoping-centre.png");
export const locator = require("./locator.png");
export const store1 = require("./store-1.png");
export const store2 = require("./store-2.png");
export const store3 = require("./store-3.png");
export const store4 = require("./store-4.png");
export const store5 = require("./store-5.png");
export const store6 = require("./store-6.png");
export const store7 = require("./store-7.png");
export const heartCard = require("./heart-card.png");
export const crossSearch = require("./cross-search.png");
export const news = require("./news.png");
export const discount = require("./discount-notification.png");
export const messageNotification = require("./message-notification.png");
export const saleHeaderBackground = require("./message-notification.png");
export const promoRightIcon = require("./promo-right-botton.png");
export const promoItemImage = require("./promo_item_image.png");
export const promoModalSubmit = require("./promo-modal-submit.png");
export const foodPromoModal = require("./alimentari-promo-modal.png");
export const shopNamePromoModal = require("./shop-name-promo-modal.png");
export const fidelityCard = require("./fidelity-card.png");
export const mapLocator = require("./map_locator.png");
export const filter = require("./filter.png");
export const mapView = require("./mappa.png");
export const mapClose = require("./map-close.png");
export const largerLocator = require("./big-locator.png");
export const locationReload = require("./reload.png");
export const sortLogo = require("./sortLogo.svg");
export const leftArrowMall = require("./left-arrow-mall.png");
export const rightArrowMall = require("./right-arrow-mall.png");
export const mallImage1 = require("./mall-image-1.png");
export const mallImage2 = require("./mall-image-2.png");
export const mallImage3 = require("./mall-image-3.png");
export const mallImage4 = require("./mall-image-4.png");
export const mallImage5 = require("./mall-image-5.png");
export const mallImage6 = require("./mall-image-6.png");
export const mallImage7 = require("./mall-image-7.png");
export const mallImage8 = require("./mall-image-8.png");
export const shopShoes = require("./shop-shoes.png");
export const profileOrderShoe = require("./profile_shoe_order.png");
export const printer = require("./printer.png");
export const orderCalendar = require("./order-calendar.png");
export const closeModal = require("./close-modal.png");
export const heartFilled = require("./heart-filled.png");
export const promoModalImage = require("./promo_modal.png");
export const mobileHeader = require("./mobile-header-backgrounnd.png");
export const mobileCard = require("./mobile-card-image.png");
export const mobileShoppingCentre = require("./mobile-discover-shopping-centre.png");
export const mobileOfferGroup = require("./mobile-offers-group.png");
export const mobileViewGroup = require("./mobile-view-group-image.png");
export const productImagePlaceholder = require("./Product_image_placeholder.jpg");
export const mallHeaderFallback = require("./mall-header.png");
export const defaultBannerImage = require("./home-header-background.png");
export const menuClose = require("./menu-close.png");
export const noStore = require("./no-store.png");
export const verifiedUser = require("./verified-user.png");
export const calendar = require("./calendar-date.png");
export const homePagePhone = require("./home-page-phone.png");
