/* eslint-disable */
import React from "react";
// import NavBar from "../NavBar/NavBar";
// import Footer from "../footer/Footer";

function PrivacyPage() {
  return (
    <div>
      {/* <NavBar mapview showMapView /> */}
      <div className="privacy_content">
        <p className="privacy_heading">Privacy Policies</p>

        <p className="privacy_terms">
          Gentile Utente,
          <br />
          qui nel seguito sono fornite le informazioni relative al trattamento
          dei dati personali effettuato attraverso le App “My-Stores” (nel
          seguito, “App”) ed il sito my-stores.it (nel seguito, “il Sito”) ai
          sensi degli artt. 13-14 del Regolamento 2016/679 (UE) (nel seguito, il
          “Regolamento” o “GDPR”).
        </p>
        <p className="privacy_terms">
          Forniamo la presente Informativa non solo per adempiere agli obblighi
          di legge in materia di protezione dei dati personali, ma anche e
          soprattutto perché crediamo che la tutela dei dati dell’Utente
          registrato alle App ed al Sito sia un valore fondamentale della nostra
          attività. Per questa ragione, invitiamo l’Utente a leggere con
          attenzione la presente Informativa, per comprendere in che modo e per
          quali finalità vengono trattati i suoi dati personali. Per potersi
          registrare alle App ed al Sito, così come per acconsentire validamente
          alla raccolta ed alla memorizzazione dei suoi dati personali, l’Utente
          deve avere più di 16 anni. Qualora risultasse che l’Utente abbia meno
          di 16 anni, potremo bloccare o cancellare il Profilo personale. I
          genitori o gli esercenti la potestà sul minore possono, in ogni caso,
          contattarci per bloccare il Profilo personale del minore di cui sono
          responsabili, secondo le modalità qui nel seguito indicate.
        </p>
        <p className="privacy_title">1. Titolare del Trattamento</p>
        <p className="privacy_terms">
          Titolare del Trattamento è RetAPPs S.r.l., con sede legale in Brescia
          (BS), via E. Stassano n. 29, Cod. Fisc. e P.IVA 03388720983, iscritta
          al Registro delle Imprese di Brescia al n. BS-530039 (nel seguito,
          “RetAPPs” o il “Titolare del Trattamento”).
        </p>
        <p className="privacy_title">2. Categorie di dati personali trattati</p>
        <p className="privacy_terms">
          Per poter effettuare la registrazione alle App ed al Sito e creare un
          Profilo personale, così come per visitare e consultare i contenuti
          delle App e del Sito, per selezionare ed accedere ai Negozi, per
          effettuare il check-in presso i Negozi Fisici, per selezionare ed
          accedere ai Centri Commerciali, per fruire dei programmi Fedeltà e
          delle Promozioni riservate all’Utente – per come definiti nelle TCU[1]
          - così come per consentire l’accesso ad ogni ulteriore funzionalità
          delle App e del Sito, raccogliamo ed utilizziamo una serie di dati
          personali comuni, vale a dire informazioni che, direttamente o
          indirettamente, consentono l’identificazione dell’Utente. Sono
          indicate nel seguito le categorie di dati personali trattate:
        </p>
        <p className="privacy_terms">
          <ul>
            <li>
              Dati identificativi, di contatto e di accesso, quali nome,
              cognome, data di nascita, sesso, indirizzo-email, password, città
              preferita ai fini della fruizione delle App e del Sito; il
              conferimento di alcuni di questi dati, contrassegnato con
              asterisco, è ritenuto necessario per la registrazione e la
              fruizione di contenuti e servizi;
            </li>
            <li>
              Dati di fatturazione, consegna e pagamento, quali codice fiscale,
              partita IVA, ragione sociale, indirizzo di consegna, modalità
              preferita di pagamento (con esclusione dei dati bancari e dei dati
              relativi a conti o carte utilizzate per il pagamento) qualora
              comunicati in occasione dell’acquisto di beni e servizi effettuati
              per il tramite l’e-Commerce della Piattaforma;
            </li>
            <li>
              Dati di prodotto, quali i dati relativi ai prodotti ed ai servizi
              acquistati per il tramite la Piattaforma;
            </li>
            <li>
              Dati di navigazione, quali i dati relativi alla connessione, agli
              indirizzi IP, ai parametri relativi al browser ed al sistema
              operativo utilizzato dall’Utente; dati di log relativi
              all’utilizzo della Piattaforma; ai flussi di navigazione e di
              visualizzazione delle pagine; alla posizione del dispositivo
              utilizzato per accedere alla Piattaforma.
            </li>
          </ul>
        </p>
        <p className="privacy_title">3. Finalità del trattamento</p>
        <p className="privacy_terms">
          Il trattamento delle categorie di dati personali di cui al punto
          precedente è effettuato dal Titolare del Trattamento, nello
          svolgimento delle sue attività economiche e commerciali, per le
          specifiche finalità indicate nel seguito
        </p>
        <p className="privacy_title">3.1 Finalità Contrattuali e di Legge:</p>
        <ul>
          <li>
            consentire all’Utente l’accesso e la registrazione alle App ed al
            Sito, la creazione di un Profilo personale, ivi incluse verifiche,
            assistenza e recupero delle credenziali, la fruizione delle
            funzionalità connesse alla creazione del Profilo personale e, in via
            generale, la fruizione dei contenuti della Piattaforma;
          </li>
          <li>
            consentire all’Utente l’esecuzione delle attività necessarie alla
            conclusione di contratti di acquisto di beni e servizi per il
            tramite della Piattaforma;
          </li>
          <li>
            consentire l’attività di assistenza e supporto all’utilizzo dei
            servizi offerti tramite la Piattaforma;
          </li>
          <li>
            consentire la gestione di comunicazioni, aggiornamenti, richieste,
            segnalazioni, reclami tramite i recapiti indicati dall’Utente, così
            come a mezzo chatbot, banner, sistemi di notifica push, sms ed altri
            strumenti di comunicazione a distanza;
          </li>
          <li>
            consentire l’adempimento degli obblighi derivanti dalle leggi
            vigenti, così come la gestione dei riscontri a richieste provenienti
            dalle competenti Autorità amministrative, tributarie e giudiziarie.
          </li>
          <li>
            Il trattamento dei dati dell’Utente per le finalità sopra elencate,
            nell’insieme definite “Finalità contrattuali e di legge”, non
            richiede l’espresso consenso dell’Interessato.
          </li>
          <li>
            Il conferimento dei dati richiesti per le anzidette finalità,
            contrassegnati da asterisco, è necessario e quindi obbligatorio
            poiché in mancanza non sarà possibile dare seguito al rapporto
            contrattuale scaturente dalla registrazione alla Piattaforma e
            l’Utente non potrà fruire dei contenuti e dei servizi ivi resi.
          </li>
        </ul>
        <p className="privacy_title">3.2 Finalità di legittimo interesse:</p>
        <ul>
          <li>
            per le analisi statistiche e di ricerca rispetto ai prodotti ed ai
            servizi acquistati per il tramite della Piattaforma, anche mediante
            l’interconnessione dei dati tra soggetti, a loro volta Titolari del
            Trattamento che, nei Negozi e nei Centri Commerciali – per come
            definiti nelle TCU - offrono al pubblico beni e servizi per il
            tramite della Piattaforma. Nel rispetto del principio di
            minimizzazione, ove possibile, dette attività di analisi statistica
            e di ricerca verranno effettuate previa anonimizzazione ed
            aggregazione dei dati raccolti;
          </li>
          <li>
            per la valutazione del grado di soddisfazione dei prodotti e dei
            servizi acquistati, per la soluzione di eventuali problemi legati al
            relativo utilizzo, per il miglioramento dell’esperienza di fruizione
            dei servizi resi per il tramite della Piattaforma;
          </li>
          <li>
            per tutelare, all’occorrenza ed anche per il tramite di terze parti,
            diritti ed interessi di RetAPPs nell’ambito di procedimenti e
            procedure giudiziali e stragiudiziali, così come per prevenire e
            contrastare eventuali frodi;
          </li>
          <li>
            per addivenire, all’occorrenza ed anche per il tramite di terze
            parti, a cessioni di beni, cessioni d’azienda o di ramo d’azienda,
            ad operazioni finanziarie ed operazioni straordinarie che prevedano
            la comunicazione ed il trasferimento dei dati a terze parti;
          </li>
          <li>
            per inviare all’Utente ai recapiti indicati in sede di
            registrazione, ai sensi dell’art. 130, co. 4, D.LGS. 196/2003
            (Codice Privacy), comunicazioni di marketing relative a servizi o
            prodotti analoghi a quelli costituenti oggetto del contratto
            concluso con RetAPPs all’atto della registrazione della Piattaforma,
            ferma la facoltà dell’Utente di opporsi in qualunque momento
            all’invio di dette comunicazioni;
          </li>
          <li>
            per svolgere attività di segmentazione degli Utenti ai quali è
            possibile, in virtù del consenso manifestato, inviare comunicazioni
            per le Finalità di Marketing di cui al punto successivo della
            presente Informativa, basate su categorie di dati comuni di
            appartenenza forniti dall’Utente quali: i luoghi di interesse,
            l’età, il sesso, la frequentazione dei Negozi, la tipologia di
            prodotti e servizi acquistati per il tramite della Piattaforma e
            comunque tutti quei dati ed informazioni fornite spontaneamente
            dall’Utente.
          </li>
          <li>
            Il trattamento dei dati dell’Utente per le finalità sopra elencate,
            nell’insieme definite “Finalità di legittimo interesse”, non
            richiede lo specifico consenso dell’Interessato, rientrando
            nell’eccezione prevista dall’art. 6, co. 1, lett. f) GDPR. Al
            riguardo ed in conformità al Regolamento, RetAPPs assicura
            all’Utente di avere svolto un approfondito bilanciamento di
            interessi finalizzato a garantire la privacy e i diritti
            fondamentali dell’Interessato.
          </li>
        </ul>
        <p className="privacy_title">
          3.3 Finalità di marketing e di profilazione:
        </p>
        <ul>
          <li>
            per inviare all’Utente aggiornamenti su offerte commerciali di
            prodotti e servizi, promozioni riservate all’Utente, per condurre
            ricerche di mercato e di customer satisfaction o per comunicare
            altre iniziative commerciali in genere, tramite i recapiti indicati
            dall’Utente, così come a mezzo chatbot, banner, sistemi di notifica
            push, sms ed altri strumenti di comunicazione a distanza, anche
            mediante l’interconnessione dei dati tra diversi soggetti, a loro
            volta Titolari del Trattamento che, nei Negozi e nei Centri
            Commerciali – per come definiti nelle TCU - offrono al pubblico beni
            e servizi per il tramite della Piattaforma;
          </li>
          <li>
            per comunicare i dati dell’Utente ad altri soggetti, a loro volta
            Titolari del Trattamento che, nei Negozi e nei Centri Commerciali –
            per come definiti nelle TCU - per l’invio di comunicazioni di
            marketing analoghe a quelle indicate al punto precedente;
          </li>
          <li>
            per creare un profilo dell’Utente ed esaminare le sue preferenze, le
            sue abitudini, i prodotti ed i servizi acquistati per il tramite
            della Piattaforma, anche al fine di inviare comunicazioni
            commerciali personalizzate in base agli interessi manifestati.
          </li>
          <li>
            Il trattamento dei dati dell’Utente per le finalità sopra elencate,
            nell’insieme definite “Finalità di marketing e di profilazione”, non
            è obbligatorio e, per conseguenza, all’Utente viene chiesto, di
            volta in volta e nelle forme più opportune allo scopo, di
            manifestare il consenso per ciascuna delle finalità sopra descritte.
            L’Utente può revocare il proprio consenso in ogni momento, tenendo
            tuttavia conto che, nel caso di revoca, alcune funzionalità della
            Piattaforma non risulteranno più fruibili o lo saranno solo in
            parte.
          </li>
          <li>
            RetAPPs declina ogni responsabilità per la mancata o limitata
            fruizione delle funzionalità della Piattaforma qualora ciò consegua
            da una scelta dell’Utente.
          </li>
        </ul>
        <p className="privacy_title">
          4. Comunicazione, diffusione e trasferimento dei Dati
        </p>
        <p className="privacy_terms">
          Nel rispetto del principio di finalità (cfr. punto 3) e di
          minimizzazione, i dati personali dell’Utente possono essere comunicati
          a soggetti terzi che svolgono attività funzionali a quelle relative ai
          servizi offerti ovvero relative ai prodotti ed ai servizi acquistati
          dall’Utente per il tramite la Piattaforma, quali:
        </p>
        <ul>
          <li>
            soggetti terzi fornitori di assistenza e consulenza in favore di
            RetAPPs, con riguardo, a titolo esemplificativo, all’ambito
            tecnologico, contabile, amministrativo, legale, assicurativo;
          </li>
          <li>
            soggetti terzi che intervengono nel rapporto contrattuale quali, a
            titolo esemplificativo, distributori, rivenditori, partner facenti
            parte della catena di distribuzione e vendita dei beni e dei servizi
            acquistati dall’Utente per il tramite la Piattaforma;
          </li>
          <li>banche ed istituti di credito;</li>
          <li>
            società di recupero crediti, banche dati pubbliche e sistemi di
            informazione creditizia;
          </li>
          <li>
            soggetti ed Autorità pubbliche il cui diritto all’accesso dei dati
            personali dell’Utente è espressamente riconosciuto dalle leggi
            vigenti o da provvedimenti dell’Autorità competente;
          </li>
          <li>
            potenziali soggetti interessati a compravendita di beni,
            compravendita d’azienda o di ramo d’azienda, ad operazioni
            finanziarie e ad ogni operazione straordinaria eventualmente
            riguardante RetAPPs.
          </li>
        </ul>
        <p className="privacy_terms">
          Per le finalità di marketing, previo consenso, i dati personali
          dell’Utente possono essere comunicati anche a soggetti terzi quali, a
          titolo esemplificativo, partner commerciali incaricati da RetAPPs
          della realizzazione di campagne di marketing. Tali soggetti, a seconda
          dei casi, trattano i dati dell’Utente in qualità di autonomi Titolari
          del Trattamento, ovvero di Responsabili incaricati del trattamento. La
          lista completa ed aggiornata dei soggetti che trattano i dati
          dell’Utente in qualità di Responsabili del trattamento è disponibile
          su motivata richiesta dell’Interessato, da inviarsi a RetAPPs con le
          modalità di contatto indicate nella presente Informativa.
        </p>
        <p className="privacy_title">
          5. Trasferimento dei Dati fuori dal territorio comunitario
        </p>
        <p className="privacy_terms">
          I dati non vengono trasferiti fuori dal territorio UE.
        </p>
        <p className="privacy_title">6. Modalità di trattamento dei Dati</p>
        <p className="privacy_terms">
          I dati personali dell’Utente sono trattati da RetAPPs con sistemi
          elettronici e manuali, secondo i principi di correttezza, lealtà e
          trasparenza previsti dal GDPR, tutelando la riservatezza
          dell’Interessato tramite misure di sicurezza tecniche ed organizzative
          atte a garantire un livello di sicurezza adeguato.
        </p>
        <p className="privacy_terms">
          Il trattamento dei dati ha luogo presso la sede di RetAPPs e/o presso
          la sede dei Responsabili esterni del trattamento, adeguatamente
          nominati, che effettuano il trattamento per conto di RetAPPs.
        </p>
        <p className="privacy_terms">
          Per l’analisi e l’elaborazione dei Dati di prodotto e dei Dati di
          navigazione, RetAPPs si avvale di strumenti elettronici che ne
          consentono la raccolta e l’analisi.
        </p>
        <p className="privacy_title">7. Conservazione dei dati</p>
        <p className="privacy_terms">
          I dati personali dell’Utente sono conservati da RetAPPs per il periodo
          di tempo necessario al perseguimento delle finalità per cui tali dati
          sono stati raccolti, secondo quanto esplicato nella presente
          Informativa.
        </p>
        <p className="privacy_terms">
          In ogni caso, con riferimento ai trattamenti ed alle finalità indicate
          nel seguito, RetAPPs osserva i seguenti termini di conservazione:
        </p>
        <ul>
          <li>
            per le Finalità Contrattuali e di Legge (cfr. 3.1) e per le Finalità
            di legittimo interesse (cfr. 3.2), i dati vengono conservati per un
            periodo pari alla durata dei servizi richiesti dall’Utente e per i
            10 anni successivi, periodo nel il quale matura la prescrizione in
            ordine all’eventuale responsabilità di RetAPPs per i servizi resi
            all’Utente, fatte salve le ipotesi in cui la conservazione per un
            periodo di tempo maggiore sia richiesta in ragione di eventuali
            contenziosi, richieste delle Autorità competenti o, in via generale,
            dalle norme vigenti;
          </li>
          <li>
            per le Finalità di Marketing e di profilazione (cfr. 3.3), i dati
            vengono conservati per un periodo pari a 24 mesi dalla data in cui
            l’Utente presta il relativo consenso o lo rinnova, ovvero, a seconda
            della categoria di dati, dalla data dell’ultimo contatto, con ciò
            intendendosi, a titolo esemplificativo, la fruizione di un prodotto
            o di un servizio, l’apertura di una comunicazione commerciale, la
            cessazione del rapporto contrattuale tra RetAPPs e l’Utente.
          </li>
        </ul>
        <p className="privacy_title">
          8. Modifica delle scelte e revoca del consenso
        </p>
        <p className="privacy_terms">
          L’Utente può modificare o revocare in qualunque momento il consenso
          prestato per le Finalità di marketing e di profilazione, accedendo
          all’apposita sezione della Piattaforma.
        </p>
        <p className="privacy_terms">
          Del pari, l’Utente può in qualunque momento modificare o revocare il
          consenso prestato per l’invio di offerte commerciali di prodotti e
          servizi, di promozioni riservate all’Utente, per condurre ricerche di
          mercato e di customer satisfaction o per la comunicazione di altre
          iniziative commerciali in genere, tenuto conto che, nel caso di
          revoca, alcune funzionalità della Piattaforma non risulteranno più
          fruibili o lo saranno solo in parte.
        </p>
        <p className="privacy_title">9. Diritti dell'Interessato</p>
        <p className="privacy_terms">
          Il GDPR sancisce una serie di diritti in favore dell'Interessato al
          trattamento il quale ha, pertanto, il diritto di ottenere la
          cancellazione (diritto all'oblio), la limitazione, l'aggiornamento, la
          rettificazione, la portabilità, l'opposizione al trattamento dei suoi
          dati personali e, in generale, può esercitare tutti i diritti previsti
          dagli artt. 15, 16, 17, 18, 19, 20, 21, 22 del GDPR.
        </p>
        <p className="privacy_terms">
          In particolare, l'Interessato ha diritto:
        </p>
        <p className="privacy_terms">
          a) di ottenere: la conferma dell'esistenza o meno di dati personali
          che lo riguardano, anche se non ancora registrati, e la loro
          comunicazione in forma intelligibile;
        </p>
        <ul>
          <p className="privacy_terms">
            b) di effettuare reclamo presso l'Autorità di controllo (Garante
            Privacy);
          </p>
          <p className="privacy_terms">
            c) di ottenere l'indicazione:
            <li>
              dell'origine dei suoi dati personali trattati dal Titolare del
              trattamento;
            </li>
            <li>delle finalità e delle modalità del trattamento;</li>
            <li>
              della logica applicata in caso di trattamento effettuato con
              l'ausilio di strumenti elettronici;
            </li>
            <li>
              degli estremi identificativi del titolare, dei responsabili e del
              rappresentante designato ai sensi dell'art.5, co.2, GDPR;
            </li>
            <li>
              dei soggetti o delle categorie di soggetti ai quali i dati
              personali possono essere comunicati o che possono venirne a
              conoscenza in qualità di rappresentante designato nel territorio
              dello Stato, di responsabili o di incaricati.
            </li>
          </p>

          <p className="privacy_terms">
            d) di ottenere:
            <li>
              l’aggiornamento, la rettificazione, ovvero, quando vi ha
              interesse, l'integrazione dei suoi dati;
            </li>
            <li>
              la cancellazione, la trasformazione in forma anonima o il blocco
              dei dati trattati in violazione di Legge, compresi quelli di cui
              non è necessaria la conservazione in relazione agli scopi per i
              quali i dati sono stati raccolti o successivamente trattati;
            </li>
            <li>
              l'attestazione che le attività di cui al presente articolo sono
              state portate a conoscenza, anche per quanto riguarda il loro
              contenuto, di coloro ai quali i dati sono stati comunicati o
              diffusi, eccettuato il caso in cui tale adempimento si riveli
              impossibile o comporti un impiego di mezzi manifestamente
              sproporzionato rispetto al diritto tutelato;
            </li>
            <li>la portabilità dei suoi dati;</li>
          </p>
          <p className="privacy_terms">
            e) di opporsi, in tutto o in parte, al trattamento dei suoi dati:
            <li>
              per motivi legittimi, al trattamento dei suoi dati personali
              ancorché pertinenti allo scopo della raccolta;
            </li>
            <li>
              in ogni caso, al trattamento dei suoi dati personali a fini di
              invio di materiale pubblicitario, di vendita diretta, per il
              compimento di ricerche di mercato o di comunicazioni commerciali.
            </li>
          </p>
        </ul>
        <p className="privacy_terms">
          RetAPPs informa l’Interessato che l'opposizione alla raccolta,
          all'elaborazione o all'utilizzo dei suoi dati personali potrebbe
          comportare l'impossibilità di fruire, in tutto o in parte, dei
          contenuti e dei servizi resi sul o per il tramite della Piattaforma.
        </p>
        <p className="privacy_terms">
          Per il caso di decesso dell’Interessato, i diritti anzidetti possono
          essere esercitati da chi ha un interesse proprio, da chi agisce a
          tutela dell’Interessato in qualità di mandatario ovvero da chi agisce
          per ragioni familiari meritevoli di protezione.
        </p>
        <p className="privacy_terms">
          Per il caso di decesso dell’Interessato, i diritti anzidetti possono
          essere esercitati da chi ha un interesse proprio, da chi agisce a
          tutela dell’Interessato in qualità di mandatario ovvero da chi agisce
          per ragioni familiari meritevoli di protezione.
        </p>
        <p className="privacy_terms">
          L’Interessato può vietare espressamente l’esercizio di alcuni dei
          diritti sopra elencati da parte dei suoi aventi causa inviando una
          comunicazione in forma scritta a RetAPPs secondo le modalità di
          comunicazione indicate nella presente Informativa. Detta dichiarazione
          può essere revocata o modificata dall’Interessato con le medesime
          modalità.
        </p>
        <p className="privacy_title">10. Modalità di comunicazione</p>
        <p className="privacy_terms">
          L’Interessato può esercitare i propri diritti in materia di protezione
          dei dati personali in qualunque momento e a titolo gratuito, inviando
          una comunicazione in forma scritta all’indirizzo PEC: retapps@pec.it o
          all’indirizzo e-mail: support@my-stores.it o, ancora, a mezzo posta
          cartacea a: RetAPPs S.r.l., via E. Stassano n. 29, 25125 Brescia (BS).
        </p>
        <p className="privacy_terms">
          Nel contattare RetAPPs per le finalità sopraindicate, l’Interessato
          deve aver cura di indicare il proprio nome e cognome, l’indirizzo di
          residenza o domicilio, l’indirizzo e-mail, recapito telefonico, così
          che la richiesta possa essere gestita.
        </p>
        <p className="privacy_title">11. Modifiche e aggiornamenti</p>
        <p className="privacy_terms">
          La presente Informativa può essere soggetta a revisioni, anche in
          conseguenza di modifiche e di integrazioni normative. Il testo
          dell’Informativa aggiornato è costantemente disponibile nell’apposita
          sezione della Piattaforma, ove l’Interessato può prenderne opportuna
          conoscenza.
        </p>
        <div>
          <p className="privacy-date">Ultima modifica in data 07/10/2020</p>
        </div>
      </div>

      {/* <NavBar mapview showMapView /> */}
      {/* <Footer /> */}
    </div>
  );
}

export default PrivacyPage;
