import React, { useEffect, useState } from "react";
import axios from "axios";
import { getPrivacyPolicy } from "../../Helpers/api";
import NavBar from "../NavBar/NavBar";
import Footer from "../footer/Footer";

import "./PrivacyPolicy.scss";
function PrivacyPolicy() {
  const [privacyPolices, setPrivacyPolices] = useState([
    {
      id: "13044742-419e-433b-9853-d8755fbf7729",
      created: "2020-09-08T08:13:35.000+0000",
      updated: "2020-09-08T08:13:35.000+0000",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
      type: "policyDetails",
      mandatory: false,
    },
  ]);
  useEffect(() => {
    // const url = `${getPrivacyPolicy}?type=policyDetails`;
    const url = `${getPrivacyPolicy}?type=privacy`;
    axios.get(url).then(
      (res) => {
        if (res && res.data && res.data) {
          setPrivacyPolices(res.data.data);
        }
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  return (
    <div>
      {/* <NavBar mapview showMapView /> */}
      <div className="privacy_content">
        <p className="privacy_heading">Privacy Policies</p>
        <ul>
          {privacyPolices &&
            privacyPolices.length &&
            privacyPolices.map((policy) => (
              <li className="privacy_terms">{policy.text}</li>
            ))}
          {/* <li className="privacy_terms">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
            commodo ligula eget dolor. Aenean massa. Cum sociis natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus mus
          </li> */}
        </ul>
      </div>

      {/* <NavBar mapview showMapView /> */}
      {/* <Footer /> */}
    </div>
  );
}

export default PrivacyPolicy;
