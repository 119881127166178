import produce from 'immer';

import {
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_LOADING,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILED,
} from './constants';

export const initialState = {
  getUserProfileData: '',
  getUserProfileError: '',
  updateUserProfileData: '',
  updateUserProfileError: '',
  updateUserLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const userProfileReducer = (state = initialState, action) =>
  produce(state, draft => {
    console.log(action.type);
    switch (action.type) {
      case GET_USER_PROFILE_SUCCESS:
        draft.getUserProfileData = action.res;
        break;
      case GET_USER_PROFILE_FAILED:
        draft.getUserProfileError = action.res;
        break;
      case UPDATE_USER_PROFILE_LOADING:
        draft.updateUserLoading = action.res;
        break;
      case UPDATE_USER_PROFILE_SUCCESS:
        draft.updateUserProfileData = action.res;
        break;
      case UPDATE_USER_PROFILE_FAILED:
        draft.updateUserProfileError = action.res;
        break;
    }
  });

export default userProfileReducer;
