/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import history from '../../../utils/history';
import { shopGallaryResource } from '../../../Helpers/api';
import { promoModalSubmit, promoModalImage } from '../../../assets/images';
import './promoProductModal.scss';
function PromoProductModal(props) {
  // const [storeTags, setStoreTags] = useState([]);
  const getDay = dateString => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = +date.getMonth() + 1;
    return `${day}/${month}`;
  };
  const formImageUrl = imageName => {
    const imgeUrl = `${shopGallaryResource}${imageName}`;
    const imgeUrlEncode = encodeURI(imgeUrl);
    return imgeUrlEncode;
  };
  // useEffect(() => {
  //   if (props.modalData.store && props.modalData.store.tags) {
  //     const str = props.modalData.store.tags;
  //     const resTags = str.split(',');
  //     setStoreTags(resTags);
  //   }
  //   console.log(props.isPromoImageFailed);
  // }, [props.modalData.store]);
  return (
    <Modal
      style={{
        width: '554px',
        height: '577px',
        borderRadius: '8px',
        boxShadow: '0 0 34px 0 rgba(0, 0, 0, 0.5)',
        backgroundColor: '#ffffff',
      }}
      closeIcon={{
        style: { top: '0', right: '0', fontSize: '12px' },
        color: '#ffffff',
        name: 'close',
      }}
      open={props.showPromoModal}
      onClose={() => props.setShowPromoModal(false)}
    >
      {props.modalData.offer && (
        <Modal.Header
          style={{
            textAlign: 'center',
            border: 'none',
            width: '554px',
            height: '232px',
            // backgroundImage: `url(${props.modalData.offer.imageUrl}), url(${promoModalImage})`,
            backgroundImage: `url(${
              props.isPromoImageFailed
                ? promoModalImage
                : formImageUrl(props.modalData.offer.image)
            })`,
          }}
          className="promo_modal_header"
        />
      )}

      <Modal.Content style={{ textAlign: 'center' }}>
        {props.modalData && props.modalData.store && props.modalData.offer ? (
          <Modal.Description>
            <div className="promo_modal_main_content">
              {/* {storeTags.length > 0 &&
                storeTags.map(tag => (
                  <p className="promo_modal_food">{` #${tag.trim()}`}</p>
                ))} */}
              {/* <p className="promo_modal_food">#alimentari</p> */}
              <span className="promo_modal_shop">
                {props.modalData.store.name}
              </span>
              <p className="modal_product_description">
                {props.modalData.offer.title}
              </p>
              <p className="product_desctrition_second">
                {props.modalData.offer.body}
              </p>
              <p className="promo_valid_date">
                {`*Promozione valida dal ${getDay(
                  props.modalData.offer.validFrom,
                )} al ${getDay(props.modalData.offer.validTo)}`}
              </p>
              <button
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  outline: 'none',
                }}
                type="button"
                onClick={() =>
                  history.push(`/shopDetails/${props.modalData.store.id}`)
                }
              >
                <img
                  src={promoModalSubmit}
                  alt="modal submit"
                  style={{ margin: '20px auto' }}
                />
              </button>
            </div>
          </Modal.Description>
        ) : props.modalData && props.modalData.mall && props.modalData.offer ? (
          <Modal.Description>
            <div className="promo_modal_main_content">
              {/* {storeTags.length > 0 &&
              storeTags.map(tag => (
                <p className="promo_modal_food">{` #${tag.trim()}`}</p>
              ))} */}
              {/* <p className="promo_modal_food">#alimentari</p> */}
              <span className="promo_modal_shop">
                {props.modalData.mall.name}
              </span>
              <p className="modal_product_description">
                {props.modalData.offer.title}
              </p>
              <p className="product_desctrition_second">
                {props.modalData.offer.body}
              </p>
              <p className="promo_valid_date">
                {`*Promozione valida dal ${getDay(
                  props.modalData.offer.validFrom,
                )} al ${getDay(props.modalData.offer.validTo)}`}
              </p>
              <button
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  outline: 'none',
                }}
                type="button"
                onClick={() =>
                  history.push(`/shopDetails/${props.modalData.store.id}`)
                }
              >
                <img
                  src={promoModalSubmit}
                  alt="modal submit"
                  style={{ margin: '20px auto' }}
                />
              </button>
            </div>
          </Modal.Description>
        ) : null}
      </Modal.Content>
    </Modal>
  );
}
PromoProductModal.propTypes = {
  showPromoModal: PropTypes.bool,
  setShowPromoModal: PropTypes.func,
  modalData: PropTypes.object,
  isPromoImageFailed: PropTypes.bool,
};

export default PromoProductModal;
