export const GET_FAVOURITE_STORES_SUCCESS = 'app/GET_FAVOURITE_STORES_SUCCESS';
export const CREATE_FAVOURITE_STORES_SUCCESS =
  'app/CREATE_FAVOURITE_STORES_SUCCESS';
export const DELETE_FAVOURITE_STORES_SUCCESS =
  'app/DELETE_FAVOURITE_STORES_SUCCESS';
export const GET_MALLS_STORES_SUCCESS = 'app/GET_MALLS_STORES_SUCCESS';
export const SEARCH_STORES_MALLS_SUCCESS = 'app/SEARCH_STORES_MALLS_SUCCESS';
export const SEARCH_STORES_MALLS_FAILED = 'app/SEARCH_STORES_MALLS_FAILED';
export const SEARCH_STORES_MALLS_LOADING = 'app/SEARCH_STORES_MALLS_LOADING';
export const GET_CITIES = 'app/GET_CITIES';
