/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Input, Button, Loader } from "semantic-ui-react";
import { useAlert } from "react-alert";
import { getUserData, operatorPasswordReset } from "../../Helpers/api";
import history from "../../utils/history";
import NavBar from "../NavBar/NavBar";
import Footer from "../footer/Footer";
import "./PasswordReset.scss";

function PasswordReset(props) {
  const alert = useAlert();
  const [userData, setUserData] = useState({
    name: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirm: "",
  });
  const [isNewsLoading, setIsNewsLoading] = useState(false);

  useEffect(() => {
    const getProfileUrl = `${getUserData}?profileId=${props.match.params.profileId}`;
    axios.get(getProfileUrl).then(
      (res) => {
        if (res && res.data && res.data.data && res.data.data) {
          setUserData({
            name: res.data.data.firstName,
            lastName: res.data.data.lastName,
            email: res.data.data.email,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);
  const passwordReset = () => {
    setIsNewsLoading(true);
    const payload = {};
    payload.email = userData.email;
    payload.profileId = props.match.params.profileId;
    payload.otp = props.match.params.otpId;
    payload.password = userData.password;
    payload.passwordConfirm = userData.passwordConfirm;

    axios.post(operatorPasswordReset, payload).then(
      (res) => {
        if (res && res.data) {
          if (res.data && parseInt(res.data.responseCode, 10) === 0) {
            alert.success("Password changed successfully !");
            setIsNewsLoading(false);
            history.push("/");
          } else if (res.data && parseInt(res.data.responseCode, 10) === 4) {
            alert.error("Validation failed");
            setIsNewsLoading(false);
          } else {
            alert.error("Something went !");
            setIsNewsLoading(false);
          }
        }
      },
      (err) => {
        console.log(err);
        alert.error("Something went !");
        setIsNewsLoading(false);
      }
    );
  };
  return (
    <div>
      {/* <NavBar mapview showMapView /> */}
      <div className="main_cotainer">
        <div className="user_details">
          <h1>Change Password</h1>
          <div className="user_key_value_container">
            <span className="user_key_name">First name : </span>
            <span className="user_key_value">{userData.name}</span>
          </div>
          <div className="user_key_value_container">
            <span className="user_key_name">Last name : </span>
            <span className="user_key_value">{userData.lastName}</span>
          </div>
          <div className="user_key_value_container">
            <span className="user_key_name">Email :</span>
            <span className="user_key_value user_key_email">
              {userData.email}
            </span>
          </div>
          <div className="user_key_value_container">
            <span className="user_key_name user_key_password">
              New password :
            </span>
            <Input
              type="password"
              maxLength="15"
              className="new_password_input"
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
            />
          </div>
          <div className="user_key_value_container">
            <span className="user_key_name user_key_password">
              Confirm password :
            </span>
            <Input
              type="password"
              maxLength="15"
              className="confirm_password_input"
              onChange={(e) =>
                setUserData({ ...userData, passwordConfirm: e.target.value })
              }
            />
          </div>
          <Button
            type="button"
            className="landing_login_button password_reset_button password_reset_button_2"
            onClick={passwordReset}
          >
            {isNewsLoading ? (
              <Loader active inline className="login_loader ladingFormLoader" />
            ) : (
              "Change Password"
            )}
          </Button>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
export default PasswordReset;
