/**
Sign up user
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import axios from "axios";
import { signin, signinlLoading, signinFailed } from "./actions";
import { userLogin } from "../../Helpers/api";
const CryptoJS = require("crypto-js");
/** function that returns an axios call */
function loginAPI(payload) {
  return axios(
    userLogin,
    // 'https://retapps-dev.demos.classicinformatics.com/api/cli/profiles/login',
    {
      method: "GET",
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, Accept",
        "Content-Type": "application/json",
      },
      auth: {
        username: payload.email,
        password: payload.password,
      },
    }
  );
}

export function* loginSaga(action) {
  yield put(signinlLoading(true));
  try {
    const loginResponse = yield call(loginAPI, action.payload);
    if (loginResponse) {
      yield put(signinlLoading(false));
      yield put(signin(loginResponse));
      yield put(signinFailed(""));
      const userInfo = {
        email: action.payload.email,
        password: action.payload.password,
        userId: loginResponse.data.data.id,
        name: `${loginResponse.data.data.firstName} ${loginResponse.data.data.lastName}`,
        firstLast: `${loginResponse.data.data.firstName.charAt(
          0
        )}${loginResponse.data.data.lastName.charAt(0)}`,
        firstName: `${loginResponse.data.data.firstName}`,
        lastName: `${loginResponse.data.data.lastName}`,
      };
      // Encrypt
      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(userInfo),
        "retApp-web-string@#20"
      ).toString();
      localStorage.setItem("token", ciphertext);
      localStorage.removeItem("searchHistory");
      if (
        loginResponse.data &&
        loginResponse.data.data &&
        loginResponse.data.data.preferredcityid
      ) {
        localStorage.setItem(
          "zipCode",
          loginResponse.data.data.preferredcityid
        );
      }
      yield put(push("/home"));
    }
  } catch (err) {
    yield put(signinlLoading(false));
    yield put(signinFailed(err));
    yield put(signin(""));
    console.log(err);
    // yield put(repoLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* signupData() {
  // Watches for LOGGED_IN actions and calls loginSaga when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest("LOGGED_IN", loginSaga);
}
