import React from 'react';
import PropTypes from 'prop-types';
import { Header, Modal } from 'semantic-ui-react';
import { emailVerification } from '../../assets/images';
const EmailVerificationModal = props => (
  <Modal
    style={{
      width: '400px',
      height: '325px',
      borderRadius: '8px',
      boxShadow: '0 0 34px 0 rgba(0, 0, 0, 0.5)',
      backgroundColor: '#ffffff',
    }}
    closeIcon={{
      style: { top: '1.0535rem', right: '1rem' },
      color: 'black',
      name: 'close',
    }}
    open={props.showSignUpSuccesModal}
    onClose={props.closeSuccessfulSignUp}
  >
    <Modal.Header style={{ textAlign: 'center', border: 'none' }}>
      Registrazione
    </Modal.Header>
    <Modal.Content style={{ textAlign: 'center' }}>
      <img src={emailVerification} alt="" />
      <Modal.Description>
        <Header
          style={{
            fontFamily: 'Open Sans',
            fontSize: '18px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.14px',
            color: '#333333',
          }}
        >
          Controlla la tua email
        </Header>
        <p
          style={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.14px',
            textAlign: 'center',
            color: '#333333',
          }}
        >
          Ti abbiamo inviatato una mail per confermare il tuo indirizzo. Clicca
          sul link all’interno della mail per concludere la registrazione.
        </p>
      </Modal.Description>
    </Modal.Content>
  </Modal>
);
EmailVerificationModal.propTypes = {
  showSignUpSuccesModal: PropTypes.bool,
  closeSuccessfulSignUp: PropTypes.func,
};

export default EmailVerificationModal;
