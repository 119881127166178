import produce from 'immer';

import {
  GET_FAVOURITE_STORES_SUCCESS,
  CREATE_FAVOURITE_STORES_SUCCESS,
  DELETE_FAVOURITE_STORES_SUCCESS,
  GET_MALLS_STORES_SUCCESS,
  SEARCH_STORES_MALLS_SUCCESS,
  SEARCH_STORES_MALLS_FAILED,
  SEARCH_STORES_MALLS_LOADING,
  GET_CITIES,
} from './constants';

export const initialState = {
  favStores: '',
};

/* eslint-disable default-case, no-param-reassign */
const homePageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_FAVOURITE_STORES_SUCCESS:
        draft.favStores = action.res;
        break;
      case CREATE_FAVOURITE_STORES_SUCCESS:
        draft.createdFavStore = action.res;
        break;
      case DELETE_FAVOURITE_STORES_SUCCESS:
        draft.deletedFavStore = action.res;
        break;
      case GET_MALLS_STORES_SUCCESS:
        draft.mallsStores = action.res;
        break;
      case SEARCH_STORES_MALLS_SUCCESS:
        draft.getMallsAndStoresSuccess = action.res;
        break;
      case SEARCH_STORES_MALLS_FAILED:
        draft.getMallsAndStoresError = action.res;
        break;
      case SEARCH_STORES_MALLS_LOADING:
        draft.getMallsAndStoresLoading = action.res;
        break;
      case GET_CITIES:
        draft.cities = action.res;
        break;
    }
  });

export default homePageReducer;
