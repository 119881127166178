/*
 *
 * LanguageProvider reducer
 *
 */
import produce from 'immer';

import { SIGN_UP } from './constants';

export const initialState = {
  signupRes: '',
};

/* eslint-disable default-case, no-param-reassign */
const signUpReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SIGN_UP:
        draft.signupRes = action.res;
        break;
    }
  });

export default signUpReducer;
