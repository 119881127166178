/* eslint-disable indent */
/**
 *
 * LandingPageContainer
 *
 */

import React, { memo, useState, useEffect } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
// import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import history from "../../utils/history";

// import { useInjectSaga } from 'utils/injectSaga';
// import { useInjectReducer } from 'utils/injectReducer';
// import makeSelectLandingPageContainer from './selectors';

// import reducer from './reducer';
// import saga from './saga';
// import messages from './messages';
// import './LandingPageContainer.scss';
import { logoIcon2, appleIcon, androidIcon } from "../../assets/images";
import LoginForm from "../../components/Login/Login";
import SignupForm from "../../components/SignupForm";
import EmailVerificationModal from "../../components/LandingPage/EmailVerificationModal";
import RegistrationModal from "../LandingPageContainer/RegistrationModal/RegistrationModal";
import ResetPasswordModal from "../LandingPageContainer/ResetPasswordModal/ResetPasswordModal";
import StoreRegisterForm from "../../components/StoreRegisterForm/StoreRegisterForm";
import "./StoreRegistrationContainer.scss";

export function StoreRegisterContainer() {
  // useInjectReducer({ key: 'landingPageContainer', reducer });
  // useInjectSaga({ key: 'landingPageContainer', saga });
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showSignUpSuccesModal, setShowSignUpSuccesModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showResetPassordModal, setShowResetPassordModal] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // Update the document title using the browser API
    document.addEventListener("scroll", () => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 1 && !isScrolled) {
        setIsScrolled(true);
      } else if (isScrolled && scrolled < 1) {
        setIsScrolled(false);
      }
    });
    return () => {
      document.removeEventListener("scroll", () => {});
    };
  });

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };
  const closeSignUpModal = () => {
    setShowSignUpModal(false);
  };
  const onSuccessfulSignUp = () => {
    setShowSignUpModal(false);
    setShowSignUpSuccesModal(true);
  };
  const closeSuccessfulSignUp = () => {
    setShowSignUpModal(false);
    setShowSignUpSuccesModal(false);
  };

  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <header className="landing_header" style={{ paddingBottom: "0" }}>
        <div
          className="landing_navbar"
          style={{
            backgroundColor: "#ffffff",
            height: "70px",
            boxShadow: "0 2px 13px 0 rgba(33, 73, 122, 0.29)",
          }}
        >
          <button
            type="button"
            className="register_store_logo_button"
            onClick={() => history.push("/")}
          >
            <div className="logo_name" style={{ backgroundImage: "none" }}>
              <img src={logoIcon2} alt="Logo Icon" className="logo_image" />
            </div>
          </button>

          {/* <div className="link_container" style={{ paddingTop: '15px' }}>
            <Link to="/react" className="nav-link" style={{ color: '#333333' }}>
              NEGOZI
            </Link>
            <Link to="/react" className="nav-link" style={{ color: '#333333' }}>
              OFFERTE
            </Link>
            <Link to="/react" className="nav-link" style={{ color: '#333333' }}>
              FIDELITY
            </Link>
            <Link to="/react" className="nav-link" style={{ color: '#333333' }}>
              IL TUO BUSINESS
            </Link>
          </div> */}
          <div className="button_container" style={{ margin: "15px 20px 0 0" }}>
            <Button
              className="landing_register_button"
              type="submit"
              // onClick={() => setShowSignUpModal(true)}
              onClick={() => setShowRegistrationModal(true)}
              // disabled
            >
              REGISTRATI
            </Button>
            <Button
              className="landing_login_button"
              type="submit"
              onClick={() => setShowLoginModal(true)}
              // disabled
            >
              ACCEDI
            </Button>
          </div>
        </div>
      </header>
      <StoreRegisterForm />

      {/* <footer className="landing_footer">
        <div className="footer_link_container">
          <div className="footer_links_column_one">
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
          </div>
          <div className="footer_links_column_two">
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
          </div>
          <div className="footer_links_column_three">
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/react" style={{ color: '#ffffff' }}>
                COME FUNZIONA
              </Link>
            </div>
          </div>
          <div className="footer_links_column_four">
            <div className="footer_link" style={{ fontWeight: 'normal' }}>
              Scarica l’ app:
            </div>
            <Button className="playstore_button footer_button">
              <img
                src={appleIcon}
                alt="apple-icon"
                style={{ margin: '0 5px 3px 0' }}
              />{' '}
              APP STORE
            </Button>
            <Button className="playstore_button footer_button">
              <img
                src={androidIcon}
                alt="apple-icon"
                style={{ margin: '0 5px 3px 0' }}
              />{' '}
              PLAY STORE
            </Button>
          </div>
        </div>
        <div className="footer_copyright_contiainer">
          <div className="all_rights">My-Stores® - All right reserved 2020</div>
          <div className="all_rights powered_by">powered by RetAPPs</div>
        </div>
      </footer> */}
      <LoginForm
        showLoginModal={showLoginModal}
        closeModal={closeLoginModal}
        setShowResetPassordModal={setShowResetPassordModal}
        setShowLoginModal={setShowLoginModal}
      />
      <SignupForm
        showSignUpModal={showSignUpModal}
        closeModal={closeSignUpModal}
        onSuccessfulSignUp={onSuccessfulSignUp}
      />
      <EmailVerificationModal
        showSignUpSuccesModal={showSignUpSuccesModal}
        closeSuccessfulSignUp={closeSuccessfulSignUp}
      />
      <RegistrationModal
        showRegistrationModal={showRegistrationModal}
        setShowRegistrationModal={setShowRegistrationModal}
        closeSuccessfulSignUp={closeSuccessfulSignUp}
        setShowSignUpModal={setShowSignUpModal}
      />
      <ResetPasswordModal
        showResetPassordModal={showResetPassordModal}
        setShowResetPassordModal={setShowResetPassordModal}
      />
    </div>
  );
}

StoreRegisterContainer.propTypes = {
  // dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  //   landingPageContainer: makeSelectLandingPageContainer(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(StoreRegisterContainer);
