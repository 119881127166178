/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { appleIcon, androidIcon } from "../../assets/images";
import "./Footer.scss";

function Footer() {
  return (
    <div className="common_footer">
      <footer className="landing_footer">
        <div className="footer_link_container">
          <div className="footer_links_column_one">
            <div className="footer_link">
              <Link to="/privacy" style={{ color: "#ffffff" }}>
                PRIVACY POLICY
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/cookies" style={{ color: "#ffffff" }}>
                COOKIES POLICY
              </Link>
            </div>
            {/*<div className="footer_link">*/}
            {/*  <Link to="/react" style={{ color: '#ffffff' }}>*/}
            {/*    COME FUNZIONA*/}
            {/*  </Link>*/}
            {/*</div>*/}
            {/*<div className="footer_link">*/}
            {/*  <Link to="/react" style={{ color: '#ffffff' }}>*/}
            {/*    COME FUNZIONA*/}
            {/*  </Link>*/}
            {/*</div> */}
          </div>
          <div className="footer_links_column_two">
            <div className="footer_link">
              <Link to="/cge" style={{ color: "#ffffff" }}>
                CGE
              </Link>
            </div>
            {/*<div className="footer_link">*/}
            {/*  <Link to="/react" style={{ color: '#ffffff' }}>*/}
            {/*    COME FUNZIONA*/}
            {/*  </Link>*/}
            {/*</div>*/}
            {/*<div className="footer_link">*/}
            {/*  <Link to="/react" style={{ color: '#ffffff' }}>*/}
            {/*    COME FUNZIONA*/}
            {/*  </Link>*/}
            {/*</div>*/}
            {/*<div className="footer_link">*/}
            {/*  <Link to="/react" style={{ color: '#ffffff' }}>*/}
            {/*    COME FUNZIONA*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
          <div className="footer_links_column_three">
            <div className="footer_link">
              <Link to="/tcu" style={{ color: "#ffffff" }}>
                TERMS & CONDITION UTENTE
              </Link>
            </div>
            {/*<div className="footer_link">*/}
            {/*  <Link to="/react" style={{ color: '#ffffff' }}>*/}
            {/*    COME FUNZIONA*/}
            {/*  </Link>*/}
            {/*</div>*/}
            {/*<div className="footer_link">*/}
            {/*  <Link to="/react" style={{ color: '#ffffff' }}>*/}
            {/*    COME FUNZIONA*/}
            {/*  </Link>*/}
            {/*</div>*/}
            {/*<div className="footer_link">*/}
            {/*  <Link to="/react" style={{ color: '#ffffff' }}>*/}
            {/*    COME FUNZIONA*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
          <div className="footer_links_column_four">
            <div
              className="footer_link footer_button_link"
              style={{ fontWeight: "normal" }}
            >
              Scarica l’APP:
            </div>
            <a
              href="https://apps.apple.com/it/app/my-stores/id1490231492"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="playstore_button footer_button">
                <img
                  src={appleIcon}
                  alt="apple-icon"
                  // style={{ margin: '0 5px 3px 0' }}
                />{" "}
                APP STORE
              </Button>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.retapps.mystores"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="playstore_button footer_button">
                <img
                  src={androidIcon}
                  alt="apple-icon"
                  // style={{ margin: "0 5px 3px 0" }}
                />{" "}
                PLAY STORE
              </Button>
            </a>
          </div>
        </div>
        <div className="footer_copyright_contiainer">
          <div className="all_rights">My-Stores - All right reserved 2020</div>
          <div className="all_rights powered_by">Made by RetAPPs</div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
