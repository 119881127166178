/* eslint-disable import/no-webpack-loader-syntax */
import React from "react";
import { Switch, Route } from "react-router-dom";
// import NotFoundPage from "containers/NotFoundPage/Loadable";
import NotFoundPage from "./components/NotFoundPage";
// import VerifyMail from "components/VerifyMail";
import VerifyMail from "./components/VerifyMail";
// import StoreRegisterContainer from "../StoreRegisterContainer/StoreRegisterContainer";
import StoreRegisterContainer from "./containers/StoreRegisterContainer/StoreRegisterContainer";
// import HomePage from "../../components/Homepage/HomePage";
import HomePage from "./containers/Homepage/HomePage";
import PromoPage from "./containers/promoPage/promoPage";
import FidelityPage from "./containers/FidelityPage/FidelityPage";
import ListingPage from "./containers/ListingPage/ListingPage";
// import ItemsMapView from "../../components/ItemsMapView/ItemsMapView";
import ItemsMapView from "./components/ItemsMapView/ItemsMapView";

import MallDetails from "./containers/MallDetails/MallDetails";
import ShopDetails from "./containers/ShopDetails/ShopDetails";
import UserProfile from "./containers/UserProfile/UserProfile";
import FavouritePage from "./containers/FavouritePage/FavouritePage";
import { PrivateRoute } from "./Helpers/PrivateRoute";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import PrivacyPageType from "./components/PrivacyPolicy/PrivacyPageType";
import FilterMapView from "./components/FilterMapView/FilterMapView";
import MallMapView from "./components/MallMapView/MallMapView";
// import LandingPage from "../LandingPageContainer/LandingPage";
import LandingPage from "./containers/LandingPageContainer/LandingPage";
import PasswordReset from "./components/PasswordReset/PasswordReset";
import PrivacyPage from "./components/PrivacyPage/PrivacyPage";
import CookiesPolicy from "./components/CookiesPolicy/CookiePolicy";
import Tcm from "./components/TermsAndConditions/Tcm";
import Tcu from "./components/TermsAndConditions/Tcu";
import LayoutComponent from "./components/LayoutComponent/LayoutComponent";
// import LandingPageMain from "./containers/LandingPageContainer/LandingPageMain";
import "!file-loader?name=[name].[ext]!./images/favicon.ico";
// import "file-loader?name=.htaccess!./.htaccess";

function App() {
  return (
    <div>
      <LayoutComponent>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          {/* <Route exact path="/" component={LandingPageMain} /> */}
          <Route exact path="/privacy" component={PrivacyPage} />
          <Route exact path="/cookies" component={CookiesPolicy} />
          <Route exact path="/tcu" component={Tcu} />
          <Route exact path="/cge" component={Tcm} />
          <Route
            exact
            path="/password-reset/otp/:otpId/profile/:profileId"
            component={PasswordReset}
          />
          {/* <Route
            path="/home/:id"
            component={HomePage}
            render={({ match }) => (
              <HomePage match={match} key={match.params.id} />
            )}
          /> */}
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/promo" component={PromoPage} />
          <Route exact path="/fidelity" component={FidelityPage} />
          <Route exact path="/search-results" component={ListingPage} />
          <Route exact path="/verifyMail" component={VerifyMail} />
          <Route
            path="/verify-email/otp/:otpId/profile/:profileId/activate"
            component={LandingPage}
            render={({ match }) => (
              <VerifyMail match={match} key={match.params.otpId} />
            )}
          />
          <Route exact path="/map-view" component={ItemsMapView} />
          <Route
            exact
            path="/register-store"
            component={StoreRegisterContainer}
          />
          <Route
            path="/mallDetails/:id"
            component={MallDetails}
            render={({ match }) => (
              <MallDetails match={match} key={match.params.id} />
            )}
          />
          <Route
            path="/shopDetails/:id"
            component={ShopDetails}
            render={({ match }) => (
              <MallDetails match={match} key={match.params.id} />
            )}
          />
          <PrivateRoute exact path="/userProfile" component={UserProfile} />
          <PrivateRoute exact path="/favourites" component={FavouritePage} />
          <Route exact path="/policy/:privacyId" component={PrivacyPageType} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/fav-map" component={FilterMapView} />
          <Route exact path="/mall-map" component={MallMapView} />
          <Route component={NotFoundPage} />
        </Switch>
      </LayoutComponent>
    </div>
  );
}

export default App;
