/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { getPrivacyPolicy } from "../../Helpers/api";
import NavBar from "../NavBar/NavBar";
import Footer from "../footer/Footer";

import "./PrivacyPolicy.scss";
function PrivacyPageType(props) {
  const [privacyPolices, setPrivacyPolices] = useState([]);
  useEffect(() => {
    const url = `${getPrivacyPolicy}/get?id=${props.match.params.privacyId}`;
    axios.get(url).then(
      (res) => {
        if (res && res.data && res.data) {
          setPrivacyPolices(res.data.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  return (
    <div>
      {/* <NavBar mapview showMapView /> */}
      <div className="privacy_content">
        <p className="privacy_heading">Privacy Policies</p>
        {privacyPolices && privacyPolices.text ? (
          <p className="privacy_terms">{privacyPolices.text}</p>
        ) : (
          <p className="privacy_terms">No Data found</p>
        )}
      </div>

      {/* <NavBar mapview showMapView /> */}
      {/* <Footer /> */}
    </div>
  );
}

export default PrivacyPageType;
