/* eslint-disable */
import React, { Component } from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../footer/Footer";

class Tcm extends Component {
  render() {
    return (
      <>
        {/* <NavBar mapview showMapView /> */}
        <div>
          <div className="d-flex privacy_content">
            <p className="privacy_title">
              My-Stores Termini e Condizioni Generali per
            </p>
            <div>
              <p className="privacy_terms">
                <ul>
                  <li>la registrazione dell’Esercente,</li>
                  <li>
                    la registrazione di un Negozio e di un Centro Commerciale,
                  </li>
                  <li>
                    l’attivazione di un e-commerce e di un programma fedeltà.
                  </li>
                </ul>
              </p>
            </div>

            <p className="privacy_terms">
              <b>My-Stores</b> è una piattaforma in Cloud di Attraction &
              Engagement, per Esercenti, Circuiti, Centri Commerciali Fisici e
              Naturali e Comunità Multi-Esercente. Le presenti Condizioni
              Generali disciplinano la registrazione dell’Esercente alla
              piattaforma My-Stores (nel seguito, “My-Stores” o “la
              Piattaforma”), la registrazione, da parte dell’Esercente, di un
              Negozio o di un Centro Commerciale, secondo le definizioni di cui
              infra, l’attivazione di un eCommerce, di programmi di
              fidelizzazione e di campagne promozionali, fruibili dall’Utente
              attraverso le applicazioni per smartphone (nel seguito, “le App”)
              ed il sito my-stores.it (nel seguito, “il Sito”).
            </p>
            <p className="privacy_title">
              1. Titolarità di <b>My-Stores</b>
            </p>
            <p className="privacy_terms">
              Titolare della Piattaforma, delle App e del Sito è RetAPPs S.r.l.,
              con sede legale in Brescia (BS), via E. Stassano n. 29, Cod. Fisc.
              e P.IVA 03388720983, iscritta al Registro delle Imprese di Brescia
              al n. BS-530039 (nel seguito, “RetAPPs”).
            </p>

            <p className="privacy_title">2. Definizioni</p>
            <p className="privacy_terms">
              Utente: è il consumatore, persona fisica, che si registra alla
              Piattaforma My-Stores; Esercente o Retailer: è il titolare o,
              comunque, il legale rappresentante dell’attività che si registra
              alla piattaforma ed attiva la sua vetrina digitale associata ad
              uno o più punti vendita fisici presenti sul territorio; Negozio o
              Shop: è il punto vendita fisico dove l’esercente esercita la sua
              attività; Negozio Preferito: è il punto vendita fisico selezionato
              volontariamente dall’utente per il quale ha dichiarato interesse a
              ricevere comunicazioni ed offerte; Centro Commerciale o Mall: è un
              aggregato di punti vendita fisici presenti sul territorio. Può
              essere fisico, se esiste una struttura fisica definita, oppure
              naturale o virtuale nel caso di aggregazione senza una struttura
              fisica definita sul territorio; Fidelity: è la possibilità che
              viene data all’Utente di partecipare ad una campagna promozionale
              definita da ciascun Esercente, potendo accumulare punti e ricevere
              sconti ed offerte dedicate sia nei negozi fisici sia nello shop
              e-Commerce online.
            </p>
            <p className="privacy_title">3. Registrazione a My-Stores </p>
            <p className="privacy_terms">
              3.1 Il rapporto fra My-Stores e l’Esercente è regolato dalle
              presenti Condizioni Generali (nel seguito, “CGE”), ivi ritenendovi
              compresi ogni nota, avviso legale, contenuto contrattuale o anche
              solo informativo, destinati all’Esercente, pubblicati nelle varie
              sezioni delle App e del Sito, con esclusione di qualsivoglia
              diversa pattuizione laddove non concordata espressamente in forma
              scritta.
            </p>
            <p className="privacy_terms">
              3.2 La registrazione a My-Stores, così come la registrazione di un
              Negozio o di un Centro Commerciale, l’attivazione di un eCommerce,
              di programmi fedeltà e di campagne promozionali implica
              l’accettazione di CGE e Privacy Policy. La persona fisica che, in
              qualità di Esercente, attiva la registrazione a My-Stores ed
              assume i conseguenti impegni dichiara di averne adeguati poteri.
            </p>
            <p className="privacy_terms">
              3.3 RetAPPs si riserva il diritto di aggiornare e modificare, in
              tutto o in parte ed in qualsiasi momento, CGE e Privacy Policy,
              così come eventuali integrazioni contrattuali ivi richiamati.
              L’Esercente è invitato, pertanto, a prenderne periodicamente
              visione, poiché i contenuti, così come modificati, avranno vigore
              dal momento della pubblicazione su My-Stores e si intenderanno da
              quel momento conosciuti dall’Esercente.
            </p>
            <p className="privacy_terms">
              3.4 Beni e servizi presentati ed offerti al pubblico
              dall’Esercente per il tramite di My-Stores sono unicamente
              destinati agli Utenti che rivestono la qualità di Consumatori,
              intendendosi per tali le persone fisiche che ivi agiscono per
              scopi estranei all'attività imprenditoriale, commerciale,
              professionale o artigianale eventualmente svolta. RetAPPs non
              offre direttamente la vendita al pubblico di beni e servizi e non
              è in alcun modo parte del contratto di vendita, limitandosi ad
              offrire all’Esercente i servizi disciplinati nelle presenti CGE.
            </p>
            <p className="privacy_title">4. Registrazione dell’Esercente</p>
            <p className="privacy_terms">
              4.1 Per poter accedere alla Piattaforma, l’Esercente deve
              registrarsi e creare un Profilo personale, protetto da password e
              verificato mediante OPT link in fase di registrazione al servizio.
              In sede di registrazione, per le finalità indicate nell’apposita
              Informativa, all’Esercente viene chiesto di indicare taluni dati
              anagrafici che ne consentono l’identificazione e di indicare il
              ruolo, di titolare o di legale rappresentante della società che
              svolge l’attività di promozione e vendita di beni e servizi per il
              tramite di My-Stores. I dati necessari onde poter procedere alla
              registrazione su My-Stores sono contrassegnati da asterisco.
            </p>
            <p className="privacy_terms">
              4.2 In sede di registrazione a My-Stores, l’Esercente si impegna e
              dichiara di fornire informazioni veritiere ed aggiornate,
              liberando RetAPPs da ogni e qualsivoglia responsabilità per il
              caso di dichiarazioni mendaci. È onere e responsabilità
              dell’Esercente provvedere al tempestivo aggiornamento delle
              informazioni fornite.
            </p>
            <p className="privacy_terms">
              4.3 L’Esercente, procedendo alla registrazione, dichiara di
              utilizzare la Piattaforma per scopi connessi alla sua attività
              imprenditoriale, commerciale, artigianale o professionale e, per
              l’effetto, di non rientrare nella nozione di Consumatore.
            </p>
            <p className="privacy_terms">
              4.4 RetAPPs potrà in ogni momento richiedere all’Esercente copia
              di un documento di identità o la manifestazione dei poteri per la
              verifica dei dati forniti. L’eventuale non veridicità dei dati
              forniti dall’Esercente costituisce grave inadempimento
              contrattuale e comporta l’immediata cancellazione dell’Esercente
              dalla Piattaforma.
            </p>
            <p className="privacy_terms">
              4.5 L’Esercente è responsabile della conservazione e della
              riservatezza delle proprie credenziali di accesso alla Piattaforma
              ed è responsabile per l’uso di My-Stores da parte di chiunque vi
              acceda con le sue credenziali.
            </p>
            <p className="privacy_terms">
              4.6 L’Esercente è responsabile di tutte le attività svolte sulla
              Piattaforma, ivi compreso il trattamento dei dati personali, così
              come dei contenuti, delle informazioni e delle immagini pubblicate
              su My-Stores, liberando RetAPPs da ogni responsabilità per il caso
              di dichiarazioni o immagini mendaci, contrarie alla legge,
              all’ordine pubblico o al buon costume o, comunque, lesive di
              diritti ed interessi di terzi.
            </p>
            <p className="privacy_terms">
              4.7 L’esercente può, in qualunque momento, procedere alla
              cancellazione del proprio Profilo, seguendo le indicazioni
              riportate nell’apposita sezione. La cancellazione dell’Esercente
              comporta la cancellazione del Negozio e della Vetrina digitale, di
              cui all’art. 6 delle CGE.
            </p>
            <p className="privacy_terms">
              4.8 RetAPPs, per le finalità indicate nella Privacy Policy, potrà
              fornire all’Esercente supporto tecnico, così come inviare
              segnalazioni o comunicazioni relative, in generale, alle
              funzionalità della Piattaforma, ai recapiti indicati
              dall’Esercente in sede di registrazione.
            </p>
            <p className="privacy_terms">
              4.9 RetAPPs si riserva la facoltà di sospendere o cancellare il
              Profilo personale dell’Esercente, di eliminare i contenuti
              pubblicati, di sospendere o bloccare il caricamento di contenuti
              ulteriori in caso di violazione degli obblighi di cui alle
              presenti CGE.
            </p>
            <p className="privacy_title">5. Registrazione dei Collaboratori </p>
            <p className="privacy_terms">
              L’Esercente, seguendo le istruzioni dell’apposita sezione, può
              registrare sulla Piattaforma propri Collaboratori, attribuendo
              loro specifici livelli di autorizzazione. Anche ai fini del
              trattamento dei dati personali, è compito dell’Esercente
              provvedere alle opportune nomine o deleghe in favore dei
              Collaboratori registrati. L’Esercente sarà in ogni caso
              responsabile dell’operato dei propri Collaboratori nei riguardi
              sia di RetApps sia dell’Utente.
            </p>
            <p className="privacy_title">
              6. Registrazione del Negozio e creazione della Vetrina digitale{" "}
            </p>
            <p className="privacy_terms">
              6.1 L’Esercente, seguendo le istruzioni dell’apposita sezione, può
              registrare sulla Piattaforma uno o più Negozi, corrispondenti ad
              altrettanti negozi fisici presenti sul territorio, ed attivare la
              propria Vetrina digitale. Per le finalità indicate nell’apposita
              Informativa, in sede di registrazione del Negozio viene chiesto
              all’Esercente di indicare una serie di dati identificativi
              dell’impresa o della società rappresentata, così come una serie di
              dati descrittivi dell’attività svolta.{" "}
            </p>
            <p className="privacy_terms">
              6.2 L’Esercente deve porre particolare attenzione nell’indicare
              sia la localizzazione del Negozio sia le parole chiave che
              descrivono beni e servizi offerti per il tramite di My-Stores,
              poiché costituiscono i criteri di ricerca e di selezione per
              l’Utente, che in tal modo individua e salva nel proprio profilo i
              Negozi Preferiti. La selezione dei Negozi Preferiti consente
              all’Utente di visualizzarne i contenuti, di accedere agli
              e-Commerce, di visualizzare offerte e promozioni nell’apposita
              sezione, di accedere ai programmi fedeltà, di ricevere
              comunicazione di offerte, promozioni, e informazioni su prodotti e
              servizi. L’Utente, per il tramite di My-Stores, può ricevere
              comunicazioni esclusivamente dai propri Negozi Preferiti.
            </p>
            <p className="privacy_terms">
              6.3 L’Esercente, seguendo le istruzioni dell’apposita sezione, può
              procedere alla creazione della propria Vetrina digitale, caricando
              immagini e descrizione di beni e prodotti offerti al pubblico nel
              Negozio. E’ fatto espresso divieto all’Esercente di pubblicare
              contenuti, informazioni ed immagini relativamente ai quali è privo
              di permessi, licenze o, in via generale, di autorizzazioni
              necessarie ai fini della pubblicazione, così come di pubblicare
              contenuti, informazioni ed immagini lesivi dell’immagine o della
              reputazione di terzi, diffamatori, violenti, razzisti o, comunque,
              contrari alla legge, all’ordine pubblico ed al buon costume.
            </p>
            <p className="privacy_terms">
              6.4 RetAPPs non effettua alcun controllo preventivo in ordine ai
              contenuti, alle informazioni ed alle immagini pubblicate
              dall’Esercente su My-Stores, riservandosi tuttavia la facoltà di
              rimozione laddove detti contenuti, informazioni ed immagini
              risultino contrari al disposto delle presenti CGE.
            </p>
            <p className="privacy_title">7. Attivazione dell’eCommerce </p>
            <p className="privacy_terms">
              7.1 L’Esercente, seguendo le istruzioni dell’apposita sezione, può
              attivare sulla Piattaforma uno o più eCommerce, corrispondenti ad
              altrettanti Negozi registrati sulla Piattaforma, ed ivi proporre
              al pubblico dei consumatori la vendita di beni e servizi,
              disciplinata da apposite condizioni.
            </p>
            <p className="privacy_terms">
              7.2 L’Esercente è responsabile di tutte le attività e le
              transazioni svolte tramite l’eCommerce, ivi compreso il
              trattamento dei dati personali, così come dei contenuti, delle
              informazioni e delle immagini ivi pubblicate, liberando RetAPPs da
              ogni responsabilità per il caso di violazione delle condizioni di
              vendita, dichiarazioni o immagini mendaci, contrarie alla legge,
              all’ordine pubblico o al buon costume o, comunque, lesive di
              diritti ed interessi di terzi e, in via generale, per il caso di
              violazione di obblighi di legge.Il contratto di vendita di beni e
              servizi per il tramite dell’eCommerce si conclude direttamente tra
              il consumatore e l’Esercente, che si assume ogni conseguente
              responsabilità, limitandosi RetAPPs a fornire i servizi di cui
              alle presenti CGE.{" "}
            </p>
            <p className="privacy_terms">
              7.3 Seguendo le istruzioni dell’apposita sezione, l’Esercente può
              caricare immagini e descrizione di beni e prodotti offerti al
              pubblico tramite l’eCommerce, rispettando le previsioni di cui
              all’art. 6.3 CGE, da intendersi qui richiamate.
            </p>
            <p className="privacy_terms">
              7.4 Effettuando acquisti tramite l’eCommerce, l’Utente può sia
              accumulare sia utilizzare punti Fidelity, così come beneficiare di
              offerte e promozioni, come meglio indicato all’art. 9 CGE.
            </p>
            <p className="privacy_title">
              8. Registrazione di un Centro Commerciale{" "}
            </p>
            <p className="privacy_terms">
              8.1 Ai fini della promozione di beni e servizi, più negozi fisici
              presenti sul territorio possono creare una aggregazione, sia
              fisica, laddove corrisponda una effettiva aggregazione sul
              territorio, sia virtuale, laddove non vi sia una aggregazione
              fisica sul territorio, e registrare sulla Piattaforma un Centro
              Commerciale.
            </p>
            <p className="privacy_terms">
              8.2 Per le finalità indicate nell’apposita Informativa, in sede di
              registrazione del Centro Commerciale viene chiesto all’Esercente
              o, comunque, al soggetto che provvede alla registrazione del
              Centro Commerciale, di indicare una serie di dati identificativi.
            </p>
            <p className="privacy_terms">
              8.4 Seguendo le istruzioni dell’apposita sezione, l’Esercente o,
              comunque, il soggetto che provvede alla registrazione del Centro
              Commerciale, può caricare immagini e descrizione di beni e
              prodotti offerti al pubblico nei Negozi, fisici o virtuali, che
              costituiscono l’aggregazione, rispettando le previsioni di cui
              all’art. 6.3 CGE, da intendersi qui richiamate.
            </p>
            <p className="privacy_terms">
              8.5 Il Centro Commerciale non svolge attività di vendita al
              pubblico di beni e servizi, bensì di promozione dei Negozi, fisici
              e virtuali, che compongono l’aggregazione. A questo fine, il
              Centro Commerciale può altresì promozionare ed estendere le
              campagne Fidelity, così come le offerte e le promozioni dei
              Negozi, come meglio disciplinate all’art. 9 CGE.
            </p>
            <p className="privacy_title">9. Fidelity e Promozioni</p>
            <p className="privacy_terms">
              9.1 L’Esercente, seguendo le istruzioni dell’apposita sezione, può
              attivare per ciascuno dei Negozi registrati sulla Piattaforma, un
              programma fedeltà, disciplinato da apposito Regolamento, ed
              emettere una Fidelity Card virtuale, a mezzo della quale l’Utente
              può accumulare punti, partecipare ad offerte e promozioni,
              fruibili presso ciascuno dei Negozi, sia fisici sia in modalità
              eCommerce, emittenti la Fidelity Card.
            </p>
            <p className="privacy_terms">
              9.2 L’Esercente è responsabile di tutte le attività inerenti
              l’attivazione e la gestione del programma fedeltà, ivi compreso il
              trattamento dei dati personali, liberando RetAPPs da ogni
              responsabilità per il caso di violazione del Regolamento di
              utilizzo e, in via generale, per il caso di violazione di obblighi
              di legge. Il contratto di adesione al programma fedeltà si
              conclude direttamente tra l’Utente e l’Esercente, che si assume
              ogni conseguente responsabilità, limitandosi RetAPPs a fornire i
              servizi di cui alle presenti CGE.
            </p>
            <p className="privacy_terms">
              9.3 I programmi fedeltà, le offerte e le promozioni possono essere
              estese e promozionate dal Centro Commerciale di cui il Negozio è
              parte. La gestione del programma fedeltà così come l’attribuzione
              e la fruizione dei punti accumulati compete, in ogni caso, al
              Negozio emittente la Fidelity Card.
            </p>
            <p className="privacy_terms">
              9.5 RetAPPs si riserva la facoltà di realizzare e gestire
              direttamente concorsi, programmi fedeltà e promozioni a cui
              l’Utente può partecipare. Nel caso, il Regolamento di detti
              concorsi, programmi fedeltà e promozioni verrà pubblicato su
              My-Stores.
            </p>
            <p className="privacy_title">
              10. Corrispettivo e modalità di pagamento
            </p>
            <p className="privacy_terms">
              10.1 Il Corrispettivo per i servizi resi da RetAPPs e le relative
              modalità di pagamento sono disciplinate mediante offerte separate
              che, sottoscritte dalle Parti, vanno a formare parte integrante
              delle CGE.
            </p>
            <p className="privacy_terms">
              10.2 In caso di mancato o tardato pagamento del corrispettivo nei
              termini pattuiti, RetAPPs si riserva la facoltà di sospendere
              l’accesso e l’utilizzo della Piattaforma da parte dell’Esercente,
              configurando l’esercizio di detta facoltà eccezione di
              inadempimento. Nel caso, l’Esercente nulla potrà opporre o
              domandare a RetAPPs a qualsivoglia titolo sino all’esatto
              adempimento dell’obbligazione di pagamento.
            </p>
            <p className="privacy_terms">
              10.3 L’eventuale tolleranza di condotte contrarie a quanto sopra
              non costituisce acquiescenza.
            </p>
            <p className="privacy_title">
              11. Durata del contratto e facoltà di recesso
            </p>
            <p className="privacy_terms">
              11.1 Il presente contratto è concluso tra le parti a tempo
              indeterminato.{" "}
            </p>
            <p className="privacy_terms">
              11.2 Ciascuna delle Parti ha facoltà di recedere dal presente
              contratto con preavviso di ____ mesi, da comunicarsi all’altra
              parte a mezzo PEC o lettera raccomandata da inviarsi, quanto
              all’Esercente, ai recapiti indicati in sede di registrazione e,
              quanto a RetAPPs, ai recapiti indicati nelle CGE.{" "}
            </p>
            <p className="privacy_terms">
              11.3 Per il caso di esercizio della facoltà di recesso, decorso il
              termine di preavviso RetAPPs provvederà alla chiusura del profilo
              personale dell’Esercente, del Negozio, dell’eCommerce ed alla
              cancellazione del Negozio dall’aggregazione in Centro Commerciale.
              Nulla potrà per l’effetto l’Esercente opporre o domandare a
              RetAPPs a qualsivoglia titolo.
            </p>
            <p className="privacy_title">12. Autonomia delle Parti </p>
            <p className="privacy_terms">
              RetAPPs, i Negozi e i Centri Commerciali sono soggetti
              reciprocamente autonomi e indipendenti. L’Utente può fruire dei
              contenuti offerti direttamente da RetAPPs su o per il tramite di
              My-Stores a titolo gratuito. Ciascun Negozio o Centro Commerciale
              indica all’Utente le proprie condizioni di vendita, di
              partecipazione ai programmi fedeltà e di fruizione delle offerte
              promozionali.
            </p>
            <p className="privacy_title">13. Proprietà intellettuale</p>
            <p className="privacy_terms">
              13.1 I domini web “my-stores.it”, “.net”, “.shop”, “.online” e la
              denominazione commerciale delle App “My-Stores” e “My-Stores POS”,
              appartengono a RetAPPs; in mancanza di previa autorizzazione in
              forma scritta, è vietato all’Esercente e ai Terzi l’utilizzo del
              dominio e della denominazione commerciale “My-Stores” per attività
              e finalità diverse rispetto all’utilizzo ed alla fruizione dei
              contenuti e dei servizi resi su o per il tramite della Piattaforma
              My-Stores e ciò al fine di non causare confusione nel Consumatore.
            </p>
            <p className="privacy_terms">
              13.2 RetAPPs detiene tutti i diritti sui contenuti, il design e il
              codice sorgente delle App e dei Siti, ivi inclusi, a titolo
              esemplificativo, fotografie, immagini, testi, loghi, disegni,
              marchi, denominazioni commerciali e dati, di creazione e/o
              comunque riconducibili a RetAPPs.
            </p>
            <p className="privacy_terms">
              13.3 Il contenuto delle App e del Sito è considerato software
              informatico e, per conseguenza, trova applicazione la normativa
              italiana e comunitaria europea vigente in materia di tutela del
              diritto d’autore. In mancanza di previa autorizzazione in forma
              scritta, è vietata la riproduzione totale o parziale delle App e
              del Sito, anche tramite link ipertestuali, così come di ogni parte
              dei relativi contenuti, ivi comprese le presenti CGE.
            </p>
            <p className="privacy_title">14. Responsabilità </p>
            <p className="privacy_terms">
              RetAPPs si impegna a verificare il corretto utilizzo di My-Stores
              da parte dell’Esercente e dell’Utente; tuttavia, a titolo
              esemplificativo e non esaustivo, non si assume alcuna
              responsabilità derivante:
            </p>
            <ul>
              <li>
                dall’utilizzo che l’Utente può fare delle informazioni, delle
                immagini e, in via generale, dei contenuti presenti sulla
                Piattaforma;
              </li>
              <li>
                dalla violazione di norme a tutela della proprietà intellettuale
                o industriale eventualmente commesse dall’Esercente e/o
                dall’Utente;
              </li>
              <li>
                dai pregiudizi derivanti all’Utente in conseguenza dell’acquisto
                e/o dell’utilizzo di prodotti e servizi, essendo l’Esercente
                unico responsabile di ogni conseguenza connessa alla
                compravendita dei prodotti e dei servizi;
              </li>
              <li>
                dall’accesso e dall’utilizzo di My-Stores da parte di minori di
                16 anni, essendo compito del genitore o di chi ne fa le veci
                esercitare l’adeguato controllo sull’attività del minore, ovvero
                installare strumenti di controllo dell’accesso e dell’uso
                dell’internet;
              </li>
              <li>
                dalla mancata operatività o da problemi tecnici relativi
                all’indirizzo e-mail fornito dall’Utente o dall’Esercente in
                sede di registrazione.
              </li>
            </ul>
            <p className="privacy_title">15. Interruzione dei servizi</p>
            <p className="privacy_terms">
              15.1 RetAPPs potrà sospendere temporaneamente l’accesso a
              My-Stores in caso di problemi tecnici o di esigenze manutentive,
              così come potrà modificarne impostazioni e contenuti. RetAPPs
              potrà cessare in qualunque momento, in tutto o in parte, la
              prestazione dei servizi resi su o per il tramite di My-Stores,
              salvo il rispetto dei previi impegni contrattualmente assunti.
            </p>
            <p className="privacy_terms">
              15.2 RetAPPs non si assume alcuna responsabilità per eventuali
              difficoltà, disfunzioni o impedimenti di accesso al Profilo
              personale ed alle funzionalità di My-Stores da parte
              dell’Esercente laddove cagionati dagli strumenti tecnici, dalla
              rete di connessione o qualsivoglia causa non direttamente
              dipendente dal fatto di RetAPPs.
            </p>
            <p className="privacy_title">16. Disposizioni finali </p>
            <p className="privacy_terms">
              16.1 Qualora una o più delle disposizioni di cui alle presenti CGE
              venga dichiarata, in tutto o in parte, invalida o inefficace,
              verrà ritenuta scindibile dalle altre e non inciderà sulla
              validità delle altre CGE, così come ogni nota, avviso legale,
              contenuto contrattuale o anche solo informativo pubblicati nelle
              varie sezioni delle App e del Sito, ivi comprese le informazioni
              relative alla Privacy Policy.{" "}
            </p>
            <p className="privacy_terms">
              16.2 L’eventuale tolleranza, da parte di RetAPPs, di comportamenti
              posti in essere in violazione delle presenti CGE non costituisce
              rinuncia ai diritti derivanti dalle disposizioni violate né a
              qualsivoglia diritto o facoltà scaturente da Legge o contratto.
            </p>
            <p className="privacy_title">
              17. Legge applicabile e risoluzione delle controversie
            </p>
            <p className="privacy_terms">
              CGE, così come ogni contenuto delle App e del Sito, sono regolati
              dalla Legge italiana. In caso di controversia, l’Esercente è
              invitato a contattare RetAPPs all’indirizzo PEC retapps@pec.it o
              all’indirizzo e-mail support@my-stores.it al fine di risolvere
              bonariamente la questione. Nell’impossibilità di una soluzione
              amichevole, sin d’ora RetAPPs manifesta l’adesione al servizio di
              conciliazione “Risolvionline”, fornito dalla Camera Arbitrale di
              Milano e meglio descritto sul sito www.risolvionline.it, al quale
              si rinvia.
            </p>
            <br />
            <p className="privacy_terms">
              L’Esercente dichiara di essere un imprenditore commerciale e di
              aver ben compreso il contenuto dell’intero contratto; in ogni
              caso, a norma dell’art. 1341. Co. 2, cod. civ., l’Esercente
              espressamente dichiara di approvare le seguenti clausole: 4.
              Registrazione dell’Esercente; 6. Registrazione del Negozio e
              creazione della Vetrina digitale; 7. Attivazione dell’eCommerce;
              8. Registrazione di un Centro Commerciale; 9. Fidelity e
              Promozioni; 10. Corrispettivo e modalità di pagamento; 11. Durata
              del contratto e facoltà di recesso; 14. Responsabilità; 15.
              Interruzione dei servizi; 17. Legge applicabile e risoluzione
              delle controversie.
            </p>
            <div>
              <p className="privacy-date">Ultima modifica in data 09/10/2020</p>
            </div>
          </div>
        </div>
        {/* <NavBar mapview showMapView />
        <Footer /> */}
      </>
    );
  }
}

export default Tcm;
