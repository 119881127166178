/*
 *
 * User sign up actions
 *
 */

import { SIGN_UP } from './constants';

export function signup(data) {
  return {
    type: SIGN_UP,
    res: data,
  };
}
